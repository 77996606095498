import { Component, Input } from '@angular/core'
import { environment as env } from 'src/environments/environment'

@Component({
	selector: 'app-modal-login',
	template: `
		<app-modal-basic title="Entrar na sua conta" [id]="id">
			<p class="text">Você precisa estar logado na sua conta LATAM Pass para fazer uma reserva.</p>
			<button class="btn btn--primary" (click)="loginLatam()">Ir para o login</button>
			<button class="btn btn--ghost" (click)="signUpLatam()">Criar conta</button>
		</app-modal-basic>
	`,
})
export class ModalLoginComponent {
	@Input() id!: string
	constructor() {}

	loginLatam() {
		const redirectTo = window.location.href
		const url = `${env.latamPassUrl}/login?url=${redirectTo}`
		window.open(url, '_blank')
	}

	signUpLatam() {
		const url = 'https://www.latamairlines.com/br/pt/cadastro'
		window.open(url, '_blank')
	}
}
