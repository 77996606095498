import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store'
import { BenefitsActions } from './benefits.actions'
import { Status } from '../status.type'

export interface BenefitState {
	biggestMultiplier: number | null
	status: Status
	error: string | null
}

const initialState: BenefitState = {
	biggestMultiplier: null,
	status: 'idle',
	error: null,
}

const getVenueFeatureState = createFeatureSelector<BenefitState>('benefits')

export const getBiggestMultiplier = createSelector(getVenueFeatureState, state => state.biggestMultiplier)

export const benefitsReducer = createReducer<BenefitState>(
	initialState,
	on(BenefitsActions.loadBiggestMultiplier, (state): BenefitState => {
		return {
			...state,
			status: 'pending',
		}
	}),
	on(BenefitsActions.loadBiggestMultiplierSuccess, (state, action): BenefitState => {
		return {
			...state,
			biggestMultiplier: Number(action.multiplier),
			status: 'success',
			error: null,
		}
	}),
	on(BenefitsActions.loadBiggestMultiplierFailure, (state, action): BenefitState => {
		return {
			...state,
			error: action.error,
			status: 'failure',
		}
	})
)
