import { Component } from '@angular/core'

@Component({
	selector: 'app-sucesso',
	template: `
		<app-header> </app-header>
		<app-checkin-success></app-checkin-success>
		<div style="height: 32px"></div>
		<app-footer> </app-footer>
	`,
})
export class CheckinSuccessPage {}
