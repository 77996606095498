<div class="card" *ngIf="client$ | async as client">
	<p class="card__title">Selecionar cartão para pagamento</p>
	<div class="card__itens">
		<div class="card__item" *ngFor="let card of client.cards">
			<div class="card__brand">
				<img [src]="img.src" [alt]="img.alt" *ngIf="chooseBrand.get(card.brand) as img" />
			</div>
			<p class="card__number">.... {{ card.lastFourDigits }}</p>
			<i class="fa fa-star card__star" *ngIf="card.mainCard"></i>
			<ng-container [formGroup]="form">
				<label class="card__check-container">
					<input
						type="radio"
						class="card__check"
						name="card"
						value="{{ card.gatewayId }}|{{ card.lastFourDigits }}"
						name="card"
						formControlName="card"
					/>
					<span class="card__checkmark"></span>
					<i class="tagmeicon tagmeicon-imput-03"></i>
				</label>
			</ng-container>
		</div>
	</div>
	<button class="card__item card__add" (click)="addCard.emit()">
		<p><i class="tagmeicon tagmeicon-mais-micro"></i> Adicionar novo cartão</p>
	</button>

	<button class="card__cancel" (click)="modalService.close(id)">
		<p>Cancelar</p>
	</button>
</div>
