import { Component, HostBinding, Input, OnInit } from '@angular/core'
import { VenueFilter } from 'src/entity/venues.entity'

@Component({
	selector: 'app-venue-info',
	templateUrl: './venue-info.component.html',
	styleUrls: ['venue-info.component.scss'],
})
export class VenueInfoComponent {
	img = './../assets/images/img-restaurante.jpg'

	@Input() name!: string | undefined
	@Input() address!: string | undefined
	@Input() cuisines!: string[]
	@Input() size: 'small' | 'normal' = 'normal'
	@Input() limit: number | null = null

	constructor() {}

	get filteredCuisines(): string[] {
		if (this.limit !== null) {
			return this.cuisines.slice(0, this.limit)
		} else {
			return this.cuisines
		}
	}

	@HostBinding('style')
	get addProps() {
		if (this.size === 'small') {
			return {
				'--font-size-title': '16px',
				'--font-size-address': '12px',
			}
		}
		// size == normal
		return {
			'--font-size-title': '20px',
			'--font-size-address': '14px',
		}
	}
}
