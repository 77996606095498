import { VenueDetail, VenueFilter } from 'src/entity/venues.entity'
import { Status } from '../status.type'
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store'
import { VenueActions } from './venue.actions'
import { DEFAULT_LIMIT } from 'src/gateway/venue/http-venues.gateway'

export interface Location {
	latitude: number
	longitude: number
	maxDistance: number
}
export interface VenueState {
	venues: VenueFilter[] | null
	statusGetVenues: Status
	errorGetVenues: string | null
	allLoaded: boolean
	selectedVenue: VenueDetail | null
	statusSelectedVenue: Status
	erroGetVenue: string | null
	statusLoadingMoreVenues: Status
	errorLoadingMoreVenues: string | null
	location: Location | null
}

const initialState: VenueState = {
	venues: null,
	statusGetVenues: 'idle',
	errorGetVenues: null,
	selectedVenue: null,
	allLoaded: false,
	statusSelectedVenue: 'idle',
	erroGetVenue: null,
	statusLoadingMoreVenues: 'idle',
	errorLoadingMoreVenues: null,
	location: null,
}

const getVenueFeatureState = createFeatureSelector<VenueState>('venues')

export const getVenues = createSelector(getVenueFeatureState, state => state.venues)
export const getErrorVenues = createSelector(getVenueFeatureState, state => state.errorGetVenues)
export const isLoadingGetVenues = createSelector(
	getVenueFeatureState,
	state => state.statusGetVenues === 'pending'
)
export const getSelectedVenue = createSelector(getVenueFeatureState, state => state.selectedVenue)
export const isLoadingSelectedVenue = createSelector(
	getVenueFeatureState,
	state => state.statusSelectedVenue === 'pending'
)
export const loadingMoreVenues = createSelector(
	getVenueFeatureState,
	state => state.statusLoadingMoreVenues === 'pending'
)
export const isAllLoaded = createSelector(getVenueFeatureState, state => state.allLoaded)

export const getLocation = createSelector(getVenueFeatureState, state => state.location)

export const venueReducer = createReducer<VenueState>(
	initialState,
	on(VenueActions.getVenues, (state): VenueState => {
		return {
			...state,
			selectedVenue: null,
		}
	}),
	on(VenueActions.loadVenuesSuccess, (state, action): VenueState => {
		return {
			...state,
			errorGetVenues: '',
			selectedVenue: null,
			venues: action.venues.filter(venue => venue.isNotEmpty),
			statusGetVenues: 'success',
			allLoaded: action.venues.length < DEFAULT_LIMIT,
		}
	}),
	on(VenueActions.loadVenuesFailure, (state, action): VenueState => {
		return {
			...state,
			errorGetVenues: action.error,
			statusGetVenues: 'failure',
			venues: null,
			allLoaded: false,
		}
	}),
	on(VenueActions.loadVenues, (state): VenueState => {
		return {
			...state,
			venues: null,
			statusGetVenues: 'pending',
		}
	}),
	on(VenueActions.loadOneVenue, (state): VenueState => {
		return {
			...state,
			statusSelectedVenue: 'pending',
		}
	}),
	on(VenueActions.loadOneVenueSuccess, (state, action): VenueState => {
		return {
			...state,
			selectedVenue: action.venue,
			statusSelectedVenue: 'success',
		}
	}),
	on(VenueActions.loadOneVenueFailure, (state, action): VenueState => {
		return {
			...state,
			statusSelectedVenue: 'failure',
			erroGetVenue: action.error,
		}
	}),
	on(VenueActions.loadMoreVenue, (state): VenueState => {
		return {
			...state,
			statusLoadingMoreVenues: 'pending',
		}
	}),
	on(VenueActions.loadMoreVenuesSuccess, (state, action): VenueState => {
		const venues = action.venues.filter(venue => venue.isNotEmpty)
		return {
			...state,
			errorLoadingMoreVenues: '',
			venues: state.venues ? [...state.venues, ...venues] : [...venues],
			allLoaded: action.venues.length < DEFAULT_LIMIT,
			statusLoadingMoreVenues: 'success',
		}
	}),
	on(VenueActions.loadMoreVenuesFailure, (state, action): VenueState => {
		return {
			...state,
			venues: null,
			allLoaded: false,
			errorLoadingMoreVenues: action.error,
			statusLoadingMoreVenues: 'failure',
		}
	}),
	on(VenueActions.setLocation, (state, action): VenueState => {
		return {
			...state,
			location: {
				latitude: action.latitude,
				longitude: action.longitude,
				maxDistance: action.maxDistance,
			},
		}
	})
)
