<app-header></app-header>

<div class="colorTop">
	<div class="breadcrumb">
		<app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>
	</div>

	<div class="client">
		<app-client></app-client>
	</div>
</div>

<div class="carrossel">
	<div class="carrossel__box px-16">
		<button class="carrossel__btn" routerLink="/como-funciona">Veja como funciona</button>
	</div>
	<app-carrossel
		[imagesDesk]="['./../assets/images/carro1.jpg',]"
		[imagesMobile]="['./../assets/images/carro2.jpg',]"
	></app-carrossel>
</div>

<div class="body px-16">
	<div class="body__filter">
		<div class="body__filterInside">
			<app-filter></app-filter>
		</div>
	</div>

	<div class="order-active" *ngIf="hasPaymentReady$ | async">
		<h1 class="order-active__title">Reserva ativa</h1>
		<app-order-card *ngFor="let order of ordersToPay$ | async" [order]="order"></app-order-card>
	</div>

	<div style="height: 19px"></div>

	<div class="loading" *ngIf="isLoadingVenues$ | async" style="margin-bottom: 50px">
		<app-spinner></app-spinner>
	</div>

	<ng-container *ngIf="venues$ | async as venues">
		<div class="body__venue px-desk">
			<app-venue-card *ngFor="let venue of venues" [venue]="venue"></app-venue-card>
			<div *ngIf="venues.length === 0">Nenhum restaurante encontrado.</div>
		</div>
		<ng-container *ngIf="loadingObject$ | async as loadingObject">
			<div class="wrapperButton">
				<button
					class="btn btn--inverted viewMore"
					*ngIf="!loadingObject.allLoaded && !loadingObject.loadingMoreVenues"
					(click)="viewMoreVenues()"
				>
					Ver mais
				</button>
				<app-spinner *ngIf="loadingObject.loadingMoreVenues"></app-spinner>
			</div>
		</ng-container>
	</ng-container>
</div>

<app-footer></app-footer>

<app-handle-login-token page="venue-list"></app-handle-login-token>

<app-modal [id]="modal.error" top="25%">
	<app-modal-basic [id]="modal.error" title="Login falhou" *ngIf="error$ | async as error">
		{{ error }}
	</app-modal-basic>
</app-modal>
