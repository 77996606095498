import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Store } from '@ngrx/store'
import { tap } from 'rxjs'
import { BreadcrumbsData } from 'src/app/components/breadcrumb/breadcrumb.component'
import { PREFIX_TITLE } from 'src/shared/helpers/prefix-title'
import { AppState } from 'src/state/app.state'
import { getSelectedVenue } from 'src/state/venue/venue.reducers'

@Component({
	template: `
		<app-header></app-header>
		<div style="padding: 11px 0">
			<app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>
		</div>
		<app-confirm-reservation></app-confirm-reservation>
		<app-footer></app-footer>
	`,
})
export class ConfirmReservationSuccessPage {
	selectedVenue$ = this.store.select(getSelectedVenue).pipe(
		tap(venue =>
			this.title.setTitle(`${PREFIX_TITLE} - ${venue?.name} - Reserva realizada com sucesso!`)
		),
		tap(venue => {
			setTimeout(() => {
				this.breadcrumbs = [
					{ url: '/gastronomia', title: 'Gastronomia' },
					{ url: `/gastronomia/${venue?._id}`, title: venue?.name ?? '' },
					{ url: `/gastronomia/${venue?._id}/reserva-sucesso`, title: 'Reserva sucesso' },
				]
			}, 0)
		})
	)
	breadcrumbs: BreadcrumbsData[] = []
	constructor(private store: Store<AppState>, private title: Title) {}
}
