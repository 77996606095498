<div class="modalFilter">
	<div class="modalFilter__header">
		<p>Filtros</p>
		<button (click)="close.emit()">
			<i class="fa fa-close"></i>
		</button>
	</div>

	<div class="modalFilter__select">
		<p>Localização</p>

		<ch-select-box
			*ngIf="addresses$ | async as addresses"
			[items]="addresses"
			[selectedItem]="selectedAddress$ | async"
			icon="fa-localization"
			(itemWasSelected)="selectAddress($event)"
			option="persist"
		></ch-select-box>
	</div>

	<div class="modalFilter__select">
		<p>Tipo de culinária</p>
		<ch-select-box
			*ngIf="cuisines$ | async as cuisines"
			[items]="cuisines"
			icon="fa-cuccine"
			[selectedItem]="selectedCuisine$ | async"
			(itemWasSelected)="selectCuisine($event)"
			option="persist"
		></ch-select-box>
	</div>

	<!-- <div class="modalFilter__select">
		<p>Estilo de restaurante</p>
		<ch-select-box [items]="restaurante" icon="fa-restaurant"></ch-select-box>
	</div> -->

	<div class="modalFilter__button">
		<button id="clear" (click)="cleanFilter()">Limpar filtro</button>
		<button id="ok" (click)="applyFilter()">Ok</button>
	</div>
</div>
