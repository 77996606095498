import { Injectable } from '@angular/core'

type BRAND_PAGARME =
	| 'elo'
	| 'mastercard'
	| 'visa'
	| 'diners'
	| 'amex'
	| 'hipercard'
	| 'vr'
	| 'sodexo'
	| 'alelo'

@Injectable({ providedIn: 'root' })
export class ChooseBrandService {
	constructor() {}
	get(value: string | undefined) {
		if (!value) {
			return
		}
		const valueLowerCAse = value.toLowerCase() as BRAND_PAGARME
		const PREFIX = '/assets/images'
		const chooseBrand: Record<BRAND_PAGARME, { src: string; alt: string }> = {
			alelo: {
				src: `${PREFIX}/card-alelo.svg`,
				alt: 'Alelo',
			},
			amex: {
				src: `${PREFIX}/card-amex.svg`,
				alt: 'Amex',
			},
			diners: {
				src: `${PREFIX}/card-diners.svg`,
				alt: 'Diners',
			},
			elo: {
				src: `${PREFIX}/card-elo.svg`,
				alt: 'Elo',
			},
			hipercard: {
				src: `${PREFIX}/card-hipercard.svg`,
				alt: 'Hipercard',
			},
			mastercard: {
				src: `${PREFIX}/card-mastercard.svg`,
				alt: 'Mastercard',
			},
			sodexo: {
				src: `${PREFIX}/card-sodexo.svg`,
				alt: 'Sodexo',
			},
			visa: {
				src: `${PREFIX}/card-visa.svg`,
				alt: 'Visa',
			},
			vr: {
				src: 'card-hipercard.svg',
				alt: 'Hipercard',
			},
		}
		return chooseBrand[valueLowerCAse]
	}
}
