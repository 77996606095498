<div class="card" [routerLink]="['/gastronomia', venue.id]">
	<img *ngIf="venue.coverURL.value" [src]="venue.coverURL.value" class="card__img" />
	<div *ngIf="!venue.coverURL.value" class="card__img"></div>

	<div class="info">
		<app-venue-info
			[name]="venue.name"
			[address]="venue.address.value"
			[cuisines]="venue.cuisines"
			[limit]="3"
			size="small"
		></app-venue-info>

		<hr class="hr" />

		<app-benefits [benefit]="venue.benefits" size="small"></app-benefits>

		<div class="info__reserve">
			<button>
				Reservar uma mesa <i style="font-weight: bold" class="tagmeicon tagmeicon-seta"></i>
			</button>
		</div>
	</div>
</div>
