import { Component, Input } from '@angular/core'
import { ModalService } from '../modal.service'

@Component({
	selector: 'app-modal-basic',
	template: `
		<div class="wrapper">
			<app-modal-header (close)="close()" [hasClose]="hasClose">{{ title }}</app-modal-header>
			<ng-content></ng-content>
		</div>
	`,
	styles: [
		`
			.wrapper {
				position: fixed;
				left: 50%;
				transform: translateX(-50%);

				display: flex;
				gap: 16px;
				flex-direction: column;

				background-color: var(--color-white);
				padding: 16px;
				width: 343px;
				border-radius: 8px;
			}
		`,
	],
})
export class ModalBasicComponent {
	@Input() id!: string
	@Input() hasClose = true
	@Input() title!: string

	constructor(private modalService: ModalService) {}

	close() {
		this.modalService.close(this.id)
	}
}
