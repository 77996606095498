import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, map } from 'rxjs'

interface Option {
	id: string
	enabled: boolean
}

@Injectable({ providedIn: 'root' })
export class MenuEffectService {
	private showOptionsSub = new BehaviorSubject<Option[]>([])
	public showOptions$ = this.showOptionsSub.asObservable()

	createNewOption(id: string) {
		const newOption = { id, enabled: false }
		const optionsBefore = this.showOptionsSub.getValue()
		this.showOptionsSub.next([...optionsBefore, newOption])
	}

	enableOption(id: string) {
		const optionsBefore = this.showOptionsSub.getValue()
		const newOptions = optionsBefore.map(option => {
			if (option.id === id) {
				return { ...option, enabled: !option.enabled }
			}
			return { ...option, enabled: false }
		})
		this.showOptionsSub.next(newOptions)
	}

	getOption(id: string) {
		return this.showOptions$.pipe(map(options => options.find(option => option.id === id)))
	}
}
