<div class="wrapper">
	<app-modal-header (close)="close()">Horários de funcionamento</app-modal-header>
	<ul class="list">
		<li class="list__item" *ngFor="let operationHour of operationHoursByTime">
			<p class="list__text">
				<span class="list__text--strong">{{ getWeekday(operationHour.day) }}: </span>
				&nbsp;{{ operationHour.times }}
			</p>
		</li>
	</ul>
</div>
