import { Inject, Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { HttpOrderGateway } from 'src/gateway/order/http-order.gateway'
import OrderGateway from 'src/gateway/order/order.gateway'
import { AppState } from '../app.state'
import { mergeMap, switchMap, catchError, of, map } from 'rxjs'
import { getClient } from '../client/client.reducers'
import { CheckInActions } from './checkin.actions'
import { getOrderId } from './checkin.reducers'

@Injectable()
export class CheckinEffects {
	constructor(
		private actions$: Actions,
		@Inject(HttpOrderGateway) private orderGateway: OrderGateway,
		private store: Store<AppState>
	) {}

	loadAvailabilities$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(CheckInActions.loadAvailabilities),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) => {
				return this.orderGateway
					.checkAvailability({
						venueId: action.venueId,
						clientId: client!.id,
						categoryId: client!.selectedCategory!.categoryId,
						intent: 'checkin',
					})
					.pipe(
						map(response => {
							if (!response.success) {
								return CheckInActions.checkinUnavailable({
									availabilities: response.availabilities,
								})
							}
							return CheckInActions.loadAvailabilitiesSuccess({
								availabilities: response.availabilities,
								orderId: response.order._id,
							})
						}),
						catchError(error =>
							of(CheckInActions.loadAvailabilitiesFailure({ error: error.error?.message }))
						)
					)
			})
		)
	})

	confirmCheckin$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(CheckInActions.checkinConfirm),
			concatLatestFrom(() => [this.store.select(getOrderId), this.store.select(getClient)]),
			mergeMap(([action, orderId, client]) =>
				this.orderGateway
					.confirmOrder(orderId as string, {
						reservationDay: action.checkInDate.day,
						reservationTime: action.checkInDate.time,
						partySize: action.partySize,
						clientId: client!.id,
					})
					.pipe(
						map(() => CheckInActions.checkinConfirmSuccess()),
						catchError(error =>
							of(CheckInActions.checkinConfirmFailure({ error: error?.error?.message }))
						)
					)
			)
		)
	})
}
