export class Benefits {
	points?: number
	pointsWithoutMultiplier?: number
	constructor(props: Benefit[] | undefined) {
		if (!props) return
		const points = props.find(benefit => benefit?.type === 'points')?.value
		const multiplier = props.find(benefit => benefit.type === 'multiplier')?.value
		this.pointsWithoutMultiplier = Number(points)
		if (multiplier) {
			this.points = Number(points) * Number(multiplier)
		} else {
			this.points = Number(points)
		}
	}
}

export interface Benefit {
	_id: string
	type: string
	value: string
	description: string
	name: string
	icon: string
	partnerId: string
	isDeleted: boolean
	deletedAt: any
	createdAt: string
	updatedAt: string
	__v: number
	enabled: boolean
	transferValue: string
	descriptionLong: string
	order: number
	revenueValue?: string
}
