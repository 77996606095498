import { Component, HostBinding, HostListener, Input, OnDestroy, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subject, takeUntil } from 'rxjs'
import { Benefits } from 'src/entity/benefits'
import { MOBILE_BREAKPOINT } from 'src/shared/helpers/breakpoint'
import { AppState } from 'src/state/app.state'
import { getBiggestMultiplier } from 'src/state/benefits/benefits.reducers'

@Component({
	selector: 'app-benefits',
	templateUrl: './benefits.component.html',
	styleUrls: ['benefits.component.scss'],
})
export class BenefitsComponent implements OnInit, OnDestroy {
	@Input() showPoint: boolean = true
	@Input() benefit!: Benefits | undefined
	@Input() size: 'default' | 'small' = 'default'
	private destroy$ = new Subject<void>()
	multiplyPoints: number | undefined
	isMobile = false

	constructor(private store: Store<AppState>) {}

	ngOnInit(): void {
		this.getScreensize()
		this.store
			.select(getBiggestMultiplier)
			.pipe(takeUntil(this.destroy$))
			.subscribe(biggestMultiplier => {
				if (biggestMultiplier) {
					this.multiplyPoints = Number(this.benefit?.pointsWithoutMultiplier) * biggestMultiplier
				}
			})
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	@HostListener('window:resize', ['$event'])
	getScreensize() {
		this.isMobile = window.innerWidth < MOBILE_BREAKPOINT
	}

	@HostBinding('style')
	get addProps() {
		const smallProps = {
			'--fs-point': '16px',
			'--fs-pass-p': '10px',
			'--fs-pass-value-p': '11px',
			'--fs-pass-value-point': '11px',
		}
		const defaultProps = {
			'--fs-point': '24px',
			'--fs-pass-p': '12px',
			'--fs-pass-value-p': '12px',
			'--fs-pass-value-point': '16px',
		}

		if (this.isMobile) {
			return defaultProps
		}

		if (this.size === 'small') return smallProps
		return defaultProps
	}
}
