import { Component, OnDestroy, OnInit } from '@angular/core'
import { getSelectedVenue } from 'src/state/venue/venue.reducers'
import { Store } from '@ngrx/store'
import { AppState } from 'src/state/app.state'
import { Actions, ofType } from '@ngrx/effects'
import { Subject, filter, merge, takeUntil, tap } from 'rxjs'
import { CheckInActions } from 'src/state/checkin/checkin.actions'
import { ActivatedRoute, Router } from '@angular/router'
import { getErrorAvailabilities, getErrorConfirm } from 'src/state/checkin/checkin.reducers'
import { ModalService } from 'src/app/components/modal/modal.service'
import { Location } from '@angular/common'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/shared/helpers/prefix-title'
import { BreadcrumbsData } from 'src/app/components/breadcrumb/breadcrumb.component'

@Component({
	template: `
		<app-header></app-header>
		<div style="margin-top: 16px">
			<app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>
		</div>

		<div class="px-16">
			<p class="title">Check-in</p>
			<p class="venueName" *ngIf="selectedVenue$ | async as venue">{{ venue.name }}</p>
		</div>
		<app-checkin></app-checkin>
		<app-footer></app-footer>
		<app-modal [id]="modal.error" top="10%" [preventOutsideClickClose]="true">
			<app-modal-basic
				[id]="modal.error"
				title="Algum erro aconteceu"
				*ngIf="error$ | async as error"
				[hasClose]="false"
			>
				{{ error }}
				<button class="btn btn--inverted" (click)="location.back()">Voltar</button>
			</app-modal-basic>
		</app-modal>
	`,
	styles: [
		`
			.title {
				color: var(--color-blue);
				font-size: 16px;
				font-style: italic;
				line-height: 24px;
				margin-top: 24px;
				margin-bottom: 6px;
			}
			.venueName {
				color: var(--color-blue-dark);
				font-size: 20px;
				font-weight: 600;
				line-height: 28px;
			}
		`,
	],
})
export class CheckinConfirmPage implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>()
	modal = {
		error: 'modal-error-id',
	}
	error$ = merge(this.store.select(getErrorAvailabilities), this.store.select(getErrorConfirm))
	constructor(
		private store: Store<AppState>,
		private actions$: Actions,
		private router: Router,
		private route: ActivatedRoute,
		private modalService: ModalService,
		public location: Location,
		private title: Title
	) {}
	ngOnInit(): void {
		this.actions$
			.pipe(ofType(CheckInActions.checkinConfirmSuccess), takeUntil(this.destroy$))
			.subscribe(() => {
				this.router.navigate(['../checkin-sucesso'], { relativeTo: this.route })
			})

		this.actions$
			.pipe(
				ofType(CheckInActions.checkinConfirmFailure, CheckInActions.loadAvailabilitiesFailure),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.modalService.open(this.modal.error)
			})
	}
	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	breadcrumbs: BreadcrumbsData[] = []
	selectedVenue$ = this.store.select(getSelectedVenue).pipe(
		filter(value => Boolean(value)),
		tap(venue => this.title.setTitle(`${PREFIX_TITLE} - ${venue?.name} - Check-In`)),
		tap(venue => {
			setTimeout(() => {
				this.breadcrumbs = [
					{ url: '/gastronomia', title: 'Gastronomia' },
					{ url: `/gastronomia/${venue?._id}`, title: venue?.name ?? '' },
					{ url: `/gastronomia/${venue?._id}/checkin`, title: 'Checkin' },
				]
			}, 0)
		})
	)
}
