import { Component, OnDestroy, OnInit, HostListener } from '@angular/core'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Subject, takeUntil } from 'rxjs'
import { environment as env } from 'src/environments/environment'
import { AppState } from 'src/state/app.state'
import { ClientActions } from 'src/state/client/client.actions'
import { getClient, isLoggedIn } from 'src/state/client/client.reducers'
import { OrderHistoryActions } from 'src/state/order-history/order-history.actions'
import { getHasPaymentReady } from 'src/state/order-history/order-history.reducers'

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	@HostListener('window:scroll', ['$event'])
	onScroll(event: any) {
		this.exitMenu()
	}
	showDivMobile = false
	showDivDesktop = false
	showSubMenu2 = false
	showSubMenu3 = false
	showSubMenu4 = false
	showSubMenu5 = false
	showMenuUser = false
	showMenuUserDesk = false
	menuSelected: string = ''
	signUpUrl!: string
	loginUrl!: string
	isLoggedIn$ = this.store.select(isLoggedIn)
	client$ = this.store.select(getClient)
	hasPaymentReady = false

	private destroy$ = new Subject<void>()
	constructor(private store: Store<AppState>, private actions$: Actions) {}

	ngOnInit(): void {
		const redirectTo = window.location.href
		this.loginUrl = `${env.latamPassUrl}/login?url=${redirectTo}`
		this.signUpUrl = 'https://www.latamairlines.com/br/pt/cadastro'

		this.actions$
			.pipe(ofType(ClientActions.loginClientSuccess), takeUntil(this.destroy$))
			.subscribe(() => {
				this.store.dispatch(OrderHistoryActions.getOrdersPaymentReady())
			})

		this.store
			.select(getHasPaymentReady)
			.pipe(takeUntil(this.destroy$))
			.subscribe(hasPaymentReady => {
				this.hasPaymentReady = hasPaymentReady
			})
	}
	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	logout() {
		this.store.dispatch(ClientActions.logoutClient())
	}

	showMenuMobile() {
		this.showDivMobile = !this.showDivMobile
	}

	showMenuDesktop(menu: string) {
		this.showDivDesktop = true
		this.menuSelected = menu
	}

	showMenu2Sub() {
		this.showSubMenu2 = !this.showSubMenu2
		this.showSubMenu3 = false
		this.showSubMenu4 = false
		this.showSubMenu5 = false
	}

	showMenu3Sub() {
		this.showSubMenu3 = !this.showSubMenu3
		this.showSubMenu2 = false
		this.showSubMenu4 = false
		this.showSubMenu5 = false
	}

	showMenu4Sub() {
		this.showSubMenu4 = !this.showSubMenu4
		this.showSubMenu3 = false
		this.showSubMenu2 = false
		this.showSubMenu5 = false
	}

	showMenu5Sub() {
		this.showSubMenu5 = !this.showSubMenu5
		this.showSubMenu3 = false
		this.showSubMenu4 = false
		this.showSubMenu2 = false
	}

	menuUser() {
		this.showMenuUser = !this.showMenuUser
	}

	menuUserDesk() {
		this.showMenuUserDesk = !this.showMenuUserDesk
	}

	exitMenu() {
		this.showDivDesktop = false
		this.showMenuUser = false
		this.showMenuUserDesk = false
	}
}
