import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Store } from '@ngrx/store'
import { combineLatest, filter, map } from 'rxjs'
import { AppState } from 'src/state/app.state'
import { Availability, Schedules, Section } from 'src/state/reservation/reservation'
import { AvailabilityActions } from 'src/state/reservation/reservation.actions'
import {
	CHOOSE_DATE,
	CHOOSE_PARTYSIZE,
	CHOOSE_SECTION,
	getAvailabilities,
	getErrorAvailability,
	getReservationTime,
	getSchedules,
	getSections,
	getSelectedDate,
	getSelectedPartySize,
	getSelectedReservationTime,
	getSelectedSection,
	isLoadingAvailabililities,
	isLoadingConfirmReservation,
} from 'src/state/reservation/reservation.reducers'
import { ReservationDay } from '../../../shared/helpers/reservation-day'
import { Item } from '../select-box/select-box.component'
import { Partysize } from 'src/shared/helpers/partysize'

@Component({
	selector: 'app-reservation-form',
	templateUrl: './reservation-form.component.html',
	styleUrls: ['./reservation-form.component.scss'],
})
export class ReservationFormComponent implements OnInit {
	venueId: string | null = null
	//Error
	errorAvailability$ = this.store.select(getErrorAvailability)

	// Loading
	loadingAvailabilities$ = this.store.select(isLoadingAvailabililities)
	loadingConfirmReservation$ = this.store.select(isLoadingConfirmReservation)

	// DATE
	selectedDate$ = this.store.select(getSelectedDate)
	availabilitiesDatesItems$ = this.store.select(getAvailabilities).pipe(
		filter(availability => Boolean(availability?.length)),
		map(
			availabilities =>
				[{ reservationDay: CHOOSE_DATE }, ...(availabilities as Availability[])] as Availability[]
		),
		map(availabilities =>
			availabilities?.map(av => ({
				name: ReservationDay.format(av.reservationDay),
				value: av.reservationDay,
			}))
		)
	)
	// SECTIONS
	sectionsItems$ = this.store.select(getSections).pipe(
		filter(value => Boolean(value?.length)),
		map(section => [{ label: CHOOSE_SECTION }, ...(section as Section[])]),
		map(section =>
			section.map(sec => ({
				name: sec.label,
				value: sec.label,
			}))
		)
	)
	selectedSection$ = this.store.select(getSelectedSection)

	// SCHEDULES // PARTYSIZE
	schedulesItems$ = this.store.select(getSchedules).pipe(
		filter(value => Boolean(value?.length)),
		map(schedule => [{ partySize: CHOOSE_PARTYSIZE }, ...(schedule as Schedules[])]),
		map(schedule =>
			schedule?.map(sch => ({
				name: Partysize.format(sch.partySize.toString()),
				value: sch.partySize.toString(),
			}))
		)
	)
	selectedSchedule = this.store.select(getSelectedPartySize)

	// RESERVATION TIMES
	reservationTimes$ = this.store.select(getReservationTime)
	selectedReservationTime$ = this.store.select(getSelectedReservationTime)
	clickedTime = false

	times$ = this.reservationTimes$.pipe(
		filter(res => Boolean(res)),
		map((res: any) => {
			return {
				firstTime: res[0].reservationTime,
				lastTime: res[res?.length - 1].reservationTime,
			}
		})
	)

	vmTimes$ = combineLatest([this.reservationTimes$, this.selectedReservationTime$, this.times$]).pipe(
		map(([reservationTimes, selectedReservationTime, times]) => ({
			reservationTimes,
			selectedReservationTime,
			times,
		}))
	)

	formIsInvalid$ = combineLatest([
		this.selectedDate$,
		this.selectedSection$,
		this.selectedSchedule,
		this.selectedReservationTime$,
	]).pipe(
		map(([date, section, schedule, reservationTime]) => {
			return !Boolean(date && section && schedule && reservationTime)
		})
	)

	constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.venueId = this.route.snapshot.paramMap.get('venueId') as string
		this.store.dispatch(AvailabilityActions.resetReservationState())
		this.store.dispatch(AvailabilityActions.loadAvailabilities({ venueId: this.venueId }))
	}

	dateSelected(date: Item) {
		this.store.dispatch(AvailabilityActions.setSelectedDate({ selectedDate: date.value }))
	}

	sectionSelected(section: Item) {
		this.store.dispatch(AvailabilityActions.setSection({ selectedSectionLabel: section.value }))
	}

	scheduleSelected(partysize: Item) {
		this.store.dispatch(AvailabilityActions.setSchedule({ selectedPartySize: partysize.value }))
	}

	timeSelected(time: string) {
		this.store.dispatch(AvailabilityActions.setReservationTime({ selectedReservationTime: time }))
	}

	confirmReservation() {
		this.store.dispatch(AvailabilityActions.confirmReservation())
	}
}
