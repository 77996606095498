<div class="ticket">
	<p class="ticket__item">
		<ng-container *ngIf="templates.default">
			<span class="ticket__item__label">{{ label }}</span>
			<span class="ticket__item__data">{{ data }}</span>
		</ng-container>

		<ng-container *ngIf="templates.creditCard">
			<p class="ticket__item__label">{{ label }}</p>
			<div class="ticket__item__data inline">
				<img [src]="chooseCard.src" [alt]="chooseCard.alt" class="ticket__item__img" />
				<i class="ticket__item__icon fa fa-bullets"></i>
				<p class="ticket__item__theLast">{{ data }}</p>
			</div>
		</ng-container>

		<ng-container *ngIf="templates.tooltip">
			<p class="ticket__item__label">
				{{ label }}
				<span class="ticket__item__label__icon fa fa-info">
					<div class="tooltip">
						<p class="tooltip__text">
							Seus pontos estarão disponíveis para uso em até {{ daysText }}.
						</p>
					</div>
				</span>
			</p>
			<p class="ticket__item__data">{{ data }}</p>
		</ng-container>
	</p>
</div>
