import { Component } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Actions } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { environment } from 'src/environments/environment'
import { DataLayerService } from 'src/shared/services/gtm/data-layer.service'
import { AppState } from 'src/state/app.state'
import { ClientActions } from 'src/state/client/client.actions'
import { getClient } from 'src/state/client/client.reducers'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent {
	title = 'Latam'
	env = environment.env
	clientId: string | null = null
	client$ = this.store.select(getClient)

	constructor(
		private router: Router,
		private dataLayerService: DataLayerService,
		private store: Store<AppState>,
		private actions$: Actions
	) {
		this.router.events.subscribe(event => {
			// subscribe to router events
			if (event instanceof NavigationEnd) {
				// if our event is of our interest
				this.dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
			}
		})
	}

	login() {
		if (this.clientId) {
			this.store.dispatch(ClientActions.loginClientHomologation({ id: this.clientId }))
		}
	}
}
