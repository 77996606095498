<div class="menuMobile" *ngIf="showOption$ | async as option">
	<li
		class="menuMobile__li"
		(click)="openOptions()"
		[ngClass]="{ 'menuMobile__li--active': option.enabled }"
	>
		<div>{{ title }}</div>
	</li>
	<ng-container>
		<div class="menuMobile__visible" *ngIf="option.enabled">
			<ng-container *ngIf="templates.viagens">
				<h2 class="menuMobile__subtitle">Viagens</h2>
				<a href="https://latampass.latam.com/pt_br/passagens" class="menuMobile__link"
					>Buscar Passagens</a
				>
				<a
					href="https://latampass.latam.com/pt_br/viagem/usar-pontos-para-voar/regras-de-resgate/latam"
					class="menuMobile__link"
					>Usar pontos para voar</a
				>
				<a href="https://www.latam.com/retros/" class="menuMobile__link"
					>Solicitar Pontos Pendentes em Voo</a
				>
				<hr class="menuMobile__hr" />
				<a href="https://latampass.latam.com/pt_br/viagem/hospedagens" class="menuMobile__link"
					>Hospedagem</a
				>
				<a href="https://latampass.latam.com/pt_br/viagem/aluguel-de-carro" class="menuMobile__link"
					>Aluguel de carros</a
				>
				<a href="https://latampass.latam.com/pt_br/viagem/latam-travel" class="menuMobile__link"
					>Pacotes de Viagem</a
				>
				<hr class="menuMobile__hr" />
				<a href="https://latampass.latam.com/pt_br/categorias-elite" class="menuMobile__link"
					>Categorias Elite</a
				>
				<a
					href="https://latampass.latam.com/pt_br/latampass-itaucard/beneficios-mastercard"
					class="menuMobile__link"
					>Benefícios LATAM Pass Itaú</a
				>
				<a href="https://latampass.latam.com/pt_br/clube" class="menuMobile__link"
					>Clube LATAM Pass</a
				>
				<hr class="menuMobile__hr" />
			</ng-container>
			<ng-container *ngIf="templates.shopping">
				<h2 class="menuMobile__subtitle">Troque</h2>
				<a href="https://resgatepontos.latampass.latam.com/use-pontos" class="menuMobile__link"
					>Troque Pontos</a
				>
				<a href="https://resgatepontos.latampass.latam.com/ganhe-pontos" class="menuMobile__link"
					>Ganhar Pontos</a
				>
				<a href="https://latampass.latam.com/pt_br/resgate-pontos/parceiros" class="menuMobile__link"
					>Explorar Parceitos</a
				>
				<hr class="menuMobile__hr" />
			</ng-container>
			<ng-container *ngIf="templates.junte_pontos">
				<h2 class="menuMobile__subtitle">Junte</h2>
				<a href="https://latampass.latam.com/pt_br/juntar-pontos" class="menuMobile__link"
					>Juntar Pontos</a
				>
				<a href="https://latampass.latam.com/pt_br/pontos/como-juntar-pontos" class="menuMobile__link"
					>Como juntar pontos</a
				>
				<a
					href="https://latampass.latam.com/pt_br/pontos/como-juntar-pontos/solicitar-pontos-em-voo"
					class="menuMobile__link"
					>Juntar pontos voando</a
				>
				<hr class="menuMobile__hr" />
				<div class="menuMobile__subtitle">Exlore</div>
				<a
					href="https://latampass.latam.com/pt_br/ganhar-pontos/cartoes-de-bancos"
					class="menuMobile__link"
					>Cartões de banco</a
				>
				<a href="https://latampass.latam.com/pt_br/junte-pontos/abastece-ai" class="menuMobile__link"
					>Ipiranga</a
				>
				<a
					href="https://www.pontosmultiplus.com.br/facilidades/compradepontos"
					class="menuMobile__link"
					>Comprar pontos</a
				>
				<hr class="menuMobile__hr" />
				<div class="menuMobile__subtitle">Acelere</div>
				<a
					href="https://latampass.latam.com/pt_br/pontos/acelere-seus-pontos"
					class="menuMobile__link"
					>Acelere seus pontos</a
				>
				<a href="https://latampass.latam.com/pt_br/latampass-itaucard" class="menuMobile__link"
					>Cartão LATAM Pass Itaú</a
				>
				<a href="https://latampass.latam.com/pt_br/clube" class="menuMobile__link"
					>Clube LATAM Pass</a
				>
				<hr class="menuMobile__hr" />
			</ng-container>
			<ng-container *ngIf="templates.acelere">
				<h2 class="menuMobile__subtitle">Acelere seus pontos</h2>
				<a class="menuMobile__link" href="https://latampass.latam.com/pt_br/latampass-itaucard"
					>Cartão LATAM Pass Itaú</a
				>
				<a class="menuMobile__link" href="https://latampass.latam.com/pt_br/clube"
					>Cartão LATAM Pass
				</a>
				<a
					class="menuMobile__link"
					href="https://www.pontosmultiplus.com.br/facilidades/compradepontos"
					>Comprar Pontos</a
				>
				<hr class="menuMobile__hr" />
				<a
					class="menuMobile__link"
					href="https://latampass.latam.com/pt_br/pontos/acelere-seus-pontos/multiplicar"
					>Multiplique seus pontos</a
				>
				<a
					class="menuMobile__link"
					href="https://latampass.latam.com/pt_br/pontos/acelere-seus-pontos/renovar"
					>Renove seus pontos</a
				>
				<a
					class="menuMobile__link"
					href="https://latampass.latam.com/pt_br/pontos/acelere-seus-pontos/transferir"
					>Transfira seus pontos</a
				>
				<hr class="menuMobile__hr" />
				<a class="menuMobile__link" href="https://latampass.latam.com/pt_br/passagens"
					>Resgate aéreo</a
				>
				<hr class="menuMobile__hr" />
			</ng-container>
			<ng-container *ngIf="templates.mais">
				<h2 class="menuMobile__subtitle">Sobre</h2>
				<a href="https://latampass.latam.com/pt_br/sobre-o-programa" class="menuMobile__link"
					>Sobre o programa</a
				>
				<a href="https://latampass.latam.com/pt_br/pontos/como-juntar-pontos" class="menuMobile__link"
					>Como juntar pontos</a
				>
				<a
					href="https://latampass.latam.com/pt_br/viagem/usar-pontos-para-voar/regras-de-resgate/latam"
					class="menuMobile__link"
					>Use pontos pra voar</a
				>
				<hr class="menuMobile__hr" />
				<h2 class="menuMobile__subtitle">Extras</h2>
				<a href="https://latampass.latam.com/pt_br/baixe-o-app" class="menuMobile__link"
					>Baixe o App</a
				>
				<a href="https://latampass.latam.com/pt_br/viagem/seguros" class="menuMobile__link"
					>Seguros</a
				>
				<a
					href="https://latampass.latam.com/pt_br/pontos/como-juntar-pontos/solicitar-pontos-em-voo/companhias-parceiras"
					class="menuMobile__link"
					>Compahias aereas</a
				>
				<hr class="menuMobile__hr" />
				<h2 class="menuMobile__subtitle">Benefícios</h2>
				<a href="https://latampass.latam.com/pt_br/categorias-elite" class="menuMobile__link"
					>Categorias Elite</a
				>
				<a href="https://latampass.latam.com/pt_br/latampass-itaucard" class="menuMobile__link"
					>Cartão LATAM Pass Itaú</a
				>
				<a href="https://latampass.latam.com/pt_br/clube" class="menuMobile__link"
					>Clube LATAM Pass</a
				>
				<hr class="menuMobile__hr" />
			</ng-container>
		</div>
	</ng-container>
</div>
