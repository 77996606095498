import { Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Subject, filter, merge, takeUntil, tap } from 'rxjs'
import { BreadcrumbsData } from 'src/app/components/breadcrumb/breadcrumb.component'
import { ModalService } from 'src/app/components/modal/modal.service'
import { PREFIX_TITLE } from 'src/shared/helpers/prefix-title'
import { AppState } from 'src/state/app.state'
import { AvailabilityActions } from 'src/state/reservation/reservation.actions'
import { getErrorAvailability, getErrorConfirmation } from 'src/state/reservation/reservation.reducers'
import { VenueActions } from 'src/state/venue/venue.actions'
import { getSelectedVenue } from 'src/state/venue/venue.reducers'

@Component({
	templateUrl: 'create-reservation.page.html',
	styleUrls: ['create-reservation.page.scss'],
})
export class CreateReservationPage implements OnInit, OnDestroy {
	selectedVenue$ = this.store.select(getSelectedVenue).pipe(
		filter(value => Boolean(value)),
		tap(venue => this.title.setTitle(`${PREFIX_TITLE} - ${venue?.name} - Reserva`)),
		tap(venue => {
			setTimeout(() => {
				this.breadcrumbs = [
					{ url: '/gastronomia', title: 'Gastronomia' },
					{ url: `/gastronomia/${venue?._id}`, title: venue?.name ?? '' },
					{ url: `/gastronomia/${venue?._id}/reserva`, title: 'Reserva' },
				]
			}, 0)
		})
	)

	breadcrumbs: BreadcrumbsData[] = []
	modal = { error: 'modal-error-reservation-id' }
	error$ = merge(this.store.select(getErrorAvailability), this.store.select(getErrorConfirmation))
	private destroy$ = new Subject<void>()

	constructor(
		private store: Store<AppState>,
		private route: ActivatedRoute,
		private router: Router,
		private actions$: Actions,
		private modalService: ModalService,
		private title: Title
	) {}

	ngOnInit(): void {
		const venueId = this.route.snapshot.paramMap.get('venueId') as string

		this.store.dispatch(VenueActions.getSelectedVenue({ venueId }))

		this.actions$
			.pipe(ofType(AvailabilityActions.confirmReservationSuccess), takeUntil(this.destroy$))
			.subscribe(() => this.router.navigate(['../reserva-sucesso'], { relativeTo: this.route }))

		this.actions$
			.pipe(
				ofType(
					AvailabilityActions.confirmReservationFailure,
					AvailabilityActions.loadAvailabilitiesFailure
				),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.modalService.open(this.modal.error)
			})
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}
}
