import { Call } from '@angular/compiler'
import { Component } from '@angular/core'

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['footer.component.scss'],
})
export class FooterComponent {
	footerText = `@${new Date().getFullYear()} LATAM Airlines Brasil Rua Ática nº 673, 6º andar sala 62, CEP 04634-042 São Paulo/SP
			CNPJ: 02.012.862/0001-60 © 2022 LATAM Airlines S.A.`
}
