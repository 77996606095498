export class PartySize {
	value: string
	constructor(partySize: number | null) {
		if (!partySize) {
			this.value = ''
		} else {
			this.value = partySize <= 1 ? `${partySize} pessoa` : `${partySize} pessoas`
		}
	}
}
