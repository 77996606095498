import { Inject, Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { AppState } from '../app.state'
import { catchError, filter, map, mergeMap, of } from 'rxjs'
import { BenefitsActions } from './benefits.actions'
import { HttpBenefitGateway } from 'src/gateway/benefit/http-benefit.gateway'
import BenefitGateway from 'src/gateway/benefit/benefit.gateway'
import { getBiggestMultiplier } from './benefits.reducers'

@Injectable()
export class BenefitsEffects {
	constructor(
		private actions$: Actions,
		@Inject(HttpBenefitGateway) private benefitGateway: BenefitGateway,
		private store: Store<AppState>
	) {}

	// Cached
	getMultiplier$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(BenefitsActions.getBiggestMultiplier),
			concatLatestFrom(() => this.store.select(getBiggestMultiplier)),
			filter(([_, multiplier]) => !Boolean(multiplier)),
			map(() => BenefitsActions.loadBiggestMultiplier())
		)
	})

	// Call API (not cached)
	getBiggestMultiplier$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(BenefitsActions.loadBiggestMultiplier),
			mergeMap(() =>
				this.benefitGateway.biggestMulti().pipe(
					map(multiplier => BenefitsActions.loadBiggestMultiplierSuccess({ multiplier })),
					catchError(error => of(BenefitsActions.loadBiggestMultiplierFailure({ error })))
				)
			)
		)
	})
}
