import { createAction, props } from '@ngrx/store'
import { Availability } from '../reservation/reservation'
import { AvailabilityCheckin } from './checkin'
import { CheckinDate } from 'src/entity/checkin-date.vo'

const loadAvailabilities = createAction(
	'[Checkin Page/Venue-details] Load Availabilities',
	props<{ venueId: string }>()
)

const loadAvailabilitiesSuccess = createAction(
	'[Channels API] Load Availabilities(checkin) Success',
	props<{ availabilities: AvailabilityCheckin; orderId: string }>()
)

const loadAvailabilitiesFailure = createAction(
	'[Channels API] Load Availabilities(checkin) Fail',
	props<{ error: string }>()
)

const checkinUnavailable = createAction(
	'[Channels API] Load Availabilities(checkin) unavailable',
	props<{ availabilities: AvailabilityCheckin }>()
)

const checkinConfirm = createAction(
	'[Check-In Page] Check-In Confirmation',
	props<{ checkInDate: CheckinDate; partySize: number }>()
)

const checkinConfirmSuccess = createAction('[Channels API] Check-In Confirm Success')

const checkinConfirmFailure = createAction(
	'[Channels API] Check-In Confirm Failure',
	props<{ error: string }>()
)

const setOrderId = createAction(
	'[Channels API] Set OrderId after Loading Availabilities',
	props<{ orderId: string }>()
)

export const CheckInActions = {
	loadAvailabilities,
	loadAvailabilitiesSuccess,
	loadAvailabilitiesFailure,
	checkinUnavailable,
	setOrderId,
	checkinConfirm,
	checkinConfirmSuccess,
	checkinConfirmFailure,
}
