import { createAction, props } from '@ngrx/store'
import { Availability } from './reservation'

const loadAvailabilities = createAction(
	'[Reservation Page] Load Availabilities',
	props<{ venueId: string }>()
)

const loadAvailabilitiesSuccess = createAction(
	'[Channels API] Load Availabilities Success',
	props<{ availabilities: Availability[] }>()
)

const loadAvailabilitiesFailure = createAction(
	'[Channels API] Load Availabilities Fail',
	props<{ error: string }>()
)

const setSelectedDate = createAction(
	'[Reservation Page] Set Selected Date',
	props<{ selectedDate: string }>()
)

const setSection = createAction(
	'[Reservation Page] Set Selected Section',
	props<{ selectedSectionLabel: string }>()
)

const setSchedule = createAction('[Reservation Page] Set Schedule ', props<{ selectedPartySize: string }>())

const setReservationTime = createAction(
	'[Reservation Page] Set ReservationTime ',
	props<{ selectedReservationTime: string }>()
)

const setOrderId = createAction(
	'[Channels API] Set OrderId after Loading Availabilities',
	props<{ orderId: string }>()
)

const confirmReservation = createAction('[Channels API] POST Confirmm Reservation')

const confirmReservationSuccess = createAction('[Channels API] Confirm Reservation Success')

const confirmReservationFailure = createAction(
	'[Channels API] Confirm Reservation Fail',
	props<{ error: string }>()
)

const resetReservationState = createAction('[Channels API] Reset Reservation State')

export const AvailabilityActions = {
	resetReservationState,
	loadAvailabilities,
	setSelectedDate,
	setSchedule,
	setSection,
	setReservationTime,
	loadAvailabilitiesSuccess,
	loadAvailabilitiesFailure,
	setOrderId,
	confirmReservation,
	confirmReservationSuccess,
	confirmReservationFailure,
}
