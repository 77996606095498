<div class="footerOneMobile">
	<div class="footerOneMobile__one">
		<img src="./../assets/images/logo-latam-footer.svg" />
		<p>Nossas Redes</p>
	</div>

	<div class="footerOneMobile__midia">
		<a href="https://www.facebook.com/LATAMBrasil/" target="_blank"> <i class="fa fa-facebook"></i></a>
		<a href="https://twitter.com/latam_bra" target="_blank"> <i class="fa fa-twitter"></i></a>
		<a href="https://www.youtube.com/user/tam" target="_blank"> <i class="fa fa-youtube"></i></a>
		<a href="https://www.instagram.com/latampass/" target="_blank"> <i class="fa fa-instagram"></i></a>
		<img src="./../assets/images/abemf.png" />
	</div>

	<div class="footerOneMobile__apps">
		<a
			href="https://play.google.com/store/apps/details?id=br.com.multiplus.participantemultiplus"
			target="_blank"
			><img src="./../assets/images/google-play.png"
		/></a>
		<a href="https://apps.apple.com/br/app/latam-pass/id757157591" target="_blank"
			><img src="./../assets/images/apple-store.png"
		/></a>
	</div>
</div>

<div class="footerTwoMobile">
	<a href="https://www.latamairlines.com/br/pt/central-ajuda" target="_blank">Central de Ajuda</a>
	<a href="https://latampass.latam.com/pt_br/sobre-o-programa" target="_blank">Sobre o LATAM Pass</a>
	<a href="https://latampass.latam.com/pt_br/descubra-latam-pass/politica-de-privacidade" target="_blank"
		>Politica de Privacidade</a
	>
	<a href="https://latampass.latam.com/pt_br/descubra-latam-pass/politica-de-cookies" target="_blank"
		>Politica de Cookies</a
	>
	<a
		href="https://latampass.latam.com/pt_br/descubra-latam-pass/termos-e-condicoes-latam-pass-gastronomia"
		target="_blank"
		>Termos e Condições Gastronomia</a
	>
	<a href="https://career8.successfactors.com/career?company=lan&rcm_site_locale=pt_BR&" target="_blank"
		>Trabalhe Conosco</a
	>
	<a href="https://www.latamairlinesgroup.net/" target="_blank">LATAM Airlines Group</a>
	<a href="https://latampass.latam.com/pt_br/descubra-latam-pass/termos-e-condicoes" target="_blank"
		>Termos e Condições</a
	>
	<a href="https://resgatepontos.latampass.latam.com/home" target="_blank">Shopping LATAM Pass</a>
</div>

<div class="footerThreeMobile">
	<p>
		@2022 LATAM Airlines Brasil Rua Ática nº 673, 6º andar sala 62, CEP 04634-042 São Paulo/SP CNPJ:
		02.012.862/0001-60 © 2022 LATAM Airlines S.A.
	</p>
</div>

<div class="footerDesk">
	<div class="footerDesk__inside px-desk">
		<div class="footerDeskOne">
			<div class="footerDeskOne__logo">
				<img src="./../assets/images/logo-latam-footer.svg" />
				<span>Nossas Redes</span>
				<div id="midia">
					<a href="https://www.facebook.com/LATAMBrasil/" target="_blank">
						<i class="fa fa-facebook"></i
					></a>
					<a href="https://twitter.com/latam_bra" target="_blank"> <i class="fa fa-twitter"></i></a>
					<a href="https://www.youtube.com/user/tam" target="_blank">
						<i class="fa fa-youtube"></i
					></a>
					<a href="https://www.instagram.com/latampass/" target="_blank">
						<i class="fa fa-instagram"></i
					></a>
				</div>
			</div>
			<div class="footerDeskOne__optionsOne">
				<a href="https://www.latamairlines.com/br/pt/central-ajuda" target="_blank"
					>Central de Ajuda</a
				>
				<a href="https://latampass.latam.com/pt_br/sobre-o-programa" target="_blank"
					>Sobre o LATAM Pass</a
				>
				<a
					href="https://latampass.latam.com/pt_br/descubra-latam-pass/politica-de-privacidade"
					target="_blank"
					>Política de Privacidade</a
				>
				<a
					href="https://latampass.latam.com/pt_br/descubra-latam-pass/politica-de-cookies"
					target="_blank"
					>Política de Cookies</a
				>
				<a
					href="https://latampass.latam.com/pt_br/descubra-latam-pass/termos-e-condicoes-latam-pass-gastronomia"
					target="_blank"
					>Termos e Condições Gastronomia</a
				>
			</div>
			<div class="footerDeskOne__optionsTwo">
				<a
					href="https://career8.successfactors.com/career?company=lan&rcm_site_locale=pt_BR&"
					target="_blank"
					>Trabalhe Conosco</a
				>
				<a href="https://www.latamairlinesgroup.net/" target="_blank">LATAM Airlines Group</a>
				<a
					href="https://latampass.latam.com/pt_br/descubra-latam-pass/termos-e-condicoes"
					target="_blank"
					>Termos e Condições</a
				>
				<a href="https://resgatepontos.latampass.latam.com/home" target="_blank"
					>Shopping LATAM Pass</a
				>
			</div>
			<div class="footerDeskOne__img">
				<img src="./../assets/images/celulares.png" />
			</div>
		</div>

		<div class="footerDeskTwo">
			<div class="footerDeskTwo__abemf">
				<img src="./../assets/images/abemf.png" />
			</div>
			<div class="footerDeskTwo__apps">
				<a
					href="https://play.google.com/store/apps/details?id=br.com.multiplus.participantemultiplus"
					target="_blank"
					><img src="./../assets/images/google-play.png"
				/></a>
				<a href="https://apps.apple.com/br/app/latam-pass/id757157591" target="_blank"
					><img src="./../assets/images/apple-store.png"
				/></a>
			</div>
		</div>
	</div>
</div>
<div class="footerDeskThree px-desk">
	<div>
		<p>
			{{ footerText }}
		</p>
	</div>
</div>
