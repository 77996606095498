import { Component } from '@angular/core'
import { Store } from '@ngrx/store'
import { combineLatest, map, of } from 'rxjs'
import { AppState } from 'src/state/app.state'
import { getCheckInDate } from 'src/state/checkin/checkin.reducers'
import { getClient } from 'src/state/client/client.reducers'
import { getSelectedVenue } from 'src/state/venue/venue.reducers'

@Component({
	selector: 'app-checkin-success',
	template: `
		<ng-container *ngIf="vm$ | async as vm">
			<app-success-template template="checkin">
				<div style="display: flex; flex-direction: column;gap: 16px">
					<app-ticket label="Nome" [data]="vm.client?.fullName"> </app-ticket>
					<app-ticket label="Restaurante" [data]="vm.venue?.name"> </app-ticket>
					<app-ticket label="Data" [data]="vm.checkinDate?.dayFormatted"> </app-ticket>
				</div>
			</app-success-template>
			<div class="footer">
				<div style="height: 8px"></div>
				<app-benefits
					*ngIf="selectedVenue$ | async as selectedVenue"
					[benefit]="selectedVenue.benefits"
					[showPoint]="false"
				></app-benefits>
				<app-alert></app-alert>
			</div>
		</ng-container>
	`,
	styles: [
		`
			.footer {
				max-width: 343px;
				margin: 0 auto;
			}
		`,
	],
})
export class CheckinSuccessComponent {
	selectedVenue$ = this.store.select(getSelectedVenue)
	vm$ = combineLatest([
		this.store.select(getClient),
		this.selectedVenue$,
		this.store.select(getCheckInDate),
	]).pipe(map(([client, venue, checkinDate]) => ({ client, venue, checkinDate })))

	constructor(private store: Store<AppState>) {}
}
