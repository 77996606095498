import { createAction, props } from '@ngrx/store'
import { VenueDetail, VenueFilter } from 'src/entity/venues.entity'
import { FilterVenuesDto, FindOneVenueDto } from 'src/gateway/venue/venues.gateway'

// Filter
export const cleanFilter = createAction('[Venues Page] Clean Filter')
export const applyFilter = createAction('[Venues Page] Apply Filter')

export const getVenues = createAction('[Venues Page] Get Venues')
export const loadVenues = createAction(
	'[Venues Page] Load Venues',
	props<{ filterVenueDto?: FilterVenuesDto }>()
)
export const loadVenuesSuccess = createAction(
	'[Channels API] Load Venues Success',
	props<{ venues: VenueFilter[] }>()
)
export const loadVenuesFailure = createAction('[Channels API] Load Venues Fail', props<{ error: string }>())

// Venue
const getSelectedVenue = createAction(
	'[Reservation Page] Get One Venue',
	props<{ venueId: string; findOneVenueDto?: FindOneVenueDto }>()
)

const loadOneVenue = createAction(
	'[Venue Page] Load One Venue',
	props<{ venueId: string; findOneVenueDto?: FindOneVenueDto }>()
)

const loadOneVenueSuccess = createAction(
	'[Channels API] Load One Venue Success',
	props<{ venue: VenueDetail }>()
)

const loadOneVenueFailure = createAction('[Channels API] Load One Venue Fail', props<{ error: string }>())

const loadMoreVenue = createAction('[Venue Page] Load More Venue')
const loadMoreVenuesSuccess = createAction(
	'[Channels API] Load More Venue Success',
	props<{ venues: VenueFilter[] }>()
)
const loadMoreVenuesFailure = createAction('[Channels API] Load More Venue Fail', props<{ error: string }>())

const setLocation = createAction(
	'[Channels API] set current location',
	props<{ latitude: number; longitude: number; maxDistance: number }>()
)

const filterVenuesByLocation = createAction('Channels API] filter venues by location')

export const VenueActions = {
	getVenues,
	loadVenues,
	loadVenuesSuccess,
	loadVenuesFailure,
	cleanFilter,
	applyFilter,
	loadOneVenue,
	loadOneVenueSuccess,
	loadOneVenueFailure,
	getSelectedVenue,
	loadMoreVenue,
	loadMoreVenuesSuccess,
	loadMoreVenuesFailure,
	setLocation,
	filterVenuesByLocation,
}
