import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { ModalService } from '../modal.service'
import { AppState } from 'src/state/app.state'
import { Store } from '@ngrx/store'
import { getSelectedVenue } from 'src/state/venue/venue.reducers'

@Component({
	selector: 'app-modal-reservation',
	templateUrl: './modal-reservation.component.html',
	styleUrls: ['./modal-reservation.component.scss'],
})
export class ModalReservationComponent implements OnInit {
	selectedVenue$ = this.store.select(getSelectedVenue)
	@Output() close = new EventEmitter<void>()
	constructor(private store: Store<AppState>) {}

	ngOnInit(): void {}
}
