import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core'
import { ModalService } from '../modal.service'

type OperationHours = { day: string; start: string; end: string }
interface OperationHoursByTime {
	day: string
	times: string
}

@Component({
	selector: 'app-modal-schedules [operationHours]',
	templateUrl: './modal-schedules.component.html',
	styleUrls: ['./modal-schedules.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalSchedulesComponent implements OnInit {
	constructor(public modalService: ModalService) {}
	@Input() id!: string
	@Input() operationHours: OperationHours[] | undefined = []
	operationHoursByTime: OperationHoursByTime[] = []
	dict = {
		'1': 'Segunda',
		'2': 'Terça',
		'3': 'Quarta',
		'4': 'Quinta',
		'5': 'Sexta',
		'6': 'Sábado',
		'7': 'Domingo',
	}

	ngOnInit(): void {
		// Remove null values
		this.operationHours = this.operationHours?.filter(operationHour => operationHour.day)
		this.transformOperationHours()
	}

	transformOperationHours() {
		const days = ['1', '2', '3', '4', '5', '6', '7']

		for (const day of days) {
			const filtered = this.operationHours?.filter(op => op.day === day)
			const times = filtered?.map(({ start, end }) => `${start} - ${end}`).join(' | ')
			if (times?.length) {
				this.operationHoursByTime.push({ day, times })
			}
		}
	}

	getWeekday(weekdayNumber: string): string {
		return this.dict[weekdayNumber as keyof typeof this.dict]
	}

	close() {
		this.modalService.close(this.id)
	}
}
