import { Inject, Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { mergeMap, map, catchError, filter, switchMap, tap } from 'rxjs/operators'
import { of } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from '../app.state'
import { getCuisines } from './cuisines.reducers'

import { CuisinesActions } from './cuisines.action'
import { HttpVenuesGateway } from 'src/gateway/venue/http-venues.gateway'
import VenuesGateway from 'src/gateway/venue/venues.gateway'

@Injectable()
export class CuisinesEffects {
	constructor(
		private actions$: Actions,
		@Inject(HttpVenuesGateway) private venuesGateway: VenuesGateway,
		private store: Store<AppState>
	) {}

	getCuisines$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(CuisinesActions.getCuisines),
			switchMap(() => this.store.select(getCuisines)),
			// Only load cuisines if the array has at least one cuisine
			filter(cuisines => !Boolean(cuisines?.length)),
			map(() => CuisinesActions.loadCuisines())
		)
	})

	loadCuisines$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(CuisinesActions.loadCuisines),
			mergeMap(() =>
				this.venuesGateway.getCuisines().pipe(
					map(cuisines => CuisinesActions.loadCuisinesSuccess({ cuisines })),
					catchError(error => of(CuisinesActions.loadCuisinesFailure({ error })))
				)
			)
		)
	})
}
