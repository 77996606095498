import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { interval, map, startWith, tap } from 'rxjs'
import { OrderHistory } from 'src/entity/order-history'
import { AppState } from 'src/state/app.state'
import { OrderHistoryActions } from 'src/state/order-history/order-history.actions'

@Component({
	selector: 'app-order-card',
	templateUrl: './order-card.component.html',
	styleUrls: ['order-card.component.scss'],
})
export class OrderCardComponent {
	@Input() order!: OrderHistory
	@Output() cancelClicked = new EventEmitter<string>()
	nowEverySecond$ = interval(1000).pipe(
		startWith(0),
		map(() => Date.now())
	)
	canCancel$ = this.nowEverySecond$.pipe(
		map(NOW => NOW <= this.order.reservationDateTime),
		map(value => (this.order.wasPaid ? false : value))
	)
	constructor(private router: Router, private store: Store<AppState>, private route: ActivatedRoute) {}

	cancelOrder() {
		this.cancelClicked.emit(this.order.id)
	}

	goOrderDetails() {
		if (!this.order) return
		this.store.dispatch(OrderHistoryActions.setSelectedOrder({ order: this.order }))
		this.router.navigate(['/reservas-e-pagamento/reserva', this.order.id], { relativeTo: this.route })
	}

	goPayments() {
		if (!this.order) return
		this.store.dispatch(OrderHistoryActions.setSelectedOrder({ order: this.order }))
		this.router.navigate(['/reservas-e-pagamento/pagamento'])
	}
}
