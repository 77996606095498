export class CheckinDate {
	readonly day: string
	readonly time: string
	readonly dayFormatted: string
	constructor() {
		const today = new Date()
		const tzoffset = today.getTimezoneOffset() * 60000 //offset in milliseconds
		const [day, time] = new Date(Date.now() - tzoffset).toISOString().slice(0, -8).split('T')
		this.day = day
		this.time = time
		this.dayFormatted = `${today.toLocaleString('pt-BR', {
			day: 'numeric',
			month: 'long',
			hour: 'numeric',
			minute: 'numeric',
			hour12: false,
		})} hrs`
	}
}
