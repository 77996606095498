import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Observable, Subject, combineLatest, debounceTime, map, takeUntil } from 'rxjs'
import { PaymentHelper } from 'src/shared/helpers/payment.helper'
import { AppState } from 'src/state/app.state'
import { getSelectOrder } from 'src/state/order-history/order-history.reducers'
import { ModalService } from '../modal/modal.service'
import { PaymentActions } from 'src/state/payment/payment.actions'

const defaultTip = 10

@Component({
	selector: 'app-payment-form',
	templateUrl: './payment-form.component.html',
	styleUrls: ['payment-form.component.scss'],
})
export class PaymentFormComponent implements OnInit, OnDestroy {
	@Output() paymentClicked = new EventEmitter<void>()
	modalTipId = 'modal-tip-id'

	destroy$ = new Subject<void>()
	selectedOrder$ = this.store.select(getSelectOrder)

	showTip = false
	showTipTwo = false
	valor = ''
	form!: FormGroup
	billTip$!: Observable<number>
	aditionalBillTip$!: Observable<number>
	suggestedTip = defaultTip
	points$!: Observable<number>
	bill$!: Observable<number>
	totalValue$!: Observable<number>
	removeGratuityValue = false

	constructor(private fb: FormBuilder, private store: Store<AppState>, public modalService: ModalService) {}

	ngOnInit(): void {
		this.form = this.fb.group({
			totalValue: [null, Validators.required],
			tip: [null, Validators.required],
		})
		this.bill$ = this.form.valueChanges.pipe(
			map(form => PaymentHelper.round2decimals(form.totalValue / (1 + this.suggestedTip / 100)))
		)
		this.billTip$ = combineLatest([this.bill$, this.form.valueChanges]).pipe(
			map(([bill, form]) => (bill * form.tip) / 100)
		)

		this.aditionalBillTip$ = combineLatest([this.bill$, this.form.valueChanges]).pipe(
			map(([bill, form]) => (bill * (form.tip - this.suggestedTip)) / 100)
		)

		this.points$ = combineLatest([this.selectedOrder$, this.bill$]).pipe(
			map(([order, bill]) =>
				order?.benefits.points ? Math.floor(bill) * +order.benefits.points : Math.floor(bill)
			)
		)
		this.totalValue$ = combineLatest([this.bill$, this.billTip$]).pipe(
			map(([bill, billTip]) => bill + billTip)
		)

		this.updatePaymentOrder()
		this.updateSuggestTipForm()
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	goToConfirmPayments() {
		this.paymentClicked.emit()
	}

	changeTip(tip: number) {
		this.form.patchValue({ tip: tip })
	}

	private updateSuggestTipForm() {
		this.selectedOrder$.pipe(takeUntil(this.destroy$)).subscribe(order => {
			this.removeGratuityValue = order?.removeGratuityValue ?? false
			this.suggestedTip = this.removeGratuityValue ? 0 : order?.gratuityPercent ?? defaultTip
			this.form.patchValue({ tip: this.removeGratuityValue ? 0 : this.suggestedTip })
		})
	}

	private updatePaymentOrder() {
		combineLatest([this.billTip$, this.points$, this.bill$, this.totalValue$])
			.pipe(debounceTime(10), takeUntil(this.destroy$))
			.subscribe(([billTip, points, bill, totalValue]) => {
				const paymentOrder = {
					totalValue: totalValue,
					bill: bill,
					gratuityValue: billTip,
					points: points,
				}
				this.store.dispatch(PaymentActions.setPaymentOrder({ paymentOrder }))
			})
	}
}
