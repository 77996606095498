<div class="templateTab">
	<button
		class="templateTab__button templateTab__reserve"
		routerLink="/reservas-e-pagamento"
		routerLinkActive="selectedTab"
	>
		<i class="fa fa-forks" routerLinkActive="selectedIcon" style="margin-right: 12px"></i>Reservas e
		pagamento
	</button>
	<button
		class="templateTab__button templateTab__wallet"
		routerLink="/carteira"
		routerLinkActive="selectedTab"
	>
		<i class="fa fa-wallet" routerLinkActive="selectedIcon" style="margin-right: 12px"></i>Carteira
	</button>
	<div class="templateTab__body px-16">
		<ng-content></ng-content>
	</div>
</div>
