import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { Store } from '@ngrx/store'
import { map, Observable } from 'rxjs'
import { AppState } from '../app.state'
import { isLoggedIn } from './client.reducers'
import { ClientActions } from './client.actions'

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(private store: Store<AppState>, private router: Router) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.checkLoggedIn(state.url)
	}

	checkLoggedIn(url: string) {
		return this.store.select(isLoggedIn).pipe(
			map(value => {
				if (!value) {
					this.store.dispatch(ClientActions.setRedirectUrl({ url }))
					// Redirect to /login without changing browser history
					// this.router.navigate(['/gastronomia'], { skipLocationChange: true })
					this.router.navigate(['/gastronomia'])
					return false
				}
				return true
			})
		)
	}
}
