export class ReservationDate {
	readonly format: string | undefined
	readonly reservationDateUTC: Date | undefined
	constructor(reservationDay: string | null, reservationTime: string | null) {
		if (!reservationDay || !reservationTime) return
		this.reservationDateUTC = new Date(`${reservationDay}T${reservationTime}`)

		// Formatando a data para "20 de dezembro"
		const formatterDate = new Intl.DateTimeFormat('pt-BR', { day: 'numeric', month: 'long' })
		const dateFormatted = formatterDate.format(this.reservationDateUTC)

		// Combinando data e hora
		this.format = `${dateFormatted} às ${reservationTime} hrs`
	}
}
