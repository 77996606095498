<app-header></app-header>
<div class="wrapper px-16">
	<button class="goBack" (click)="location.back()"><i class="fa fa-arrow reverse"></i> Voltar</button>
	<app-payment-card (changeCard)="modalService.open(modal.changeCard)"></app-payment-card>
</div>
<app-footer></app-footer>

<app-modal [id]="modal.error" top="15%">
	<app-modal-basic [id]="modal.error" title="Algum erro aconteceu" *ngIf="error$ | async as error">
		{{error}}
	</app-modal-basic>
</app-modal>

<app-modal [id]="modal.changeCard" animation="vertical-i">
	<app-select-card
		(addCard)="goToAddCard()"
		[id]="modal.changeCard"
		style="position: fixed; left: 50%; bottom: 0; transform: translateX(-50%)"
	></app-select-card>
</app-modal>
