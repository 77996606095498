import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { ChooseBrandService } from 'src/shared/services/choose-brand.service'
import { AppState } from 'src/state/app.state'
import { PaymentActions } from 'src/state/payment/payment.actions'
import { getConfirmPaymentCVV, loadingPayment } from 'src/state/payment/payment.reducers'

@Component({
	selector: 'app-payment-card',
	templateUrl: './payment-card.component.html',
	styleUrls: ['payment-card.component.scss'],
})
export class PaymentCardComponent implements OnInit {
	loadingPayment$ = this.store.select(loadingPayment)
	confirmPaymentCVV$ = this.store.select(getConfirmPaymentCVV)
	form!: FormGroup
	@Output() changeCard = new EventEmitter<void>()

	constructor(
		private store: Store<AppState>,
		private fb: FormBuilder,
		public chooseBrand: ChooseBrandService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({ dynamicCVV: [null, Validators.required] })
	}

	createPayment() {
		if (this.form.invalid) return
		this.store.dispatch(PaymentActions.createPayment({ dynamicCVV: this.form.value.dynamicCVV }))
	}
}
