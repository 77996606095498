import { Inject, Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { mergeMap, map, switchMap, catchError } from 'rxjs/operators'
import { of } from 'rxjs'
import { AvailabilityActions } from './reservation.actions'
import { Store } from '@ngrx/store'
import { AppState } from '../app.state'
import { getConfirmReservation, getOrderId } from './reservation.reducers'
import OrderGateway, { ConfirmReservationDto } from 'src/gateway/order/order.gateway'
import { HttpOrderGateway } from 'src/gateway/order/http-order.gateway'
import { getClient } from '../client/client.reducers'

@Injectable()
export class ReservationEffects {
	constructor(
		private actions$: Actions,
		@Inject(HttpOrderGateway) private orderGateway: OrderGateway,
		private store: Store<AppState>
	) {}

	loadAvailabilities$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AvailabilityActions.loadAvailabilities),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) => {
				return this.orderGateway
					.checkAvailability({
						venueId: action.venueId,
						clientId: client?.id as string,
						categoryId: client?.selectedCategory?.categoryId as string,
					})
					.pipe(
						switchMap(response => {
							return [
								AvailabilityActions.loadAvailabilitiesSuccess({
									availabilities: response.availabilities,
								}),
								AvailabilityActions.setOrderId({ orderId: response.order._id }),
							]
						}),
						catchError(error =>
							of(
								AvailabilityActions.loadAvailabilitiesFailure({
									error: error?.error?.message,
								})
							)
						)
					)
			})
		)
	})

	confirmReservation$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(AvailabilityActions.confirmReservation),
			concatLatestFrom(() => [
				this.store.select(getOrderId),
				this.store.select(getConfirmReservation),
				this.store.select(getClient),
			]),
			mergeMap(([_, orderId, confirmReservationState, client]) =>
				this.orderGateway
					.confirmOrder(orderId!, {
						partySize: confirmReservationState.partySize,
						reservationDay: confirmReservationState.reservationDay,
						reservationTime: confirmReservationState.reservationTime,
						section: confirmReservationState.sectionId,
						sectionLabel: confirmReservationState.sectionLabel,
						clientId: client?.id,
					} as ConfirmReservationDto)
					.pipe(
						map(() => AvailabilityActions.confirmReservationSuccess()),
						catchError(error =>
							of(
								AvailabilityActions.confirmReservationFailure({
									error: error?.error?.message,
								})
							)
						)
					)
			)
		)
	})
}
