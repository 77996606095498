import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http'
import { Observable, tap } from 'rxjs'
import { Router } from '@angular/router'
import { LOCAL_CLIENT_ID, LOCAL_TOKEN, LocalStorageService } from './local-storage.service'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(private router: Router) {}
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const token = localStorage.getItem(LOCAL_TOKEN)
		if (token) {
			request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } })
		}
		return next.handle(request).pipe(
			tap({
				error: (err: any) => {
					if (err instanceof HttpErrorResponse) {
						if (err.status === 401 || err.status === 403) {
							// this.router.navigate(['/gastronomia'])
							localStorage.removeItem(LOCAL_TOKEN)
							localStorage.removeItem(LOCAL_CLIENT_ID)
							localStorage.removeItem(LOCAL_CLIENT_ID + '_TIME')
						}
					}
				},
			})
		)
	}
}
