import { Component, Input, OnInit } from '@angular/core'
import { VenueFilter } from 'src/entity/venues.entity'

@Component({
	selector: 'app-venue-card',
	templateUrl: './venue-card.component.html',
	styleUrls: ['venue-card.component.scss'],
})
export class VenueCardComponent {
	img = './../assets/images/img-restaurante.jpg'

	@Input() venue!: VenueFilter
	constructor() {}
}
