import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/shared/helpers/prefix-title'

@Component({
	template: `
		<app-header></app-header>
		<div style="padding: 11px 0" class="desktop">
			<app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>
		</div>
		<div style="background: var(--color-white); max-width: 500px; margin: 0 auto;">
			<app-register-card></app-register-card>
		</div>
		<app-footer></app-footer>
	`,
})
export class RegisterCardPage implements OnInit {
	breadcrumbs = [
		{ url: '/gastronomia', title: 'Gastronomia' },
		{ url: `/carteira/`, title: 'Carteira' },
		{ url: `/carteira/adicionar-cartao`, title: 'Cadastrar Cartão' },
	]
	constructor(private title: Title) {}

	ngOnInit(): void {
		this.title.setTitle(`${PREFIX_TITLE} - Adicionar Cartão`)
	}
}
