<app-header></app-header>

<div style="padding-top: 11px; max-width: 1184px; margin: 0 auto" class="desktop">
	<app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>
</div>

<app-template-tab>
	<div class="wallet">
		<div class="cardsMobile" *ngIf="isMobile">
			<div class="wallet__header">
				<p class="wallet__title">Meus cartões de crédito</p>
			</div>

			<app-spinner *ngIf="loadingClient$ | async"></app-spinner>

			<div class="wrapperCard" *ngIf="client$ | async as client">
				<div class="noCard" *ngIf="client.cards.length === 0">
					<i class="fa fa-info"></i> Nenhum cartão adicionado no momento
				</div>
				<section class="wrapperCard__card" *ngFor="let card of client.cards">
					<ul class="sublist">
						<li class="sublist__subitem" (click)="openModal(card.lastFourDigits, modal.mainCard)">
							<i class="sublist__icon fa fa-star-outline"></i>
							<p class="sublist__text">Principal</p>
						</li>
						<li class="sublist__subitem" (click)="openModal(card.lastFourDigits, modal.delete)">
							<i class="sublist__icon fa fa-delete"></i>
							<p class="sublist__text">Excluir</p>
						</li>
					</ul>
					<div
						class="card"
						[ngClass]="{'card--active': getIsOpenCard(card.lastFourDigits)}"
						(click)="toggleOpenCard(card.lastFourDigits)"
					>
						<div class="card__brand" *ngIf="card.brand">
							<ng-container *ngIf="chooseBrand.get(card.brand) as img">
								<img [src]="img.src" [alt]="img.alt" />
							</ng-container>
						</div>
						<i class="card__icon fa fa-star" *ngIf="card.mainCard"></i>
						<div class="card__menu">
							<button>
								<i class="fa fa-options"></i>
							</button>
						</div>
						<div class="card__number">
							<p>.... {{card.lastFourDigits}}</p>
						</div>
					</div>
				</section>
			</div>
			<button class="newCard" routerLink="adicionar-cartao">
				<i class="fa fa-plus"></i> Adicionar novo cartão
			</button>

			<div style="margin-top: 48px; margin-bottom: 21px">
				<button class="btn btn--inverted" routerLink="/gastronomia">Ir para Home</button>
			</div>
		</div>

		<div class="cardsDesk" *ngIf="!isMobile">
			<div class="cardsDesk__card">
				<div class="wallet__header">
					<p class="wallet__title">Meus cartões de crédito</p>
				</div>
				<div class="wrapperCard" *ngIf="client$ | async as client">
					<div class="noCard" *ngIf="client.cards.length === 0">
						<i class="fa fa-info"></i> Nenhum cartão adicionado no momento
					</div>
					<section class="wrapperCard__card" *ngFor="let card of client.cards">
						<ul class="sublist">
							<li
								class="sublist__subitem"
								(click)="openModal(card.lastFourDigits, modal.mainCard)"
							>
								<i class="sublist__icon fa fa-star-outline"></i>
								<p class="sublist__text">Principal</p>
							</li>
							<li
								class="sublist__subitem"
								(click)="openModal(card.lastFourDigits, modal.delete)"
							>
								<i class="sublist__icon fa fa-delete"></i>
								<p class="sublist__text">Excluir</p>
							</li>
						</ul>
						<div
							class="card"
							[ngClass]="{'card--active': getIsOpenCard(card.lastFourDigits)}"
							(click)="toggleOpenCard(card.lastFourDigits)"
						>
							<div class="card__brand" *ngIf="card.brand">
								<ng-container *ngIf="chooseBrand.get(card.brand) as img">
									<img [src]="img.src" [alt]="img.alt" />
								</ng-container>
							</div>

							<i class="card__icon fa fa-star" *ngIf="card.mainCard"></i>
							<div class="card__menu">
								<button>
									<i class="fa fa-options"></i>
								</button>
							</div>
							<div class="card__number">
								<p>.... {{card.lastFourDigits}}</p>
							</div>
						</div>
					</section>
				</div>
			</div>
			<div class="cardsDesk__newCard" [ngClass]="{'card--active': open}">
				<app-register-card></app-register-card>
			</div>
		</div>
	</div>
</app-template-tab>

<app-modal [id]="modal.mainCard" top="15%">
	<app-modal-basic
		[id]="modal.mainCard"
		title="Gostaria de tornar o cartão de últimos dígitos {{selectedLastFourDigits}} como principal?"
	>
		<ng-container *ngIf="{loading: loading$ | async } as obj">
			<button
				class="btn btn--primary btn--loading-wrapper"
				[ngClass]="{'btn--disabled': obj.loading}"
				[disabled]="obj.loading"
				(click)="updateMainCard()"
			>
				Sim
				<app-spinner class="btn--loading" *ngIf="obj.loading"></app-spinner>
			</button>
			<button class="btn btn--ghost" (click)="modalService.close(modal.mainCard)">Não</button>
		</ng-container>
	</app-modal-basic>
</app-modal>

<app-modal [id]="modal.delete" top="15%">
	<app-modal-basic
		[id]="modal.delete"
		title="Tem certeza que deseja excluir o cartão ({{selectedLastFourDigits}})?"
	>
		<ng-container *ngIf="{loading: loading$ | async} as obj">
			<button
				class="btn btn--primary btn--loading-wrapper"
				[ngClass]="{'btn--disabled': obj.loading}"
				[disabled]="obj.loading"
				(click)="removeCard()"
			>
				Sim
				<app-spinner class="btn--loading" *ngIf="obj.loading"></app-spinner>
			</button>
			<button class="btn btn--ghost" (click)="modalService.close(modal.delete)">Não</button>
		</ng-container>
	</app-modal-basic>
</app-modal>

<app-modal [id]="modal.error" top="15%">
	<app-modal-basic [id]="modal.error" title="Algum erro aconteceu" *ngIf="error$ | async as error">
		{{error}}
	</app-modal-basic>
</app-modal>

<app-footer></app-footer>
