import { Component, ViewChild, TemplateRef, Input } from '@angular/core'

@Component({
	selector: 'app-menu-desk',
	templateUrl: './menu-desk.component.html',
	styleUrls: ['menu-desk.component.scss'],
})
export class MenuDeskComponent {
	@ViewChild('templateViagens')
	templateViagens!: TemplateRef<any>
	@ViewChild('templateShopping')
	templateShopping!: TemplateRef<any>
	@ViewChild('templateJuntePontos')
	templateJuntePontos!: TemplateRef<any>
	@ViewChild('templateAcelere')
	templateAcelere!: TemplateRef<any>
	@ViewChild('templateMais')
	templateMais!: TemplateRef<any>

	@Input() choice: string | undefined

	get templateChoice(): TemplateRef<any> {
		if (this.choice === 'Viagens') {
			return this.templateViagens
		} else if (this.choice === 'Shopping') {
			return this.templateShopping
		} else if (this.choice === 'Junte pontos') {
			return this.templateJuntePontos
		} else if (this.choice === 'Acelere') {
			return this.templateAcelere
		} else if (this.choice === 'Mais...') {
			return this.templateMais
		} else {
			return this.templateViagens
		}
	}
}
