import { Injectable } from '@angular/core'

export const LOCAL_TOKEN = 'token'
export const LOCAL_CLIENT_ID = 'clientId'

@Injectable({
	providedIn: 'root',
})
export class LocalStorageService {
	constructor() {}

	// 3_600_000 equal 1h in miliseconds
	setTime(key: string, data: any, expirationTime = 3_600_000) {
		// Get current time in miliseconds
		let currentTime = new Date().getTime()

		// Set the item in localStorage
		localStorage.setItem(key, data)

		// Set the expiration time in localStorage
		localStorage.setItem(`${key}_TIME`, String(currentTime + expirationTime))
	}

	getTime(key: string) {
		const storedTime = localStorage.getItem(`${key}_TIME`)
		const currentTime = new Date().getTime()

		if (Number(storedTime) < currentTime) {
			// Item has expired
			localStorage.removeItem(key)
			localStorage.removeItem(`${key}_TIME`)
			return null
		}
		// Item has not expired, retrieve the data
		return localStorage.getItem(key)
	}

	deleteTime(key: string) {
		localStorage.removeItem(key)
		localStorage.removeItem(`${key}_TIME`)
	}
}
