import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Subject, debounceTime, merge, take, takeUntil } from 'rxjs'
import { ModalService } from 'src/app/components/modal/modal.service'
import { PREFIX_TITLE } from 'src/shared/helpers/prefix-title'
import { AppState } from 'src/state/app.state'
import { ClientActions } from 'src/state/client/client.actions'
import { getClient, getStatusClient, isLoggedIn } from 'src/state/client/client.reducers'
import { OrderHistoryActions } from 'src/state/order-history/order-history.actions'
import {
	getCancelOrderError,
	getOrderError,
	getOrders,
	loadingCancelOrder,
	loadingOrderHistory,
} from 'src/state/order-history/order-history.reducers'

@Component({
	templateUrl: './order-history.page.html',
	styleUrls: ['order-history.page.scss'],
})
export class OrderHistoryPage implements OnInit, OnDestroy, AfterViewInit {
	orders$ = this.store.select(getOrders)
	error$ = merge(this.store.select(getOrderError), this.store.select(getCancelOrderError))
	loadingHistory$ = this.store.select(loadingOrderHistory)
	loadingCancel$ = this.store.select(loadingCancelOrder)
	modal = {
		error: 'modal-error-order-history-id',
		cancelOrder: 'modal-cancel-order-id',
		login: 'modal-order-history-login-id',
	}
	orderId: string | null = null
	destroy$ = new Subject<void>()

	breadcrumbs = [
		{ url: '/gastronomia', title: 'Gastronomia' },
		{ url: `/reservas-e-pagamento/`, title: 'Reservas e Pagamento' },
	]

	constructor(
		private store: Store<AppState>,
		private title: Title,
		public modalService: ModalService,
		private actions$: Actions
	) {}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	ngOnInit(): void {
		this.title.setTitle(`${PREFIX_TITLE} - Reservas e Pagamento`)

		this.actions$
			.pipe(
				ofType(OrderHistoryActions.loadOrderHistoryFailure, OrderHistoryActions.cancelOrderFailure),
				takeUntil(this.destroy$)
			)
			.subscribe(() => this.modalService.open(this.modal.error))
	}

	ngAfterViewInit(): void {
		this.store
			.select(getClient)
			.pipe(takeUntil(this.destroy$), debounceTime(0))
			.subscribe(client => {
				if (!client) {
					this.modalService.open(this.modal.login)
				} else {
					this.modalService.close(this.modal.login)
					this.store.dispatch(OrderHistoryActions.loadOrderHistory())
				}
			})

		this.store
			.select(getStatusClient)
			.pipe(takeUntil(this.destroy$), debounceTime(0))
			.subscribe(status => {
				if (status === 'pending' || status === 'failure' || status === 'success') {
					this.modalService.close(this.modal.login)
				}
			})
	}

	openModalCancelOrder(id: string) {
		this.orderId = id
		this.modalService.open(this.modal.cancelOrder)
	}

	cancelOrder() {
		if (!this.orderId) return
		this.actions$
			.pipe(
				ofType(OrderHistoryActions.cancelOrderSuccess, OrderHistoryActions.cancelOrderFailure),
				take(1)
			)
			.subscribe(() => {
				this.modalService.close(this.modal.cancelOrder)
			})
		this.store.dispatch(OrderHistoryActions.cancelOrder({ orderId: this.orderId }))
	}
}
