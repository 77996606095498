import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
	selector: 'app-modal-header',
	template: `
		<div class="header">
			<h1 class="title"><ng-content></ng-content></h1>
			<button (click)="close.emit()" *ngIf="hasClose">
				<i class="fa fa-close"></i>
			</button>
		</div>
	`,
	styles: [
		`
			.header {
				display: flex;
				justify-content: space-between;

				i {
					color: var(--color-blue);
					font-size: 14px;
				}

				.title {
					font-size: 20px;
					font-weight: 600;
					line-height: 28px;
					color: var(--color-blue);
					padding-right: 5px;
				}

				.text {
					font-size: 14px;
					line-height: 18px;
					color: var(--color-gray-dark);
				}
			}
		`,
	],
})
export class ModalHeaderComponent {
	@Output() close = new EventEmitter()
	@Input() hasClose = true
	constructor() {}
}
