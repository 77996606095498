<section class="wrapper">
	<div class="form">
		<app-spinner *ngIf="loadingAvailabilities$ | async"></app-spinner>
		<div class="error" *ngIf="errorAvailability$ | async as error">{{ error }}</div>
		<!-- DATA -->
		<ch-select-box
			*ngIf="availabilitiesDatesItems$ | async as availabilitiesDates"
			[items]="availabilitiesDates"
			(itemWasSelected)="dateSelected($event)"
		></ch-select-box>

		<!-- AMBIENTE -->
		<ch-select-box
			*ngIf="sectionsItems$ | async as sections"
			[items]="sections"
			(itemWasSelected)="sectionSelected($event)"
		></ch-select-box>

		<!-- MESA PARA XX PESSOAS -->
		<ch-select-box
			*ngIf="schedulesItems$ | async as schedules"
			[items]="schedules"
			(itemWasSelected)="scheduleSelected($event)"
		></ch-select-box>

		<!-- HORÁRIOS -->
		<ng-container *ngIf="vmTimes$ | async as vmTimes">
			<div tabindex="0" *ngIf="vmTimes.reservationTimes" style="overflow: auto; height: 200px">
				<p class="time__text">
					Exibindo horários disponíveis entre {{ vmTimes.times.firstTime }} e
					{{ vmTimes.times.lastTime }}
				</p>
				<div class="time__options" tabindex="1">
					<label
						tabindex="1"
						class="time__label"
						*ngFor="let rt of vmTimes.reservationTimes"
						(click)="timeSelected(rt.reservationTime)"
						[ngClass]="{
							'time__label--active': rt.reservationTime === vmTimes.selectedReservationTime
						}"
					>
						{{ rt.reservationTime }}
					</label>
				</div>
			</div>
		</ng-container>

		<hr class="hr" />

		<ng-container
			*ngIf="{
				formIsInvalid: formIsInvalid$ | async,
				loading: loadingConfirmReservation$ | async
			} as obj"
		>
			<button
				class="confirmButton"
				[ngClass]="{ 'confirmButton--disabled': obj.formIsInvalid || obj.loading }"
				[disabled]="obj.formIsInvalid || obj.loading"
				(click)="confirmReservation()"
			>
				Confirmar reserva
				<app-spinner class="confirmButton__loading" *ngIf="obj.loading"></app-spinner>
			</button>
		</ng-container>
	</div>
</section>
