<!-- <div class="payCard px-16">
	<button class="payCard__buttonBack"><i class="fa fa-arrow reverse"></i> Voltar</button>
</div> -->
<ng-container *ngIf="confirmPaymentCVV$ | async as confirmPaymentCVV">
	<div class="payCard__info">
		<p class="payCard__infoLabel">Valor a pagar</p>
		<p class="payCard__infoText">{{ confirmPaymentCVV.totalValue | currency : 'BRL' }}</p>
	</div>

	<div class="payCard__info">
		<p class="payCard__infoLabel">Restaurante</p>
		<p class="payCard__infoText">{{ confirmPaymentCVV.venueName }}</p>
	</div>

	<div class="payCard__info">
		<p class="payCard__infoLabel">Data</p>
		<p class="payCard__infoText">{{ confirmPaymentCVV.humanDay }}</p>
	</div>

	<app-divisor></app-divisor>

	<form [formGroup]="form">
		<div class="payCard__card">
			<ng-container *ngIf="chooseBrand.get(confirmPaymentCVV.brand) as img">
				<img [src]="img.src" [alt]="img.alt" class="payCard__img" />
			</ng-container>
			<div class="payCard__cardValue">
				<p class="payCard__cardValueLabel">Valor a pagar</p>
				<p class="payCard__cardValueMoney">{{ confirmPaymentCVV.totalValue | currency : 'BRL' }}</p>
			</div>
			<div class="payCard__cardInfo">
				<p class="payCard__cardInfoCard">.... {{ confirmPaymentCVV.lastFourDigits }}</p>
				<!-- <p class="payCard__cardInfoName">Nome</p> -->
				<p class="payCard__cardInfoCod">Digite o CVV</p>
				<input
					class="payCard__cardInfoInput"
					placeholder="000"
					mask="000||0000"
					formControlName="dynamicCVV"
					inputmode="numeric"
				/>
				<button class="payCard__cardInfoBtn" (click)="changeCard.emit()">Trocar cartão</button>
			</div>
		</div>

		<div class="payCard__boxConfirm">
			<ng-container *ngIf="{ loading: loadingPayment$ | async } as obj">
				<button
					class="btn btn--primary"
					style="position: relative"
					(click)="createPayment()"
					[disabled]="form.invalid || obj.loading"
					[class.btn--disabled]="form.invalid || obj.loading"
					type="submit"
				>
					Confirmar pagamento
					<app-spinner
						class="loading"
						style="position: absolute; top: 5px; right: 10px"
						*ngIf="obj.loading"
					></app-spinner>
				</button>
			</ng-container>
		</div>
	</form>
</ng-container>
