import { Inject, Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { OrderHistoryActions } from './order-history.actions'
import { catchError, filter, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from '../app.state'
import { getClient } from '../client/client.reducers'
import OrderGateway from 'src/gateway/order/order.gateway'
import { HttpOrderGateway } from 'src/gateway/order/http-order.gateway'
import { AvailabilityActions } from '../reservation/reservation.actions'
import { getHasPaymentReady } from './order-history.reducers'

@Injectable()
export class OrderHistoryEffects {
	constructor(
		private actions$: Actions,
		private store: Store<AppState>,
		@Inject(HttpOrderGateway)
		private orderGateway: OrderGateway
	) {}

	loadOrders$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				OrderHistoryActions.loadOrderHistory,
				AvailabilityActions.confirmReservationSuccess,
				OrderHistoryActions.cancelOrderSuccess,
				OrderHistoryActions.getOrdersPaymentReadySuccess
			),
			concatLatestFrom(() => this.store.select(getClient)),
			switchMap(([_, client]) =>
				this.orderGateway.getHistory({ clientId: client!.id }).pipe(
					map(orders => OrderHistoryActions.loadOrderHistorySuccess({ orders })),
					catchError(error =>
						of(OrderHistoryActions.loadOrderHistoryFailure({ error: error?.error.message }))
					)
				)
			)
		)
	})

	cancelOrder$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(OrderHistoryActions.cancelOrder),
			mergeMap(action =>
				this.orderGateway.cancelOrder(action.orderId).pipe(
					map(() => OrderHistoryActions.cancelOrderSuccess({ orderId: action.orderId })),
					catchError(error =>
						of(OrderHistoryActions.cancelOrderFailure({ error: error?.error?.message }))
					)
				)
			)
		)
	})

	getOrdersReady$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(OrderHistoryActions.getOrdersPaymentReady),
			concatLatestFrom(() => [this.store.select(getClient), this.store.select(getHasPaymentReady)]),
			// Filtrar para garantir que o cliente existe e hasPayment diferente de true antes de continuar
			filter(([_, client, hasPaymentReady]) => !!client && !hasPaymentReady),
			switchMap(([_, client]) =>
				this.orderGateway.getOrdersPaymentReady(client!.id).pipe(
					map(res =>
						OrderHistoryActions.getOrdersPaymentReadySuccess({
							hasPaymentReady: res.hasPaymentReady,
						})
					),
					catchError(error =>
						of(OrderHistoryActions.getOrdersPaymentReadyFailure({ error: error?.error?.message }))
					)
				)
			)
		)
	})
}
