import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Observable, Subject, map, takeUntil } from 'rxjs'
import { ModalService } from '../modal.service'

@Component({
	selector: 'app-modal-tip',
	templateUrl: './modal-tip.component.html',
	styleUrls: ['./modal-tip.component.scss'],
})
export class ModalTipComponent implements OnInit, OnDestroy {
	@Input() id!: string
	@Input() suggestedTip!: number
	@Input() billTip!: number | null
	@Output() changedTip = new EventEmitter<number>()
	private destroy$ = new Subject<void>()
	formTip!: FormGroup
	hasTipChanged$!: Observable<boolean>

	constructor(private fb: FormBuilder, public modalService: ModalService) {}
	ngOnInit(): void {
		this.formTip = this.fb.group({
			tip: [this.suggestedTip],
		})
		this.formTip
			.get('tip')
			?.valueChanges.pipe(takeUntil(this.destroy$))
			.subscribe(tip => {
				this.changedTip.emit(tip)
			})
		this.hasTipChanged$ = this.formTip.valueChanges.pipe(map(form => form.tip !== this.suggestedTip))
	}
	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}
}
