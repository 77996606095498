import { createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store'
import { Status } from '../status.type'
import { PaymentActions } from './payment.actions'
import { getClientFeatureState } from '../client/client.reducers'
import { getOrderFeatureState } from '../order-history/order-history.reducers'

export interface PaymentState {
	paymentOrder: any | null
	statusPayment: Status
	errorPayment: string | null
}

const initialState: PaymentState = {
	paymentOrder: null,
	statusPayment: 'idle',
	errorPayment: null,
}

const getPaymentFeatureState = createFeatureSelector<PaymentState>('payment')

export const loadingPayment = createSelector(
	getPaymentFeatureState,
	state => state.statusPayment === 'pending'
)
export const getPaymentOrder = createSelector(getPaymentFeatureState, state => state.paymentOrder)
export const getErrorPayment = createSelector(getPaymentFeatureState, state => state.errorPayment)

export const getConfirmPaymentCVV = createSelector(
	getClientFeatureState,
	getOrderFeatureState,
	getPaymentFeatureState,
	(clientState, orderState, paymentState) => ({
		totalValue: paymentState.paymentOrder?.totalValue,
		venueName: orderState.selectedOrder?.venue?.name,
		humanDay: orderState.selectedOrder?.date?.humanDay,
		lastFourDigits: clientState.client?.selectedCategory?.lastFourDigits,
		brand: clientState.client?.selectedCategory?.brand,
	})
)

export const getConfirmPaymentSuccess = createSelector(
	getClientFeatureState,
	getOrderFeatureState,
	getPaymentFeatureState,
	(clientState, orderState, paymentState) => ({
		name: clientState.client?.fullName,
		venueName: orderState.selectedOrder?.venue?.name,
		brand: clientState.client?.selectedCategory?.brand,
		humanDate: paymentState.paymentOrder?.humanDate,
		lastFourDigits: clientState.client?.selectedCategory?.lastFourDigits,
		totalValue: paymentState.paymentOrder?.totalValue,
		points: paymentState.paymentOrder?.points,
	})
)

export const paymentReducer = createReducer<PaymentState>(
	initialState,
	on(PaymentActions.setPaymentOrder, (state, action): PaymentState => {
		return {
			...state,
			paymentOrder: action.paymentOrder,
		}
	}),
	on(PaymentActions.createPayment, (state, action): PaymentState => {
		return {
			...state,
			statusPayment: 'pending',
		}
	}),
	on(PaymentActions.createPaymentSuccess, (state, action): PaymentState => {
		const formatter = new Intl.DateTimeFormat('pt-BR', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
		})
		const humanDate = formatter.format(new Date(action.res.paidAt)).replace(',', ' às')
		return {
			...state,
			paymentOrder: {
				...state.paymentOrder,
				code: action.res.code,
				humanDate: humanDate,
				points: action.res.points,
				totalValue: action.res.orderTotalValue / 100,
			},
			statusPayment: 'success',
			errorPayment: '',
		}
	}),
	on(PaymentActions.createPaymentFailure, (state, action): PaymentState => {
		return {
			...state,
			errorPayment: action.error,
			statusPayment: 'failure',
		}
	})
)
