import { Observable, delay, map, of, take, throwError, timer } from 'rxjs'
import ClienteGateway, { CreateCardDto, EncryptCardDataDto, UpdateClientDto } from './client.gateway'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { HttpClient } from '@angular/common/http'
import { Client, ClientProps } from 'src/entity/client.entity'

@Injectable()
export class HttpClientGateway implements ClienteGateway {
	baseURL = `${environment.channelsAPI.baseUrl}/api`
	redirectUrl: string | null = null
	isLoggedIn = false
	constructor(private http: HttpClient) {}

	loginClientHomologation(clientId: string): Observable<any> {
		return this.http
			.get<ClientProps>(`${this.baseURL}/v1/auth/login/homologation/${clientId}`)
			.pipe(map(client => Client.create(client)))
	}

	updateClientBackend(clientId: string, updateClientDto: UpdateClientDto): Observable<unknown> {
		return this.http.patch(`${this.baseURL}/v1/clients/${clientId}`, updateClientDto)
	}

	loginClient(clientId: string): Observable<Client> {
		return this.http
			.get<ClientProps>(`${this.baseURL}/v1/clients/${clientId}`)
			.pipe(map(client => Client.create(client)))
	}

	loginClientSSO(token: string): Observable<any> {
		return this.http
			.post<ClientProps>(`${this.baseURL}/v1/auth/latam?token=${token}`, {})
			.pipe(map(client => Client.create(client)))
	}

	encrypt(body: EncryptCardDataDto): Observable<{ cardData: string }> {
		return this.http.post<{ cardData: string }>(`${this.baseURL}/v1/payments/encrypt`, body)
	}

	createCardToken(createCard: CreateCardDto) {
		return this.http.post(`${this.baseURL}/v2/payments/create-card-token`, createCard)
	}

	removeCard(clientId: string, lastFourDigits: string) {
		// // return throwError(() => ({ error: { message: 'hello' } }))
		// return of({}).pipe(delay(2000))
		return this.http.delete(`${this.baseURL}/v1/payments/remove-card/${clientId}/${lastFourDigits}`)
	}

	updateMainCard(clientId: string, lastFourDigits: string) {
		// // return throwError(() => ({ error: { message: 'hello' } }))
		// return of({}).pipe(delay(2000))
		return this.http.patch(`${this.baseURL}/v1/payments/main-card/${clientId}/${lastFourDigits}`, {})
	}
}
