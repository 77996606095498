<div class="card">
	<div (click)="goOrderDetails()" style="cursor: pointer">
		<div class="card__date">
			<p class="card__dateDay">{{ order.date.day }}</p>
			<p class="card__dateMonth">{{ order.date.month }}</p>
		</div>
		<p class="card__name">{{ order.venue.name }}</p>
		<p class="card__address" [title]="order.venue.address.value">{{ order.venue.address.value }}</p>
	</div>
	<div class="card__footer">
		<span class="card__footerIntent">{{ order.intent }} às {{ order.date.time }}</span>
		<button class="card__footerCancel" (click)="cancelOrder()" *ngIf="canCancel$ | async">
			Cancelar reserva
		</button>
	</div>
	<div
		class="card__payment card__payment--unidentified"
		[ngClass]="{
			'card__payment--made': order.wasPaid,
			'card__payment--unidentified': order.paymentBlocked || !order.hasPaymentConfig
		}"
	>
		<ng-container *ngIf="order.wasPaid">
			<div class="card__payment__text">
				<i class="card__payment__icon fa fa-check-outline"></i>
				<p>Pagamento realizado</p>
			</div>
			<div class="card__points" *ngIf="order.points">
				<p class="card__points__number">
					{{ order.points }}
				</p>
				<p class="card__points__text">pontos</p>
			</div>
		</ng-container>
		<ng-container *ngIf="order.paymentBlocked">
			<div class="card__payment__text">
				<i class="card__payment__icon fa fa-falha"></i>
				<p>Pagamento não identificado</p>
			</div>
		</ng-container>

		<ng-container *ngIf="!order.hasPaymentConfig">
			<div class="card__payment__text">
				<i class="card__payment__icon fa fa-falha"></i>
				<p>Impossibilidade de pagamento</p>
			</div>
		</ng-container>
	</div>

	<button
		class="btn btn--primary btn--small"
		style="margin-top: 15px; width: 100%"
		*ngIf="order.canPay"
		(click)="goPayments()"
	>
		Pagar conta
	</button>
</div>
