import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-ticket',
	templateUrl: './ticket.component.html',
	styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit {
	@Input() label: string = ''
	@Input() data: string | number | undefined | null = ''
	@Input() creditCard: { src: string; alt: string } | null = null
	@Input() days?: number
	@Input() tooltip: boolean = false

	templates = {
		default: true,
		creditCard: false,
		tooltip: false,
	}

	chooseCard!: { src: string; alt: string }

	constructor() {}

	ngOnInit(): void {
		if (this.creditCard) {
			this.resetTemplate()
			this.templates.creditCard = true
		}

		if (this.tooltip) {
			this.resetTemplate()
			this.templates.tooltip = true
		}
	}

	resetTemplate() {
		this.templates = {
			default: false,
			creditCard: false,
			tooltip: false,
		}
	}

	get daysText() {
		if (this.days === 1) {
			return `${this.days} dia`
		}
		return `${this.days} dias`
	}
}
