import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-success-template',
	templateUrl: './success-template.component.html',
	styleUrls: ['./success-template.component.scss'],
})
export class SuccessTemplateComponent implements OnInit {
	@Input() template: 'reservation' | 'payment' | 'checkin' = 'reservation'
	message!: string
	icon!: string

	constructor() {}

	ngOnInit(): void {
		if (this.template === 'reservation') {
			this.message = 'Reserva realizada </br>com sucesso!'
			this.icon = 'fa fa-reserve-confirmed'
		}

		if (this.template === 'checkin') {
			this.message = 'Check-in realizado </br>com sucesso!'
			this.icon = 'fa fa-reserve-confirmed'
		}

		if (this.template === 'payment') {
			this.message = 'Pagamento realizado </br>com sucesso!'
			this.icon = 'fa fa-cardversus'
		}
	}
}
