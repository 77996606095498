import { Component, Input, OnInit } from '@angular/core'
import { ModalService } from '../modal.service'

@Component({
	selector: 'app-modal-cvv',
	template: `
		<app-modal-basic title="O que é o código CVV?" [id]="id">
			<div class="wrapper">
				<img
					src="./../../../../assets/images/frame-card.svg"
					alt=""
					class="modalContent__img"
					width="50px"
					height="50px"
				/>
				<div style="height: 10px"></div>
				<p class="modalContent__text">
					São os 3 números que aparecem na parte de trás do seu cartão.
				</p>
			</div>
		</app-modal-basic>
	`,
	styles: [
		`
			.wrapper {
				display: grid;
				place-items: center;
			}
		`,
	],
})
export class ModalCvvComponent {
	@Input() id!: string
	constructor() {}
}
