import { Component, Input, OnInit } from '@angular/core'
import { MenuEffectService } from './menu-effect.service'

type Templates = 'viagens' | 'shopping' | 'junte_pontos' | 'acelere' | 'mais'
@Component({
	selector: 'app-menu-effect',
	templateUrl: './menu-effect.component.html',
	styleUrls: ['menu-effect.component.scss'],
})
export class MenuEffectComponent implements OnInit {
	id = crypto.randomUUID()
	@Input() template: Templates = 'viagens'
	templates: Record<Templates, boolean> = {
		viagens: false,
		shopping: false,
		junte_pontos: false,
		acelere: false,
		mais: false,
	}
	private chooseTitle: Record<Templates, string> = {
		viagens: 'Viagens',
		shopping: 'Shopping',
		junte_pontos: 'Junte pontos',
		acelere: 'Acelere',
		mais: 'Mais...',
	}
	title!: string
	showOption$ = this.menuEffectService.getOption(this.id)

	constructor(private menuEffectService: MenuEffectService) {}

	ngOnInit(): void {
		this.menuEffectService.createNewOption(this.id)
		this.templates[this.template] = true
		this.title = this.chooseTitle[this.template]
	}

	openOptions() {
		this.menuEffectService.enableOption(this.id)
	}
}
