<div class="success">
	<div class="success__header">
		<div class="success__headerInside">
			<p class="success__headerText">
				<span [innerHTML]="message"></span>
			</p>
			<i class="{{ icon }} success__headerIcon"></i>
		</div>
	</div>
	<div class="success__body px-16">
		<div class="success px-16">
			<ng-content></ng-content>
		</div>
	</div>
</div>
