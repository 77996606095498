import { createReducer, on, createFeatureSelector, createSelector } from '@ngrx/store'
import { ClientActions } from './client.actions'
import { Client } from 'src/entity/client.entity'
import { Status } from '../status.type'

export interface ClientState {
	client: Client | null
	loginStatus: Status
	registerCardStatus: Status
	mainCardStatus: Status
	removeCardStatus: Status
	errorLoginStatus: string | null
	errorRegisterCard: string | null
	errorMainCard: string | null
	errorRemoveCard: string | null
	statusUpdateClientBackend: Status
	errorUpdateClientBackend: string | null
	isLoggedIn: boolean
	redirectUrl: string | null
}

const initialState: ClientState = {
	client: null,
	isLoggedIn: false,
	loginStatus: 'idle',
	errorLoginStatus: null,
	redirectUrl: null,
	registerCardStatus: 'idle',
	errorRegisterCard: null,
	mainCardStatus: 'idle',
	removeCardStatus: 'idle',
	errorMainCard: null,
	errorRemoveCard: null,
	errorUpdateClientBackend: null,
	statusUpdateClientBackend: 'idle',
}

export const getClientFeatureState = createFeatureSelector<ClientState>('client')

export const getClient = createSelector(getClientFeatureState, state => state.client)
export const getStatusClient = createSelector(getClientFeatureState, state => state.loginStatus)
export const isLoadingRegisterCard = createSelector(
	getClientFeatureState,
	state => state.registerCardStatus === 'pending'
)
export const isLoadingMainCard = createSelector(
	getClientFeatureState,
	state => state.mainCardStatus === 'pending'
)
export const isLoadingRemoveCard = createSelector(
	getClientFeatureState,
	state => state.removeCardStatus === 'pending'
)

export const isLoggedIn = createSelector(getClientFeatureState, state => state.isLoggedIn)
export const getErrorLogin = createSelector(getClientFeatureState, state => state.errorLoginStatus)
export const getErrorRegisterCard = createSelector(getClientFeatureState, state => state.errorRegisterCard)
export const getErrorMainCard = createSelector(getClientFeatureState, state => state.errorMainCard)
export const getErrorRemoveCard = createSelector(getClientFeatureState, state => state.errorRemoveCard)

export const clientReducer = createReducer<ClientState>(
	initialState,
	on(ClientActions.loginClient, ClientActions.loginClientSSO, (state): ClientState => {
		return {
			...state,
			loginStatus: 'pending',
		}
	}),
	on(ClientActions.loginClientSuccess, (state, action): ClientState => {
		return {
			...state,
			client: action.client,
			isLoggedIn: true,
			loginStatus: 'success',
		}
	}),
	on(ClientActions.loginClientFailure, (state, action): ClientState => {
		return {
			...state,
			client: null,
			errorLoginStatus: action.error,
			isLoggedIn: false,
			loginStatus: 'failure',
		}
	}),
	on(ClientActions.setRedirectUrl, (state, action): ClientState => {
		return {
			...state,
			redirectUrl: action.url,
		}
	}),
	on(ClientActions.registerCard, (state): ClientState => {
		return {
			...state,
			registerCardStatus: 'pending',
		}
	}),
	on(ClientActions.registerCardFailure, (state, action): ClientState => {
		return {
			...state,
			errorRegisterCard: action.error,
			registerCardStatus: 'failure',
		}
	}),
	on(ClientActions.registerCardSuccess, (state): ClientState => {
		return {
			...state,
			registerCardStatus: 'success',
		}
	}),

	on(ClientActions.updateMainCard, (state): ClientState => {
		return {
			...state,
			mainCardStatus: 'pending',
		}
	}),
	on(ClientActions.updateMainCardError, (state, action): ClientState => {
		return {
			...state,
			errorMainCard: action.error,
			mainCardStatus: 'failure',
		}
	}),
	on(ClientActions.updateMainCardSuccess, (state): ClientState => {
		return {
			...state,
			mainCardStatus: 'success',
		}
	}),

	on(ClientActions.removeCard, (state): ClientState => {
		return {
			...state,
			removeCardStatus: 'pending',
		}
	}),
	on(ClientActions.removeCardFailure, (state, action): ClientState => {
		return {
			...state,
			errorRemoveCard: action.error,
			removeCardStatus: 'failure',
		}
	}),
	on(ClientActions.removeCardSuccess, (state): ClientState => {
		return {
			...state,
			removeCardStatus: 'success',
		}
	}),
	on(ClientActions.logoutClient, (state): ClientState => {
		return {
			...state,
			isLoggedIn: false,
			client: null,
		}
	}),
	on(ClientActions.selectCategory, (state, action): ClientState => {
		return {
			...state,
			client: state.client?.setCard(action.gatewayId, action.lastFourDigits) ?? null,
		}
	}),
	on(ClientActions.updateClientBackend, (state): ClientState => {
		return {
			...state,
			statusUpdateClientBackend: 'pending',
		}
	}),

	on(ClientActions.updateClientBackendSuccess, (state): ClientState => {
		return {
			...state,
			statusUpdateClientBackend: 'success',
			errorUpdateClientBackend: null,
		}
	}),
	on(ClientActions.updateClientBackendFailure, (state, action): ClientState => {
		return {
			...state,
			statusUpdateClientBackend: 'failure',
			errorUpdateClientBackend: action.error,
		}
	})
)
