import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Subject, combineLatest, filter, map, merge, take, takeUntil, tap } from 'rxjs'
import { BreadcrumbsData } from 'src/app/components/breadcrumb/breadcrumb.component'
import { ModalService } from 'src/app/components/modal/modal.service'
import { PREFIX_TITLE } from 'src/shared/helpers/prefix-title'
import { AppState } from 'src/state/app.state'
import { BenefitsActions } from 'src/state/benefits/benefits.actions'
import { getBiggestMultiplier } from 'src/state/benefits/benefits.reducers'
import { CheckInActions } from 'src/state/checkin/checkin.actions'
import { getErrorAvailabilities, getErrorConfirm } from 'src/state/checkin/checkin.reducers'
import { ClientActions } from 'src/state/client/client.actions'
import { getClient, isLoggedIn } from 'src/state/client/client.reducers'
import { AvailabilityActions } from 'src/state/reservation/reservation.actions'
import { getErrorAvailability, getErrorConfirmation } from 'src/state/reservation/reservation.reducers'
import { getSelectedVenue, isLoadingSelectedVenue } from 'src/state/venue/venue.reducers'

@Component({
	templateUrl: './venue-details.page.html',
	styleUrls: ['./venue-details.page.scss'],
})
export class VenueDetailsPage implements OnInit, OnDestroy {
	pageTitle = 'Restaurante'
	isMobile = false
	selectedVenue$ = this.store.select(getSelectedVenue).pipe(
		filter(venue => Boolean(venue)),
		tap(venue => this.title.setTitle(`${PREFIX_TITLE} - ${venue?.name}`)),
		tap(venue => {
			setTimeout(() => {
				this.breadcrumbs = [
					{ url: '/gastronomia', title: 'Gastronomia' },
					{ url: `/gastronomia/${venue?._id}`, title: venue?.name ?? '' },
				]
			}, 0)
		})
	)
	isLoadingSelectedVenue$ = this.store.select(isLoadingSelectedVenue)

	error$ = merge(
		this.store.select(getErrorAvailability),
		this.store.select(getErrorConfirmation),
		this.store.select(getErrorAvailabilities),
		this.store.select(getErrorConfirm)
	)

	modal = {
		login: 'modal-login-id',
		operationHours: 'modal-operation-hours-id',
		reservation: 'modal-reservation-id',
		reservationSuccess: 'modal-reservation-success-id',
		checkin: 'modal-checkin-id',
		checkinSuccess: 'modal-checkin-success-id',
		error: 'modal-error-id',
		registerCard: 'modal-register-card-id',
	}

	openReservation = false
	openCheckin = false
	private destroy$ = new Subject<void>()

	breadcrumbs!: BreadcrumbsData[]

	constructor(
		private store: Store<AppState>,
		private title: Title,
		private route: ActivatedRoute,
		private router: Router,
		private modalService: ModalService,
		private actions$: Actions
	) {}

	ngOnInit(): void {
		// Loading venue details is on app-handle-token, dont need to dispatch the action here...

		this.getScreenSize()
		// handle event reservations
		this.handleEventsReservation()
		this.handleEventsCheckin()
		this.handleEventsError()
		this.store.dispatch(BenefitsActions.getBiggestMultiplier())
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	private handleEventsReservation() {
		this.actions$
			.pipe(ofType(AvailabilityActions.confirmReservationSuccess), takeUntil(this.destroy$))
			.subscribe(() => {
				this.closeReservationDesk()
				this.modalService.open(this.modal.reservationSuccess)
			})
	}

	private handleEventsError() {
		this.actions$
			.pipe(
				ofType(
					AvailabilityActions.confirmReservationFailure,
					AvailabilityActions.loadAvailabilitiesFailure,
					CheckInActions.checkinConfirmFailure,
					CheckInActions.loadAvailabilitiesFailure
				),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.closeReservationDesk()
				this.closeCheckinDesk()
				this.modalService.open(this.modal.error)
			})
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize() {
		this.isMobile = window.innerWidth < 993
	}

	openModal(name: string) {
		this.modalService.open(name)
	}

	closeModal(name: string) {
		this.modalService.close(name)
	}

	goToReservationMobile() {
		this.verification(() => {
			this.router.navigate(['reserva'], { relativeTo: this.route })
		})
	}

	goToReservationDesk() {
		this.verification(() => {
			this.openReservation = true
			this.modalService.open(this.modal.reservation)
		})
	}

	closeReservationDesk() {
		this.openReservation = false
		this.modalService.close(this.modal.reservation)
	}

	private verification(fn: () => void) {
		this.store
			.select(getClient)
			.pipe(take(1))
			.subscribe(client => {
				if (!client) {
					this.modalService.open(this.modal.login)
					return
				}
				if (client && !client.selectedCategory?.isRealCard) {
					this.modalService.open(this.modal.registerCard)
					return
				}
				fn()
			})
	}

	//Check-in
	goToCheckinDesk() {
		this.verification(() => {
			this.openCheckin = true
			this.modalService.open(this.modal.checkin)
		})
	}

	closeCheckinDesk() {
		this.openCheckin = false
		this.modalService.close(this.modal.checkin)
	}

	goToCheckinMobile() {
		this.verification(() => {
			this.router.navigate(['checkin'], { relativeTo: this.route })
		})
	}

	private handleEventsCheckin() {
		this.actions$
			.pipe(ofType(CheckInActions.checkinConfirmSuccess), takeUntil(this.destroy$))
			.subscribe(() => {
				this.closeCheckinDesk()
				this.modalService.open(this.modal.checkinSuccess)
			})
	}
}
