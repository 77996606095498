export class Address {
	value: string
	constructor(
		readonly city: string,
		readonly state: string,
		readonly neighborhood: string,
		readonly address: string,
		readonly number: string
	) {
		this.value = `${address}, ${number}, ${neighborhood}, ${city}, ${state}`
	}
}
