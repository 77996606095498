import { ORDER_PAYMENT_STATUS, ORDER_STATUS } from 'src/state/reservation/order'
import { Address } from './address.vo'
import { Benefit, Benefits } from './benefits'

export class OrderHistory {
	private constructor(
		readonly id: string,
		readonly intent: string,
		readonly venue: { name: string; address: Address; id: string },
		readonly date: { day: string; month: string; humanDay: string; time: string },
		readonly reservationDateTime: number,
		readonly hasPaymentConfig: boolean,
		readonly wasPaid: boolean,
		readonly canPay: boolean,
		readonly paymentBlocked: boolean,
		readonly benefits: Benefits,
		readonly gratuityPercent: number,
		readonly points?: number | undefined,
		readonly payment?: {
			points: number
			lastFourDigits: string
			code: string
			intialValue: number
			gratuityValue: number
			totalValue: number
		},
		readonly removeGratuityValue?: boolean
	) {}

	static create(props: OrderFromHistoryProps) {
		const id = props?._id
		// address
		const address = new Address(
			props?.venue.address.city ?? '',
			props?.venue.address.state ?? '',
			props?.venue.address.neighborhood ?? '',
			props?.venue.address.address ?? '',
			props?.venue.address.number ?? ''
		)
		const name = props?.venue.name
		const intent = this.getIntent(props?.intent)
		const date = this.getDayAndMonth(new Date(props?.reservationDay), props?.reservationTime)
		const reservationTime = this.getReservationDateTime(props?.reservationDay, props.reservationTime)
		const wasPaid = this.checkWasPaid(props?.paymentStatus)
		const hasPaymentConfig = props?.venueHasPaymentConfig ?? false
		const canPay = this.canPay(props?.paymentStatus, hasPaymentConfig)
		const paymentBlocked = this.paymentBlocked(props?.paymentStatus, hasPaymentConfig)
		const benefits = new Benefits(props.benefits)
		const gratuityPercent = props?.gratuityPercent ?? 10
		let payment
		if (props?.payment) {
			payment = {
				points: props.payment.orderTotalPoints,
				lastFourDigits: props.payment.lastFourDigits,
				code: props.payment.code,
				intialValue: props.payment.orderInitialValue,
				gratuityValue: props.payment.gratuityValue,
				totalValue: props.payment.orderTotalValue,
			}
		}
		return new OrderHistory(
			id,
			intent,
			{ name, address, id: props.venueId },
			date,
			reservationTime,
			hasPaymentConfig,
			wasPaid,
			canPay,
			paymentBlocked,
			benefits,
			gratuityPercent,
			props.payment?.orderTotalPoints,
			payment,
			props?.removeGratuityValue ?? false
		)
	}

	private static getIntent(intent: string) {
		if (intent === 'reservation') {
			return 'Reserva de Mesa'
		}
		if (intent === 'checkin') {
			return 'CheckIn'
		}
		if (intent === 'waitlist') {
			return 'Fila de Espera'
		}
		return 'Order sem intent'
	}

	private static checkWasPaid(paymentStatus: ORDER_PAYMENT_STATUS) {
		return paymentStatus === 'DONE'
	}

	private static canPay(paymentStatus: ORDER_PAYMENT_STATUS, hasPaymentConfig: boolean) {
		if (!hasPaymentConfig) {
			return false
		}
		return paymentStatus === 'READY' || paymentStatus === 'FAILED'
	}

	private static paymentBlocked(paymentStatus: ORDER_PAYMENT_STATUS, hasPaymentConfig: boolean) {
		if (!hasPaymentConfig) {
			return false
		}
		return paymentStatus === 'NOT_DONE'
	}

	private static getDayAndMonth(date: Date, time: string) {
		const day = String(date.getUTCDate()).padStart(2, '0')
		const month = date.toLocaleString('pt-br', { month: 'short' }).toUpperCase().slice(0, -1)
		const humanDay = new Intl.DateTimeFormat('pt-BR', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		}).format(date)
		return { day, month, humanDay, time }
	}

	private static getReservationDateTime(reservationDay: string, reservationTime: string): number {
		if (!reservationDay) {
			// reservationTime always in the future for return FALSE in canDay$
			return Infinity
		}
		const [day, _] = reservationDay.split('T')
		return Date.parse(`${day}T${reservationTime}`)
	}
}

export interface OrderFromHistoryProps {
	_id: string
	intent: 'reservation' | 'waitlist' | 'checkin'
	partySize: number
	sectionLabel: string
	status: ORDER_STATUS
	benefits: Benefit[]
	reservationDay: string //'2022-11-25T03:00:00.000Z'
	reservationTime: string //'19:15'
	venueId: string
	clientId: string
	categoryId: string
	partnerId: string
	client: {
		name: string
		lastName: string
		phone: string
		cpf: string
	}
	createdAt: string //'2022-11-25T14:13:58.248Z'
	payment?: {
		orderInitialValue: number
		gratuityValue: number
		orderTotalValue: number
		orderTotalPoints: number
		paidAt: string //'2022-11-25T14:18:55.572Z'
		code: string
		lastFourDigits: string
	}
	removeGratuityValue?: boolean
	venue: {
		_id: string
		address: {
			city: string
			state: string
			neighborhood: string
			address: string
			number: string
			location: [number, number]
		}
		name: string
		partner: {
			partnerId: string
		}
	}
	paymentStatus: ORDER_PAYMENT_STATUS
	canceledAt: string
	gratuityPercent?: number
	venueHasPaymentConfig?: boolean
}
