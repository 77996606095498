import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { VenuesListPage } from './page/venues-list/venues-list.page'
import { VenueDetailsPage } from './page/venue-details/venue-details.page'
import { HowItWorksPage } from './page/how-it-works/how-it-works.page'
import { CreateReservationPage } from './page/create-reservation/create-reservation.page'
import { OrderHistoryPage } from './page/order-history/order-history.page'
import { ConfirmReservationSuccessPage } from './page/confirm-reservation/confirm-reservation-success.page'
import { RegisterCardPage } from './page/register-card-page/register-card.page'
import { WalletPage } from './page/wallet/wallet.page'
import { AuthGuard } from 'src/state/client/auth.guard'
import { PaymentPage } from './page/payment/payment.page'
import { PaymentCardPage } from './page/payment-card/payment-card.page'
import { ConfirmPaymentSuccessPage } from './page/confirm-payment/confirm-payment-success.page'
import { SandboxPageTest } from './page/sandbox/sandbox.page'
import { CheckinConfirmPage } from './page/checkin/checkin-confirm.page'
import { OrderDetailsPage } from './page/order-details/order-details.page'
import { CheckinSuccessPage } from './page/checkin/checkin-success.page'

const routes: Routes = [
	{ path: 'gastronomia', component: VenuesListPage },
	{ path: 'gastronomia/:venueId', component: VenueDetailsPage, pathMatch: 'full' },
	{
		path: 'gastronomia/:venueId/reserva',
		component: CreateReservationPage,
		pathMatch: 'full',
		canActivate: [AuthGuard],
	},
	{
		path: 'gastronomia/:venueId/reserva-sucesso',
		component: ConfirmReservationSuccessPage,
		canActivate: [AuthGuard],
		pathMatch: 'full',
	},
	{
		path: 'gastronomia/:venueId/checkin',
		component: CheckinConfirmPage,
		canActivate: [AuthGuard],
		pathMatch: 'full',
	},
	{
		path: 'gastronomia/:venueId/checkin-sucesso',
		component: CheckinSuccessPage,
		canActivate: [AuthGuard],
		pathMatch: 'full',
	},
	{ path: 'carteira', component: WalletPage, canActivate: [AuthGuard] },
	{ path: 'carteira/adicionar-cartao', component: RegisterCardPage, canActivate: [AuthGuard] },
	{ path: 'reservas-e-pagamento', component: OrderHistoryPage },
	{ path: 'reservas-e-pagamento/reserva/:id', component: OrderDetailsPage, canActivate: [AuthGuard] },
	{ path: 'reservas-e-pagamento/pagamento', component: PaymentPage, canActivate: [AuthGuard] },
	{
		path: 'reservas-e-pagamento/pagamento/confirmacao',
		component: PaymentCardPage,
		canActivate: [AuthGuard],
	},
	{
		path: 'reservas-e-pagamento/pagamento/confirmacao-sucesso',
		component: ConfirmPaymentSuccessPage,
		canActivate: [AuthGuard],
	},
	{ path: 'sandbox', component: SandboxPageTest },
	{ path: 'como-funciona', component: HowItWorksPage },
	{ path: '', redirectTo: '/gastronomia', pathMatch: 'full' },
	{ path: '**', redirectTo: '/gastronomia', pathMatch: 'full' },
]

@NgModule({
	declarations: [],
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
