<app-header></app-header>

<div style="padding-top: 11px; max-width: 1184px; margin: 0 auto" class="desktop">
	<app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>
</div>

<div class="mobile" *ngIf="isMobile">
	<app-template-tab>
		<div class="details-body__wrapper">
			<p class="details-body__title">Histórico de reservas</p>
			<button class="go-back" (click)="location.back()">
				<i class="fa fa-arrow reverse"></i> Voltar
			</button>
			<app-order-details
				*ngIf="selectedOrder$ | async as order"
				[order]="order"
				(cancelClicked)="openModalCancel(order.id)"
			></app-order-details>
		</div>
	</app-template-tab>
	<div style="height: 75px"></div>
</div>

<div class="desktop" *ngIf="!isMobile">
	<app-template-tab>
		<div class="details-body__wrapper">
			<p class="details-body__title">Histórico de reservas</p>
			<button class="go-back" (click)="location.back()">
				<i class="fa fa-arrow reverse"></i> Voltar
			</button>
			<app-order-details
				*ngIf="selectedOrder$ | async as order"
				[order]="order"
				(cancelClicked)="openModalCancel(order.id)"
			></app-order-details>
		</div>
	</app-template-tab>
</div>

<app-footer></app-footer>

<app-modal [id]="modal.cancelOrder" top="15%">
	<app-modal-basic
		[id]="modal.cancelOrder"
		title="Tem certeza que deseja cancelar a reserva?"
		[hasClose]="true"
	>
		<ng-container *ngIf="{loading: loadingCancel$ |async} as obj">
			<button
				class="btn btn--primary"
				(click)="cancelOrder()"
				style="position: relative"
				[ngClass]="{'btn--disabled': obj.loading}"
				[disabled]="obj.loading"
			>
				Sim
				<app-spinner
					*ngIf="obj.loading"
					style="position: absolute; top: 5px; right: 10px"
				></app-spinner>
			</button>
			<button class="btn btn--inverted" (click)="modalService.close(modal.cancelOrder)">Não</button>
		</ng-container>
	</app-modal-basic>
</app-modal>
