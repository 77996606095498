<app-header></app-header>

<div style="padding-top: 11px; max-width: 1184px; margin: 0 auto" class="desktop">
	<app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>
</div>

<app-template-tab>
	<div class="order">
		<div class="order__header">
			<p class="order__title">Histórico de reservas</p>
		</div>
		<ng-container *ngIf="{orders: orders$ | async } as obj">
			<div class="noReserva" *ngIf="!obj.orders || obj.orders?.length === 0">
				<i class="fa fa-info"></i> Nenhuma reserva no momento
			</div>
		</ng-container>
		<div
			style="display: flex; justify-content: center; align-items: center; height: 50vh"
			*ngIf="loadingHistory$ | async"
		>
			<app-spinner></app-spinner>
		</div>
		<div class="order__list" *ngIf="orders$ | async as orders">
			<app-order-card
				*ngFor="let order of orders"
				[order]="order"
				(cancelClicked)="openModalCancelOrder($event)"
			></app-order-card>
		</div>
	</div>
</app-template-tab>

<div class="px-16 mobile" style="margin-top: 48px; margin-bottom: 21px">
	<button class="btn btn--inverted" routerLink="/gastronomia">Ir para Home</button>
</div>

<app-footer></app-footer>

<app-modal [id]="modal.error" top="15%">
	<app-modal-basic [id]="modal.error" title="Algum erro aconteceu" *ngIf="error$ | async as error">
		{{error}}
	</app-modal-basic>
</app-modal>

<app-modal [id]="modal.cancelOrder" top="15%">
	<app-modal-basic
		[id]="modal.cancelOrder"
		title="Tem certeza que deseja cancelar a reserva?"
		[hasClose]="true"
	>
		<ng-container *ngIf="{loading: loadingCancel$ |async} as obj">
			<button
				class="btn btn--primary"
				(click)="cancelOrder()"
				style="position: relative"
				[ngClass]="{'btn--disabled': obj.loading}"
				[disabled]="obj.loading"
			>
				Sim
				<app-spinner
					*ngIf="obj.loading"
					style="position: absolute; top: 5px; right: 10px"
				></app-spinner>
			</button>
			<button class="btn btn--inverted" (click)="modalService.close(modal.cancelOrder)">Não</button>
		</ng-container>
	</app-modal-basic>
</app-modal>

<app-modal [id]="modal.login" top="35%" [preventOutsideClickClose]="true">
	<app-modal-login [id]="modal.login"></app-modal-login>
</app-modal>

<app-handle-login-token></app-handle-login-token>
