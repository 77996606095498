import { HttpClient } from '@angular/common/http'
import { Observable, map } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Injectable } from '@angular/core'
import AddressGateway from './address.gateway'
import { AddressApi } from 'src/state/address/address'

export const DEFAULT_LIMIT = 10

@Injectable()
export class HttpAddressGateway implements AddressGateway {
	baseURL = `${environment.channelsAPI.baseUrl}/api/v1/address`
	constructor(private http: HttpClient) {}
	getAddresses(): Observable<string[]> {
		return this.http
			.get<AddressApi[]>(`${this.baseURL}`)
			.pipe(map(addressApi => [...new Set(addressApi.map(address => address._id.city.trim()))]))
	}
}
