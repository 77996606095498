import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Store } from '@ngrx/store'
import { take } from 'rxjs'
import { PREFIX_TITLE } from 'src/shared/helpers/prefix-title'
import { AppState } from 'src/state/app.state'
import { getSelectOrder } from 'src/state/order-history/order-history.reducers'

@Component({
	template: `
		<app-header></app-header>
		<app-confirm-payment></app-confirm-payment>
		<div class="button">
			<button class="btn btn--inverted" routerLink="/reservas-e-pagamento">Ok</button>
		</div>

		<app-footer></app-footer>
	`,
	styles: [
		`
			.button {
				margin: 20px 16px;
			}
		`,
	],
})
export class ConfirmPaymentSuccessPage implements OnInit {
	constructor(private store: Store<AppState>, private title: Title) {}

	ngOnInit(): void {
		this.store
			.select(getSelectOrder)
			.pipe(take(1))
			.subscribe(order => {
				this.title.setTitle(
					`${PREFIX_TITLE} - Reservas e Pagamento - ${order?.venue?.name} - Pagamento realizado com sucesso!`
				)
			})
	}
}
