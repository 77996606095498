<app-success-template template="payment">
	<ng-container *ngIf="confirmPaymentSuccess$ | async as confirmPayment">
		<div class="info">
			<p class="label">Nome</p>
			<p class="text">{{ confirmPayment.name }}</p>
		</div>

		<div class="info">
			<p class="label">Restaurante</p>
			<p class="text">{{ confirmPayment.venueName }}</p>
		</div>

		<div class="info">
			<p class="label">Data</p>
			<p class="text">{{ confirmPayment.humanDate }}</p>
		</div>

		<div class="info">
			<p class="label">Cartão de crédito</p>
			<div class="card">
				<ng-container *ngIf="chooseBrand.get(confirmPayment.brand) as img">
					<img [src]="img.src" [alt]="img.alt" class="card__img" />
				</ng-container>
				<p class="text">.... {{ confirmPayment.lastFourDigits }}</p>
			</div>
		</div>

		<div class="info">
			<p class="label">Valor pago</p>
			<p class="text">{{ confirmPayment.totalValue | currency : 'BRL' }}</p>
		</div>

		<div class="info">
			<p class="label">
				Pontos ganhos
				<i
					class="fa fa-info colorBlue"
					(mouseover)="showTip = true"
					(mouseout)="showTip = false"
					(click)="showTip = !showTip"
				>
					<div class="tip" *ngIf="showTip">
						Seus pontos estarão disponíveis para uso em até 48 horas.
					</div></i
				>
			</p>
			<p class="text">{{ confirmPayment.points }} pontos</p>
		</div>
	</ng-container>
</app-success-template>
