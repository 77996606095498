import { createFeatureSelector, createReducer, on, createSelector } from '@ngrx/store'
import { AvailabilityCheckin } from './checkin'
import { Status } from '../status.type'
import { CheckInActions } from './checkin.actions'
import { CheckinDate } from 'src/entity/checkin-date.vo'

export interface CheckInState {
	availabilities: AvailabilityCheckin | null
	statusAvailabilities: Status | 'unavailable'
	errorAvailabilities: string | null
	orderId: string | null
	checkinDate: CheckinDate | null
	statusConfirm: Status
	errorConfirm: string | null
}

const initialState: CheckInState = {
	availabilities: null,
	statusAvailabilities: 'idle',
	errorAvailabilities: null,
	orderId: null,
	checkinDate: null,
	statusConfirm: 'idle',
	errorConfirm: null,
}

const getCheckinFeatureState = createFeatureSelector<CheckInState>('checkin')

export const getOrderId = createSelector(getCheckinFeatureState, state => state.orderId)
export const getErrorAvailabilities = createSelector(
	getCheckinFeatureState,
	state => state.errorAvailabilities
)
export const getErrorConfirm = createSelector(getCheckinFeatureState, state => state.errorConfirm)
export const getAvailabilities = createSelector(getCheckinFeatureState, state => state.availabilities)
export const getStatusAvailabilities = createSelector(
	getCheckinFeatureState,
	state => state.statusAvailabilities
)

export const getCheckInDate = createSelector(getCheckinFeatureState, state => state.checkinDate)

export const checkinReducer = createReducer<CheckInState>(
	initialState,
	on(CheckInActions.loadAvailabilities, (state): CheckInState => {
		return {
			...state,
			statusAvailabilities: 'pending',
		}
	}),
	on(CheckInActions.loadAvailabilitiesSuccess, (state, action): CheckInState => {
		return {
			...state,
			availabilities: action.availabilities,
			orderId: action.orderId,
			statusAvailabilities: 'success',
			errorAvailabilities: null,
		}
	}),
	on(CheckInActions.loadAvailabilitiesFailure, (state, action): CheckInState => {
		return {
			...state,
			errorAvailabilities: action.error,
			statusAvailabilities: 'failure',
		}
	}),
	on(CheckInActions.checkinUnavailable, (state, action): CheckInState => {
		return {
			...state,
			availabilities: action.availabilities,
			statusAvailabilities: 'unavailable',
		}
	}),
	on(CheckInActions.setOrderId, (state, action): CheckInState => {
		return {
			...state,
			orderId: action.orderId,
		}
	}),
	on(CheckInActions.checkinConfirm, (state, action): CheckInState => {
		return {
			...state,
			checkinDate: action.checkInDate,
			statusConfirm: 'pending',
			errorConfirm: null,
		}
	}),
	on(CheckInActions.checkinConfirmSuccess, (state): CheckInState => {
		return {
			...state,
			errorConfirm: null,
			statusConfirm: 'success',
		}
	}),
	on(CheckInActions.checkinConfirmFailure, (state, action): CheckInState => {
		return {
			...state,
			errorConfirm: action.error,
			statusConfirm: 'failure',
		}
	})
)
