import { CHOOSE_PARTYSIZE } from 'src/state/reservation/reservation.reducers'

export class Partysize {
	static format(partysize: string) {
		if (partysize === CHOOSE_PARTYSIZE) {
			return partysize
		}
		if (+partysize === 1) {
			return `Mesa para 1 pessoa`
		}
		return `Mesa para ${partysize} pessoas`
	}
}
