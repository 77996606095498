import { Inject, Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { HttpVenuesGateway } from 'src/gateway/venue/http-venues.gateway'
import { AppState } from '../app.state'
import { VenueActions } from './venue.actions'
import { catchError, filter, map, mergeMap, of } from 'rxjs'
import { getSelectedVenue, getVenues, getLocation } from './venue.reducers'
import VenuesGateway from 'src/gateway/venue/venues.gateway'
import { DEFAULT_CUISINE_TEXT, getSelectedCuisine } from '../cuisines/cuisines.reducers'
import { DEFAULT_ADDRESS_TEXT, getSelectedAddress } from '../address/address.reducers'
import { getClient } from '../client/client.reducers'
import { GeolocationService } from 'src/shared/services/geo-location.service'

@Injectable()
export class VenueEffects {
	constructor(
		private actions$: Actions,
		@Inject(HttpVenuesGateway) private venueGateway: VenuesGateway,
		private store: Store<AppState>,
		private geolocation: GeolocationService
	) {}

	// Cached
	getVenues$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.getVenues),
			concatLatestFrom(() => this.store.select(getVenues)),
			// Only load venues if the array has at least one venue
			filter(([_, venues]) => !Boolean(venues?.length)),
			map(() => VenueActions.loadVenues({}))
		)
	})

	// Call API (not cached)
	loadVenues$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.loadVenues),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) =>
				this.venueGateway
					.filter({ ...action.filterVenueDto, categoryId: client?.selectedCategory?.categoryId })
					.pipe(
						map(venues => VenueActions.loadVenuesSuccess({ venues })),
						catchError(error => of(VenueActions.loadVenuesFailure({ error })))
					)
			)
		)
	})

	// This actions dispatch another action to LoadVenues
	loadVenuesByFilters$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.applyFilter),
			concatLatestFrom(() => [
				this.store.select(getSelectedCuisine),
				this.store.select(getSelectedAddress),
			]),
			map(([_, cuisine, address]) =>
				VenueActions.loadVenues({
					filterVenueDto: {
						cousine: cuisine.name === DEFAULT_CUISINE_TEXT ? undefined : cuisine._id,
						address: { city: address === DEFAULT_ADDRESS_TEXT ? undefined : address },
						venuesAlreadyInView: [],
					},
				})
			)
		)
	})

	// Cached
	getSelectedVenue$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.getSelectedVenue),
			concatLatestFrom(() => this.store.select(getSelectedVenue)),
			// Only load single venue if null
			filter(([_, selectedVenue]) => !Boolean(selectedVenue)),
			map(([action, _]) => VenueActions.loadOneVenue({ venueId: action.venueId }))
		)
	})

	loadOneVenue$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.loadOneVenue),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) =>
				this.venueGateway
					.getVenueId(action.venueId, {
						...action.findOneVenueDto,
						...(client?.selectedCategory?.categoryId
							? { categoryId: client.selectedCategory.categoryId }
							: {}),
					})
					.pipe(
						map(venue => VenueActions.loadOneVenueSuccess({ venue })),
						catchError(error => of(VenueActions.loadOneVenueFailure({ error })))
					)
			)
		)
	})

	loadMoreVenues$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.loadMoreVenue),
			concatLatestFrom(() => {
				return [
					this.store.select(getVenues),
					this.store.select(getSelectedAddress),
					this.store.select(getClient),
					this.store.select(getLocation),
				]
			}),
			mergeMap(([_, venues, selectedAddress, client, location]) =>
				this.venueGateway.filter({
					address: {
						city: selectedAddress === DEFAULT_ADDRESS_TEXT ? undefined : selectedAddress,
						...(location && { location }),
					},
					venuesAlreadyInView: venues?.map(venue => venue.id),
					categoryId: client?.selectedCategory?.categoryId,
				})
			),
			map(venues => VenueActions.loadMoreVenuesSuccess({ venues })),
			catchError(error => of(VenueActions.loadMoreVenuesFailure({ error })))
		)
	})

	loadVenuesByLocation$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(VenueActions.setLocation),
			concatLatestFrom(() => [this.store.select(getVenues)]),
			filter(([_, venues]) => !Boolean(venues?.length) || this.geolocation.firstTime),
			map(([{ latitude, longitude, maxDistance }]) => {
				this.geolocation.firstTime = false
				return VenueActions.loadVenues({
					filterVenueDto: {
						address: {
							location: { latitude, longitude, maxDistance },
						},
						venuesAlreadyInView: [],
					},
				})
			})
		)
	})
}
