import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { filter, interval, map, startWith } from 'rxjs'
import { OrderHistory } from 'src/entity/order-history'

@Component({
	selector: 'app-order-details',
	templateUrl: './order-details.component.html',
	styleUrls: ['order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
	masterCard: 'masterCard' | null = 'masterCard'
	@Input() order!: OrderHistory | null
	@Output() cancelClicked = new EventEmitter<void>()
	constructor() {}
	ngOnInit(): void {}

	nowEverySecond$ = interval(1000).pipe(
		startWith(0),
		map(() => Date.now())
	)

	canCancel$ = this.nowEverySecond$.pipe(
		filter(() => Boolean(this.order)),
		map(NOW => NOW <= this.order!.reservationDateTime),
		map(value => (this.order!.wasPaid ? false : value))
	)
}
