<app-modal-basic title="Alterar a gorjeta do garçom?" [id]="id">
	<div class="tip__value">
		<p class="tip__valueOne">Valor sugerido: {{ suggestedTip }}%</p>
		<p class="tip__valueTwo">{{ formTip.value.tip }}% ({{ billTip | currency : 'BRL' }})</p>
	</div>

	<form [formGroup]="formTip">
		<input
			type="range"
			class="tip__range"
			[min]="suggestedTip"
			max="25"
			[defaultValue]="suggestedTip"
			formControlName="tip"
		/>
	</form>

	<p class="tip__message" *ngIf="hasTipChanged$ | async">
		<i class="tagmeicon tagmeicon-smile-bom-24"></i> Nossa equipe ficará muito feliz!
	</p>

	<button class="btn btn--primary" (click)="modalService.close(this.id)">Aplicar</button>
</app-modal-basic>
