import { Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Subject, combineLatest, map, takeUntil } from 'rxjs'
import { BreadcrumbsData } from 'src/app/components/breadcrumb/breadcrumb.component'
import { ModalService } from 'src/app/components/modal/modal.service'
import { PREFIX_TITLE } from 'src/shared/helpers/prefix-title'
import { AppState } from 'src/state/app.state'
import { BenefitsActions } from 'src/state/benefits/benefits.actions'
import { getBiggestMultiplier } from 'src/state/benefits/benefits.reducers'
import { ClientActions } from 'src/state/client/client.actions'
import { getClient } from 'src/state/client/client.reducers'
import { OrderHistoryActions } from 'src/state/order-history/order-history.actions'
import { getHasPaymentReady, getOrdersToPay } from 'src/state/order-history/order-history.reducers'
import { VenueActions } from 'src/state/venue/venue.actions'
import {
	getErrorVenues,
	getVenues,
	isAllLoaded,
	isLoadingGetVenues,
	loadingMoreVenues,
} from 'src/state/venue/venue.reducers'

@Component({
	templateUrl: './venues-list.page.html',
	styleUrls: ['./venues-list.page.scss'],
})
export class VenuesListPage implements OnInit, OnDestroy {
	pageTitle = 'Gastronomia'
	venues$ = this.store.select(getVenues)
	client$ = this.store.select(getClient)
	hasPaymentReady$ = this.store.select(getHasPaymentReady)
	ordersToPay$ = this.store.select(getOrdersToPay)
	modal = {
		error: 'modal-error-get-venues',
	}
	error$ = this.store.select(getErrorVenues)
	private destroy$ = new Subject<void>()
	breadcrumbs: BreadcrumbsData[] = [{ url: '/gastronomia', title: 'Gastronomia' }]

	// Loading
	isLoadingVenues$ = this.store.select(isLoadingGetVenues)
	loadingObject$ = combineLatest([
		this.store.select(isAllLoaded),
		this.store.select(loadingMoreVenues),
	]).pipe(map(([allLoaded, loadingMoreVenues]) => ({ allLoaded, loadingMoreVenues })))

	constructor(
		private store: Store<AppState>,
		private title: Title,
		public modalService: ModalService,
		private actions$: Actions
	) {}

	ngOnInit(): void {
		// Set title
		this.title.setTitle(`${PREFIX_TITLE} - ${this.pageTitle}`)
		this.store.dispatch(BenefitsActions.getBiggestMultiplier())
		this.actions$
			.pipe(
				ofType(VenueActions.loadVenuesFailure, VenueActions.loadMoreVenuesFailure),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.modalService.open(this.modal.error)
			})
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	viewMoreVenues() {
		this.store.dispatch(VenueActions.loadMoreVenue())
	}
}
