import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/shared/helpers/prefix-title'

@Component({
	templateUrl: './how-it-works.page.html',
	styleUrls: ['how-it-works.page.scss'],
})
export class HowItWorksPage implements OnInit {
	constructor(private title: Title) {}
	pageTitle = 'Como Funciona'
	ngOnInit(): void {
		this.title.setTitle(`${PREFIX_TITLE} - ${this.pageTitle}`)
	}
}
