import { environment } from 'src/environments/environment'
import { Address } from './address.vo'
import { CoverURL } from './coverURL.vo'
import { VenuesFilterProperties } from '../gateway/venue/venues-filter.interface'
import { VenueDetailProps } from 'src/gateway/venue/venue-detail.interface'
import { Benefits } from './benefits'

export class VenueFilter {
	id: string
	name: string
	cuisines: string[]
	address: Address
	coverURL: CoverURL
	isNotEmpty: boolean
	benefits: Benefits
	constructor(props: VenuesFilterProperties | null) {
		this.id = props?._id ?? ''
		this.name = props?.name ?? ''
		this.cuisines = props?.cousines.map(cuisine => cuisine.name) ?? ['']
		this.benefits = new Benefits(props?.benefits)

		// isNotEmpty
		this.isNotEmpty = Boolean(props)

		// address
		this.address = new Address(
			props?.address.city ?? '',
			props?.address.state ?? '',
			props?.address.neighborhood ?? '',
			props?.address.address ?? '',
			props?.address.number ?? ''
		)

		// coverURL
		this.coverURL = new CoverURL(props?.partner?.images?.thumb[0]?.coverUrl)
	}
}

export class VenueDetail {
	_id: string
	address: Address
	name: string
	cuisines: string[]
	coverURLsMobile: string[]
	coverURLsDesktop: string[]
	hasMenu: boolean
	mapsMobile: string
	mapsDesk: string
	mapsLink!: string
	liveMenuURL: string
	operationHours: { day: string; start: string; end: string }[]
	benefits: Benefits
	hasCheckin: {
		enabled: boolean
		available: boolean
	}
	reservation?: {
		enabled: boolean
	}

	constructor(props: VenueDetailProps) {
		this._id = props._id ?? ''
		this.name = props?.name ?? ''
		this.cuisines = props?.cousines.map((cuisine: any) => cuisine.name) ?? ['']
		this.hasMenu = props.hasMenu
		this.operationHours = props.operationHours
		this.benefits = new Benefits(props.benefits)
		this.hasCheckin = props.checkin
		this.reservation = props.reservation

		// address
		this.address = new Address(
			props?.address.city ?? '',
			props?.address.state ?? '',
			props?.address.neighborhood ?? '',
			props?.address.address ?? '',
			props?.address.number ?? ''
		)

		// coverURL
		this.coverURLsMobile = props?.partner.images.thumb.map((URL: { coverUrl: string }) => {
			const vo = new CoverURL(URL.coverUrl)
			return vo.value
		})
		this.coverURLsDesktop = props?.partner.images.banners.map((URL: { coverUrl: string }) => {
			const vo = new CoverURL(URL.coverUrl)
			return vo.value
		})

		// maps mobile and desk
		const location = props.address.location
		const latLong = `${location![1] || 0}, ${location![0] || 0}`
		this.mapsMobile = `https://maps.googleapis.com/maps/api/staticmap?markers=color:red|${latLong}&center=${latLong}&zoom=18&key=${environment.googleMapsAPIToken}&size=343x205`
		this.mapsDesk = `https://maps.googleapis.com/maps/api/staticmap?markers=color:red|${latLong}&center=${latLong}&zoom=18&key=${environment.googleMapsAPIToken}&size=660x424`
		this.mapsLink = `https://www.google.com/maps?q=${latLong}`
		// liveMenuURL
		const alternateMenu = props.partner?.alternateMenu
		this.liveMenuURL = alternateMenu
			? `${environment.liveMenu.url}/${alternateMenu}?origin=channels`
			: `${environment.liveMenu.url}/${props?._id}?origin=channels`
	}
}
