import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Subject, map, merge, takeUntil, tap } from 'rxjs'
import { ModalService } from 'src/app/components/modal/modal.service'
import { PREFIX_TITLE } from 'src/shared/helpers/prefix-title'
import { ChooseBrandService } from 'src/shared/services/choose-brand.service'
import { AppState } from 'src/state/app.state'
import { ClientActions } from 'src/state/client/client.actions'
import {
	getClient,
	getErrorMainCard,
	getErrorRemoveCard,
	getStatusClient,
	isLoadingMainCard,
	isLoadingRemoveCard,
} from 'src/state/client/client.reducers'

@Component({
	templateUrl: './wallet.page.html',
	styleUrls: ['wallet.page.scss'],
})
export class WalletPage implements OnInit, OnDestroy {
	isMobile = false
	isOpenCards: { lastFourDigits: string; enabled: boolean }[] | null = null
	loadingClient$ = this.store.select(getStatusClient).pipe(map(status => status === 'pending'))
	clientCompleted$ = this.store.select(getStatusClient).pipe(map(status => status === 'success'))
	client$ = this.store.select(getClient).pipe(
		tap(client => {
			if (!client) return
			this.isOpenCards = client?.cards.map(card => ({
				lastFourDigits: card.lastFourDigits as string,
				enabled: false,
			}))
		})
	)

	breadcrumbs = [
		{ url: '/gastronomia', title: 'Gastronomia' },
		{ url: `/carteira/`, title: 'Carteira' },
	]
	loading$ = merge(this.store.select(isLoadingMainCard), this.store.select(isLoadingRemoveCard))
	error$ = merge(this.store.select(getErrorMainCard), this.store.select(getErrorRemoveCard))
	modal = {
		mainCard: 'modal-main-card-id',
		delete: 'modal-delete-card-id',
		error: 'modal-error-id',
	}
	open = false
	selectedLastFourDigits: string | null = null

	private destroy$ = new Subject<void>()

	constructor(
		public modalService: ModalService,
		private store: Store<AppState>,
		private actions$: Actions,
		public chooseBrand: ChooseBrandService,
		private title: Title
	) {}

	ngOnInit(): void {
		this.title.setTitle(`${PREFIX_TITLE} - Carteira`)
		this.getScreenSize()
		this.actions$
			.pipe(
				ofType(ClientActions.updateMainCardError, ClientActions.removeCardFailure),
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.modalService.open(this.modal.error)
			})
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize() {
		this.isMobile = window.innerWidth < 993
	}

	getIsOpenCard(lastFourDigits: string | undefined) {
		if (!this.isOpenCards || !lastFourDigits) {
			return
		}
		const foundCard = this.isOpenCards.find(card => card.lastFourDigits === lastFourDigits)
		return foundCard?.enabled
	}

	removeCard() {
		if (!this.selectedLastFourDigits) throw new Error('not found lastFourDigits')
		this.store.dispatch(ClientActions.removeCard({ lastFourDigits: this.selectedLastFourDigits }))
		this.modalService.close(this.modal.delete)
	}

	updateMainCard() {
		if (!this.selectedLastFourDigits) throw new Error('not found lastFourDigits')
		this.store.dispatch(ClientActions.updateMainCard({ lastFourDigits: this.selectedLastFourDigits }))
		this.modalService.close(this.modal.mainCard)
	}

	toggleOpenCard(lastFourDigits: string | undefined) {
		if (!this.isOpenCards || !lastFourDigits) {
			return
		}
		this.isOpenCards = this.isOpenCards.map(card => ({
			...card,
			enabled: card.enabled ? false : card.lastFourDigits === lastFourDigits,
		}))
		this.open = this.isOpenCards.some(card => card.enabled)
	}

	openModal(lastFourDigits: string | undefined, modalId: string) {
		if (!lastFourDigits) throw new Error('lastFourDigits not found in this card')
		this.selectedLastFourDigits = lastFourDigits
		this.modalService.open(modalId)
	}
}
