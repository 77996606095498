import { Inject, Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { ClientActions } from './client.actions'

import { mergeMap, map, catchError, filter, switchMap, tap } from 'rxjs/operators'
import { concat, merge, of } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from '../app.state'
// import { getClient, getClientId } from './client.reducers'
import ClienteGateway from 'src/gateway/client/client.gateway'
import { HttpClientGateway } from 'src/gateway/client/http-client.gateway'
import { getClient } from './client.reducers'
import { LOCAL_CLIENT_ID, LOCAL_TOKEN, LocalStorageService } from 'src/shared/services/local-storage.service'
// import { CreditCardHelper } from 'src/app/shared/helpers/credit-card.helper'
// import { LocalStorageService, LOCAL_KEYS } from 'src/app/shared/services/local-storage.service'
// import { getVenues } from './venue.reducers'

@Injectable()
export class ClientEffects {
	constructor(
		private actions$: Actions,
		@Inject(HttpClientGateway) private clientGateway: ClienteGateway,
		private store: Store<AppState>,
		private localStorageService: LocalStorageService
	) {}

	loginClientHomologation$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.loginClientHomologation),
			mergeMap(action => {
				return this.clientGateway.loginClientHomologation(action.id)
			}),
			map(client => ClientActions.loginClientSuccess({ client })),
			catchError(error => of(ClientActions.loginClientFailure({ error: error?.error?.message })))
		)
	})

	// Call API (not cached)
	loginClient$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.loginClient),
			mergeMap(action => {
				return this.clientGateway
					.loginClient(action.id)
					.pipe(map(client => ClientActions.loginClientSuccess({ client })))
			}),
			catchError(error => of(ClientActions.loginClientFailure({ error: error?.error?.message })))
		)
	})

	// Login SSO
	loginClienSSO$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.loginClientSSO),
			mergeMap(({ token }) => {
				return this.clientGateway.loginClientSSO(token).pipe(
					map(client => ClientActions.loginClientSuccess({ client })),
					catchError(error => {
						return of(ClientActions.loginClientFailure({ error: error.error?.message }))
					})
				)
			})
		)
	})

	loginClientSuccess = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(ClientActions.loginClientSuccess),
				tap(({ client }) => {
					this.localStorageService.setTime(LOCAL_CLIENT_ID, client.id)
					if (client.token) {
						localStorage.setItem(LOCAL_TOKEN, client.token)
					}
				})
			)
		},
		{ dispatch: false }
	)

	logoutClient = createEffect(
		() => {
			return this.actions$.pipe(
				ofType(ClientActions.logoutClient),
				tap(() => {
					this.localStorageService.deleteTime(LOCAL_CLIENT_ID)
					localStorage.removeItem(LOCAL_TOKEN)
					window.location.reload()
				})
			)
		},
		{ dispatch: false }
	)

	registerCardv2$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.registerCard),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) =>
				this.clientGateway
					.createCardToken({
						clientId: client!.id,
						...(action.cpf ? { cpf: action.cpf } : {}),
						categoryId: client!.selectedCategory!.categoryId,
						cardProperties: { ...action.encryptData, billingAddress: action.billingAddress },
						...(action.mainCard ? { mainCard: action.mainCard } : {}),
					})
					.pipe(
						map(res => ClientActions.registerCardSuccess()),
						catchError(error =>
							of(ClientActions.registerCardFailure({ error: error?.error?.message }))
						)
					)
			)
		)
	})

	updateClientFront$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(
				ClientActions.updateClient,
				ClientActions.registerCardSuccess,
				ClientActions.removeCardSuccess,
				ClientActions.updateMainCardSuccess
			),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([_, client]) =>
				this.clientGateway.loginClient(client!.id).pipe(
					map(client => ClientActions.loginClientSuccess({ client })),
					catchError(error =>
						of(ClientActions.loginClientFailure({ error: error?.error?.message }))
					)
				)
			)
		)
	})

	updateClientBackend$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.updateClientBackend),
			concatLatestFrom(() => this.store.select(getClient)),
			switchMap(([action, client]) => {
				return this.clientGateway
					.updateClientBackend(client!.id, { phone: `55${action.phone}` })
					.pipe(
						map(() => ClientActions.updateClientBackendSuccess()),
						catchError(response =>
							of(ClientActions.updateClientBackendFailure({ error: response?.error?.message }))
						)
					)
			})
		)
	})

	removeCard$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.removeCard),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) =>
				this.clientGateway.removeCard(client!.id, action.lastFourDigits).pipe(
					map(() => ClientActions.removeCardSuccess()),
					catchError(error => of(ClientActions.removeCardFailure({ error: error?.error?.message })))
				)
			)
		)
	})

	updateMainCard$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(ClientActions.updateMainCard),
			concatLatestFrom(() => this.store.select(getClient)),
			mergeMap(([action, client]) =>
				this.clientGateway.updateMainCard(client!.id, action.lastFourDigits).pipe(
					map(() => ClientActions.updateMainCardSuccess()),
					catchError(error =>
						of(ClientActions.updateMainCardError({ error: error?.error?.message }))
					)
				)
			)
		)
	})
}
