import { Component } from '@angular/core'
import { Store } from '@ngrx/store'
import { ChooseBrandService } from 'src/shared/services/choose-brand.service'
import { AppState } from 'src/state/app.state'
import { getConfirmPaymentSuccess } from 'src/state/payment/payment.reducers'
@Component({
	selector: 'app-confirm-payment',
	templateUrl: './confirm-payment.component.html',
	styleUrls: ['./confirm-payment.component.scss'],
})
export class ConfirmPaymentComponent {
	confirmPaymentSuccess$ = this.store.select(getConfirmPaymentSuccess)
	showTip = false
	constructor(private store: Store<AppState>, public chooseBrand: ChooseBrandService) {}
}
