import { createAction, props } from '@ngrx/store'
import { OrderHistory } from 'src/entity/order-history'

// Load order history
const loadOrderHistory = createAction('[Order History Page] Load Orders')
const loadOrderHistorySuccess = createAction(
	'[Order History Page] Load Orders Success',
	props<{ orders: OrderHistory[] }>()
)
const loadOrderHistoryFailure = createAction(
	'[Order History Page] Load Orders Failure',
	props<{ error: string }>()
)
// Cancel Order
const cancelOrder = createAction('[Order History Page] Cancel Order', props<{ orderId: string }>())
const cancelOrderSuccess = createAction(
	'[Order History Page] Cancel Order Success',
	props<{ orderId: string }>()
)
const cancelOrderFailure = createAction(
	'[Order History Page] Cancel Order Failure',
	props<{ error: string }>()
)

const setSelectedOrder = createAction('[Order History Page] Set Orders', props<{ order: OrderHistory }>())

const getOrdersPaymentReady = createAction('[Channels Api] Get Orders Payment Ready')
const getOrdersPaymentReadySuccess = createAction(
	'[Channels Api] Get Orders Payment Ready Success',
	props<{ hasPaymentReady: boolean }>()
)
const getOrdersPaymentReadyFailure = createAction(
	'[Channels Api] Get Orders Payment Ready Failure',
	props<{ error: string }>()
)

export const OrderHistoryActions = {
	loadOrderHistory,
	loadOrderHistorySuccess,
	loadOrderHistoryFailure,
	cancelOrder,
	cancelOrderSuccess,
	cancelOrderFailure,
	setSelectedOrder,
	getOrdersPaymentReady,
	getOrdersPaymentReadySuccess,
	getOrdersPaymentReadyFailure,
}
