import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Injectable } from '@angular/core'
import BenefitGateway from './benefit.gateway'

export const DEFAULT_LIMIT = 10

@Injectable()
export class HttpBenefitGateway implements BenefitGateway {
	baseURL = `${environment.channelsAPI.baseUrl}/api/v1/benefits`
	constructor(private http: HttpClient) {}
	biggestMulti(): Observable<string> {
		return this.http.get<string>(`${this.baseURL}/latam/biggestmulti`)
	}
}
