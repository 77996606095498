import { CHOOSE_DATE } from 'src/state/reservation/reservation.reducers'

export class ReservationDay {
	static format(input: string) {
		if (input === CHOOSE_DATE) {
			return input
		}
		const [year, month, day] = input.split('-')
		const date = new Date(+year, +month - 1, +day)
		const dateFormatted = new Intl.DateTimeFormat('pt-BR', {
			weekday: 'long',
			day: 'numeric',
			month: 'long',
		}).format(date)
		const captalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
		return captalize(dateFormatted)
	}
}
