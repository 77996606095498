<form class="filter" (ngSubmit)="handleSubmit()">
	<div class="filter__icon" (click)="handleSubmit()">
		<i class="fa fa-search"></i>
	</div>
	<input [(ngModel)]="searchName" name="seach" placeholder="Buscar restaurante" />
	<button (click)="clickFilter()" type="button">
		<i class="fa fa-filter"></i>
	</button>
</form>
<div class="tags">
	<div class="tags__item" *ngIf="nameTag">
		{{ nameTag }}
		<button (click)="handleDeleteName()"><i class="fa fa-close"></i></button>
	</div>
	<ng-container *ngIf="selectedAddress">
		<div class="tags__item" *ngIf="selectedAddress !== DEFAULT_ADDRESS">
			{{ selectedAddress }}
			<button (click)="handleDeleteAddress()"><i class="fa fa-close"></i></button>
		</div>
	</ng-container>
	<ng-container *ngIf="selectedCuisine">
		<div class="tags__item" *ngIf="selectedCuisine !== DEFAULT_CUISINE">
			{{ selectedCuisine }}
			<button (click)="handleDeleteCuisine()"><i class="fa fa-close"></i></button>
		</div>
	</ng-container>
</div>

<app-modal [id]="modalId" top="15%" [preventOutsideClickClose]="true">
	<app-modal-filter (close)="closeFilter()"></app-modal-filter>
</app-modal>
