<app-header></app-header>

<div style="padding-top: 11px; max-width: 1184px; margin: 0 auto" class="desktop">
	<app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>
</div>

<div class="payBody">
	<div class="payBody mobile" *ngIf="isMobile">
		<div class="payBody__boxButton px-16">
			<button class="payBody__button" (click)="location.back()">
				<i class="fa fa-arrow reverse"></i> Voltar
			</button>
		</div>
		<div class="payBody__details">
			<app-order-details *ngIf="selectedOrder$ | async as order" [order]="order"></app-order-details>
		</div>
		<div class="payBody__form">
			<app-payment-form
				(paymentClicked)="router.navigate(['/reservas-e-pagamento/pagamento/confirmacao'])"
			></app-payment-form>
		</div>
	</div>

	<div class="payBody desktop" *ngIf="!isMobile">
		<app-template-tab>
			<div class="payBody__box">
				<div class="payBody__boxDetails">
					<div class="payBody__boxTitle">
						<p class="payBody__title">Histórico de reservas</p>
					</div>
					<div class="payBody__boxButton">
						<button class="payBody__button" (click)="location.back()">
							<i class="fa fa-arrow reverse"></i> Voltar
						</button>
					</div>
					<app-order-details
						*ngIf="selectedOrder$ | async as order"
						[order]="order"
					></app-order-details>
				</div>
				<div class="payBody__boxPayment">
					<div class="payBody__boxTitle px-16">
						<p class="payBody__title bold">Pagamento</p>
					</div>
					<app-payment-form
						(paymentClicked)="modalService.open(modal.paymentCVV)"
					></app-payment-form>
				</div>
			</div>
		</app-template-tab>
	</div>
</div>

<app-footer></app-footer>

<app-modal [id]="modal.paymentCVV" top="15%" *ngIf="!isMobile" [preventOutsideClickClose]="true">
	<div class="modal modal__cvv">
		<app-modal-header (close)="modalService.close(modal.paymentCVV)"
			>Confirmar pagamento</app-modal-header
		>
		<app-payment-card (changeCard)="modalService.open(modal.changeCard)"></app-payment-card>
	</div>
</app-modal>

<app-modal [id]="modal.changeCard" animation="vertical-i" *ngIf="!isMobile">
	<app-select-card
		(addCard)="goToWallet()"
		[id]="modal.changeCard"
		style="position: fixed; left: 50%; bottom: 220px; transform: translateX(-50%)"
	></app-select-card>
</app-modal>

<app-modal [id]="modal.paymentConfirm" top="15%" *ngIf="!isMobile">
	<div class="modal">
		<app-confirm-payment></app-confirm-payment>
		<div style="margin: 24px 20px 20px 20px">
			<button class="btn btn--inverted" routerLink="/reservas-e-pagamento">Ok</button>
		</div>
	</div>
</app-modal>

<app-modal [id]="modal.error" top="15%">
	<app-modal-basic [id]="modal.error" title="Algum erro aconteceu" *ngIf="error$ | async as error">
		{{error}}
	</app-modal-basic>
</app-modal>
