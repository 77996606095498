import { Component, Input } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Observable, map, tap } from 'rxjs'

export interface BreadcrumbsData {
	url: string
	title: string
}

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadCrumbComponent {
	@Input() breadcrumbs: BreadcrumbsData[] = []
	constructor() {}
	// private activatedRoute: ActivatedRoute

	ngOnInit(): void {
		// this.breadcrumbs$ = this.activatedRoute.data.pipe(map(data => data['breadcrumbs']))
	}
}
