import { Location } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { Subject, take, takeUntil } from 'rxjs'
import { ModalService } from 'src/app/components/modal/modal.service'
import { PREFIX_TITLE } from 'src/shared/helpers/prefix-title'
import { AppState } from 'src/state/app.state'
import { getSelectOrder } from 'src/state/order-history/order-history.reducers'
import { PaymentActions } from 'src/state/payment/payment.actions'
import { getErrorPayment } from 'src/state/payment/payment.reducers'

@Component({
	templateUrl: './payment-card.page.html',
	styleUrls: ['payment-card.page.scss'],
})
export class PaymentCardPage implements OnInit, OnDestroy {
	private destroy$ = new Subject<void>()
	modal = {
		error: 'modal-payment-card-error-id',
		changeCard: 'modal-change-card-mobile-id',
	}

	error$ = this.store.select(getErrorPayment)
	constructor(
		public location: Location,
		private actions$: Actions,
		private router: Router,
		public modalService: ModalService,
		private store: Store<AppState>,
		private title: Title
	) {}

	ngOnInit(): void {
		this.store
			.select(getSelectOrder)
			.pipe(take(1))
			.subscribe(order => {
				this.title.setTitle(
					`${PREFIX_TITLE} - Reservas e Pagamento - ${order?.venue?.name} - Confirmação de Pagamento`
				)
			})

		this.actions$
			.pipe(ofType(PaymentActions.createPaymentSuccess), takeUntil(this.destroy$))
			.subscribe(() => {
				this.router.navigate(['/reservas-e-pagamento/pagamento/confirmacao-sucesso'])
			})

		this.actions$
			.pipe(ofType(PaymentActions.createPaymentFailure), takeUntil(this.destroy$))
			.subscribe(() => {
				this.modalService.open(this.modal.error)
			})
	}

	goToAddCard() {
		this.router.navigate(['/carteira/adicionar-cartao'])
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}
}
