import { createAction, props } from '@ngrx/store'
import { PaymentOrder } from './payment'

// Payment on Order
const setPaymentOrder = createAction(
	'[Payment Bill Page] Set Payment Order',
	props<{ paymentOrder: PaymentOrder }>()
)
const createPayment = createAction('[Payment Card Page] Create Payment', props<{ dynamicCVV: string }>())
const createPaymentSuccess = createAction('[Payment Card Page] Create Payment Success', props<{ res: any }>())
const createPaymentFailure = createAction(
	'[Payment Card Page] Create Payment Failure',
	props<{ error: string }>()
)

export const PaymentActions = {
	setPaymentOrder,
	createPayment,
	createPaymentSuccess,
	createPaymentFailure,
}
