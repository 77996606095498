import { Component, Output, EventEmitter, OnInit } from '@angular/core'
import { Item } from '../../select-box/select-box.component'
import { Store } from '@ngrx/store'
import { AppState } from 'src/state/app.state'
import { AddressActions } from 'src/state/address/address.action'
import { DEFAULT_ADDRESS_TEXT, getAddresses, getSelectedAddress } from 'src/state/address/address.reducers'
import { filter, map } from 'rxjs'
import { DEFAULT_CUISINE_TEXT, getCuisines, getSelectedCuisine } from 'src/state/cuisines/cuisines.reducers'
import { Cuisine } from 'src/gateway/venue/venues.gateway'
import { VenueActions } from 'src/state/venue/venue.actions'
import { CuisinesActions } from 'src/state/cuisines/cuisines.action'

@Component({
	selector: 'app-modal-filter',
	templateUrl: './modal-filter.component.html',
	styleUrls: ['./modal-filter.component.scss'],
})
export class ModalFilterComponent implements OnInit {
	@Output() close = new EventEmitter()

	// Address
	addresses$ = this.store.select(getAddresses).pipe(
		map(address => [DEFAULT_ADDRESS_TEXT, ...address]),
		map(address => address.map(ad => ({ name: ad, value: ad })))
	)
	selectedAddress$ = this.store
		.select(getSelectedAddress)
		.pipe(map(address => ({ name: address, value: address })))

	// Cuisines
	cuisines$ = this.store.select(getCuisines).pipe(
		filter(cuisine => Boolean(cuisine)),
		map(cuisines => [{ name: DEFAULT_CUISINE_TEXT, _id: null }, ...(cuisines as Cuisine[])] as Cuisine[]),
		map(cuisines => cuisines.map(cui => ({ name: cui.name, value: cui._id })))
	)
	selectedCuisine$ = this.store
		.select(getSelectedCuisine)
		.pipe(map(cuisine => ({ name: cuisine.name, value: cuisine._id })))

	constructor(public store: Store<AppState>) {}

	ngOnInit(): void {
		this.store.dispatch(AddressActions.getAddresses())
		this.store.dispatch(CuisinesActions.getCuisines())
	}

	cleanFilter() {
		this.store.dispatch(VenueActions.cleanFilter())
	}

	applyFilter() {
		this.store.dispatch(VenueActions.applyFilter())
		this.close.emit()
	}

	selectAddress(address: Item) {
		this.store.dispatch(AddressActions.setCurrentAddress({ address: address.name }))
	}

	selectCuisine(cuisine: Item) {
		this.store.dispatch(
			CuisinesActions.setCurrentCuisine({ cuisine: { _id: cuisine.value, name: cuisine.name } })
		)
	}
}
