import { Location } from '@angular/common'
import { Component, HostListener, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router'
import { Actions, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { filter, take, tap } from 'rxjs'
import { BreadcrumbsData } from 'src/app/components/breadcrumb/breadcrumb.component'
import { ModalService } from 'src/app/components/modal/modal.service'
import { OrderHistory } from 'src/entity/order-history'
import { MOBILE_BREAKPOINT } from 'src/shared/helpers/breakpoint'
import { PREFIX_TITLE } from 'src/shared/helpers/prefix-title'
import { AppState } from 'src/state/app.state'
import { OrderHistoryActions } from 'src/state/order-history/order-history.actions'
import { getSelectOrder, loadingCancelOrder } from 'src/state/order-history/order-history.reducers'

@Component({
	templateUrl: './order-details.page.html',
	styleUrls: ['./order-details.page.scss'],
})
export class OrderDetailsPage implements OnInit {
	isMobile = false
	loadingCancel$ = this.store.select(loadingCancelOrder)
	selectedOrder$ = this.store.select(getSelectOrder).pipe(
		filter(order => Boolean(order)),
		tap(order => {
			this.title.setTitle(`${PREFIX_TITLE} - Reservas e Pagamento - ${order?.venue?.name} - Detalhes`)
		}),
		tap(order => {
			setTimeout(() => {
				this.breadcrumbs = [
					{ url: '/gastronomia', title: 'Gastronomia' },
					{ url: `/reservas-e-pagamento/`, title: 'Reservas e Pagamento' },
					{ url: `/reservas-e-pagamento/reserva/${order!.id}/`, title: 'Detalhes' },
				]
			}, 0)
			this.order = order
		})
	)
	orderId: string | null = null
	order: OrderHistory | null = null

	modal = {
		cancelOrder: 'modal-cancel-order-id',
	}

	breadcrumbs: BreadcrumbsData[] = []

	constructor(
		public location: Location,
		private store: Store<AppState>,
		public modalService: ModalService,
		private actions$: Actions,
		private router: Router,
		private title: Title
	) {}

	ngOnInit(): void {
		this.getScreenSize()
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize() {
		this.isMobile = window.innerWidth < MOBILE_BREAKPOINT
	}

	openModalCancel(id: string) {
		this.orderId = id
		this.modalService.open(this.modal.cancelOrder)
	}

	cancelOrder() {
		if (!this.orderId) return
		this.actions$
			.pipe(
				ofType(OrderHistoryActions.cancelOrderSuccess, OrderHistoryActions.cancelOrderFailure),
				take(1)
			)
			.subscribe(() => {
				this.modalService.close(this.modal.cancelOrder)
				this.router.navigate(['/reservas-e-pagamento'])
			})

		this.store.dispatch(OrderHistoryActions.cancelOrder({ orderId: this.orderId }))
	}
}
