import { Inject, Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { HttpPaymentsGateway } from 'src/gateway/payment/http-payment.gateway'
import { CreatePaymentDto, PaymentGateway } from 'src/gateway/payment/payment.gateway'
import { AppState } from '../app.state'
import { getSelectOrder } from '../order-history/order-history.reducers'
import { getPaymentOrder } from './payment.reducers'
import { getClient } from '../client/client.reducers'
import { PaymentActions } from './payment.actions'
import { catchError, map, mergeMap, of } from 'rxjs'
import { environment as env } from 'src/environments/environment'

@Injectable()
export class PaymentEffects {
	constructor(
		private actions$: Actions,
		@Inject(HttpPaymentsGateway)
		private paymentGateway: PaymentGateway,
		private store: Store<AppState>
	) {}

	createPayment$ = createEffect(() => {
		return this.actions$.pipe(
			ofType(PaymentActions.createPayment),
			concatLatestFrom(() => [
				this.store.select(getSelectOrder),
				this.store.select(getPaymentOrder),
				this.store.select(getClient),
			]),
			mergeMap(([action, order, paymentOrder, client]) => {
				const paymentDto = {
					tableOrder: order?.id,
					orderId: order?.id,
					venueId: env.production ? order?.venue.id : '56c776ff0896b3cd13c6012b',
					clientId: client?.id,
					gatewayId: client?.selectedCategory?.gatewayId,
					value: Math.round(paymentOrder?.bill * 100), // Em centavos, mínimo 1000
					gratuityValue: Math.round(paymentOrder?.gratuityValue * 100),
					// cpf: client?.cpf,
					dynamicCVV: action.dynamicCVV,
				}
				return this.paymentGateway.createPayment(paymentDto as CreatePaymentDto).pipe(
					map(res => PaymentActions.createPaymentSuccess({ res })),
					catchError(err => of(PaymentActions.createPaymentFailure({ error: err.error.message })))
				)
			})
		)
	})
}
