import { HttpClient } from '@angular/common/http'
import { Observable, delay, map, switchMap, throwError, timer } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Injectable } from '@angular/core'
import OrderGateway, {
	CheckAvailabilityDto,
	ConfirmReservationDto,
	FilterHistoryQueryDto,
} from './order.gateway'
import { OrderFromHistoryProps, OrderHistory } from 'src/entity/order-history'

export const DEFAULT_LIMIT = 10

@Injectable()
export class HttpOrderGateway implements OrderGateway {
	baseURL = `${environment.channelsAPI.baseUrl}/api/v1/orders`
	constructor(private http: HttpClient) {}

	checkAvailability(checkAvailabilityDto: CheckAvailabilityDto): Observable<any> {
		const body = {
			...checkAvailabilityDto,
			partnerId: environment.partnerId,
		}
		return this.http.post<any>(`${this.baseURL}/checkAvailability`, body)
	}

	confirmOrder(orderId: string, confirmReservationDto: ConfirmReservationDto): Observable<any> {
		return this.http.post(`${this.baseURL}/confirm/${orderId}`, confirmReservationDto)
	}

	getHistory(params?: FilterHistoryQueryDto): Observable<OrderHistory[]> {
		const query = params ? '?' + new URLSearchParams(params as Record<string, string>).toString() : ''
		return this.http
			.get<OrderFromHistoryProps[]>(`${this.baseURL}/history` + query)
			.pipe(map(orders => orders.map(order => OrderHistory.create(order))))
	}

	cancelOrder(orderId: string): Observable<unknown> {
		return this.http.delete(`${this.baseURL}/${orderId}`)
	}

	getOrdersPaymentReady(clientId: string) {
		return this.http.get<{ hasPaymentReady: boolean }>(`${this.baseURL}/payment/ready/${clientId}`)
	}
}
