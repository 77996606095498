<ng-container *ngIf="vm$ | async as vm">
	<app-success-template template="reservation">
		<div class="success">
			<app-ticket label="Nome" [data]="vm.client?.fullName"></app-ticket>
			<app-ticket label="Restaurante" [data]="vm.selectedVenue?.name"></app-ticket>
			<app-ticket label="Mesa para" [data]="vm.confirmReservation.formatPartySize.value"></app-ticket>
			<app-ticket label="Data" [data]="vm.confirmReservation.reservationDate.format"></app-ticket>
			<app-ticket label="Endereço" [data]="vm.selectedVenue?.address?.value"></app-ticket>
		</div>
	</app-success-template>

	<div class="footer">
		<app-benefits
			*ngIf="selectedVenue$ | async as selectedVenue"
			[showPoint]="false"
			[benefit]="selectedVenue.benefits"
		></app-benefits>

		<div class="alert">
			<app-alert></app-alert>
		</div>
	</div>
</ng-container>
