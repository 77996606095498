<form class="px-16" [formGroup]="creditCardForm" (ngSubmit)="handleSubmit()">
	<h1 class="title">Cadastrar cartão</h1>
	<div style="height: 32px"></div>
	<!-- Número do cartão-->
	<label class="label">
		<span class="label__text">Número do cartão</span>
		<input
			mask="0000 0000 0000 0000||0000 000000 00000"
			placeholder="0000  0000  0000  0000"
			inputmode="numeric"
			formControlName="number"
		/>
	</label>
	<div style="height: 32px"></div>
	<!-- Nome do cartão-->
	<label class="label">
		<span class="label__text">Nome igual do cartão</span>
		<input placeholder="Nome e sobrenome" pattern="[^()/><\][\\\x22,;|.]+" formControlName="name" />
	</label>

	<div style="height: 32px"></div>
	<label class="label">
		<span class="label__text">CPF</span>
		<input id="cpf" placeholder="000.000.000-00" mask="000.000.000-00" formControlName="cpf" />
	</label>

	<div style="height: 32px"></div>
	<div class="twoColumns">
		<!-- Validade -->
		<label class="label">
			<span class="label__text">Validade</span>
			<input
				mask="00/00"
				placeholder="MM/YY"
				[dropSpecialCharacters]="false"
				formControlName="validity"
				inputmode="numeric"
			/>
		</label>
		<!-- Código de Segurança -->
		<label class="label">
			<span class="label__text">CVV</span>
			<input type="text" mask="000||0000" formControlName="cvv" inputmode="numeric" placeholder="CVV" />
			<i class="label__icon fa fa-doubt" (click)="openModal(modal.cvv)" style="cursor: pointer"></i>
		</label>
	</div>
	<h1 class="title">Endereço de cobrança</h1>
	<div style="height: 32px"></div>
	<label class="label">
		<span class="label__text">Telefone</span>
		<input
			type="text"
			mask="(00) 00000-0000"
			formControlName="phone"
			inputmode="numeric"
			placeholder="(00) 00000-0000"
		/>
	</label>
	<div style="height: 32px"></div>
	<div formGroupName="billingAddress">
		<label class="label">
			<span class="label__text">CEP</span>
			<input
				mask="00000-000"
				formControlName="zipCode"
				type="text"
				inputmode="numeric"
				placeholder="CEP"
			/>
		</label>

		<div style="height: 32px"></div>
		<label class="label">
			<span class="label__text">Endereço</span>
			<input formControlName="address" type="text" placeholder="Endereço" />
		</label>

		<div style="height: 32px"></div>
		<div class="twoColumns">
			<ch-select-box
				[items]="states"
				[selectedItem]="selectedState"
				(itemWasSelected)="setState($event)"
			></ch-select-box>

			<label class="label">
				<span class="label__text">Cidade</span>
				<input formControlName="city" placeholder="Cidade" />
			</label>
		</div>
		<div style="height: 32px"></div>
	</div>

	<!-- SWITCH -->
	<label class="switch">
		<p class="switch__txt">Cartão principal?</p>
		<input type="checkbox" class="switch__input" formControlName="mainCard" />
		<div class="switch__round"></div>
	</label>
	<div style="height: 26px"></div>
	<app-alert template="rememberPayment"></app-alert>

	<div style="height: 24px"></div>
	<ng-container *ngIf="{ loading: isLoadingRegisterCard$ | async } as obj">
		<button
			class="btn btn--primary"
			style="position: relative"
			[disabled]="creditCardForm.invalid || obj.loading"
			[ngClass]="{ 'btn--disabled': creditCardForm.invalid || obj.loading }"
		>
			Cadastrar cartão
			<app-spinner style="position: absolute; right: 10px; top: 5px" *ngIf="obj.loading"></app-spinner>
		</button>
	</ng-container>
	<div style="height: 64px"></div>
</form>

<app-modal [id]="modal.cvv" top="15%">
	<app-modal-cvv [id]="modal.cvv"></app-modal-cvv>
</app-modal>

<app-modal [id]="modal.failure" top="15%">
	<app-modal-basic title="Falha ao cadastrar cartão" [hasClose]="false">
		<p class="text" *ngIf="error$ | async as error">{{ error }}</p>
		<button class="btn btn--primary" (click)="modalService.close(modal.failure)">Ok</button>
	</app-modal-basic>
</app-modal>
<app-modal [id]="modal.success" top="15%" [preventOutsideClickClose]="true">
	<app-modal-basic title="Cartão cadastrado com sucesso">
		<p class="text">Agora você pode usá-lo para pagar a conta no restaurante e ganhar pontos!</p>
		<button class="btn btn--primary" (click)="location.back()">Ok</button>
	</app-modal-basic>
</app-modal>
