import { Injectable } from '@angular/core'
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http'
import { Observable } from 'rxjs'
import { Router } from '@angular/router'
import { environment as env } from 'src/environments/environment'
import { LOCAL_CLIENT_ID, LocalStorageService } from './local-storage.service'

@Injectable()
export class CustomHeadersInterceptor implements HttpInterceptor {
	constructor(private router: Router, private localStorageService: LocalStorageService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.url.startsWith('https://viacep')) {
			return next.handle(request)
		}
		const clientId = this.localStorageService.getTime(LOCAL_CLIENT_ID)
		return next.handle(
			request.clone({ setHeaders: { partnerId: env.partnerId, ...(clientId ? { clientId } : {}) } })
		)
	}
}
