import { createAction, props } from '@ngrx/store'

export const getBiggestMultiplier = createAction('[Channels API] Get Biggest Multiplier')
export const loadBiggestMultiplier = createAction('[Channels API] Load Biggest Multiplier')
export const loadBiggestMultiplierSuccess = createAction(
	'[Channels API] Load Biggest Multiplier Success',
	props<{ multiplier: string }>()
)
export const loadBiggestMultiplierFailure = createAction(
	'[Channels API] Load Biggest Multiplier Fail',
	props<{ error: string }>()
)

export const BenefitsActions = {
	getBiggestMultiplier,
	loadBiggestMultiplier,
	loadBiggestMultiplierSuccess,
	loadBiggestMultiplierFailure,
}
