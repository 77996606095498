import { Component, OnInit, HostListener, OnDestroy } from '@angular/core'
import { Location } from '@angular/common'
import { Store } from '@ngrx/store'
import { AppState } from 'src/state/app.state'
import { getSelectOrder, loadingCancelOrder } from 'src/state/order-history/order-history.reducers'
import { ModalService } from 'src/app/components/modal/modal.service'
import { Subject, takeUntil, tap } from 'rxjs'
import { Actions, ofType } from '@ngrx/effects'
import { Router } from '@angular/router'
import { BenefitsActions } from 'src/state/benefits/benefits.actions'
import { PaymentActions } from 'src/state/payment/payment.actions'
import { getErrorPayment } from 'src/state/payment/payment.reducers'
import { MOBILE_BREAKPOINT } from 'src/shared/helpers/breakpoint'
import { Title } from '@angular/platform-browser'
import { PREFIX_TITLE } from 'src/shared/helpers/prefix-title'

@Component({
	templateUrl: './payment.page.html',
	styleUrls: ['payment.page.scss'],
})
export class PaymentPage implements OnInit, OnDestroy {
	loadingCancel$ = this.store.select(loadingCancelOrder)

	error$ = this.store.select(getErrorPayment)
	modal = {
		paymentCVV: 'modal-payment-cvv-id',
		paymentConfirm: 'modal-payment-confirm-id',
		changeCard: 'modal-change-card-id',
		error: 'modal-payment-error-id',
	}
	orderId: string | null = null

	private destroy$ = new Subject<void>()
	selectedOrder$ = this.store.select(getSelectOrder).pipe(
		tap(order => {
			this.title.setTitle(`${PREFIX_TITLE} - Reservas e Pagamento - ${order?.venue?.name} - Pagamento`)
		})
	)
	isMobile = false

	breadcrumbs = [
		{ url: '/gastronomia', title: 'Gastronomia' },
		{ url: `/reservas-e-pagamento/`, title: 'Reservas e Pagamento' },
		{ url: `/reservas-e-pagamento/pagamento`, title: 'Pagamento' },
	]
	constructor(
		public location: Location,
		private store: Store<AppState>,
		public modalService: ModalService,
		private actions$: Actions,
		public router: Router,
		private title: Title
	) {}

	ngOnInit(): void {
		this.store.dispatch(BenefitsActions.getBiggestMultiplier())
		this.getScreenSize()

		this.actions$
			.pipe(ofType(PaymentActions.createPaymentSuccess), takeUntil(this.destroy$))
			.subscribe(() => {
				this.modalService.close(this.modal.paymentCVV)
				this.modalService.open(this.modal.paymentConfirm)
			})

		this.actions$
			.pipe(ofType(PaymentActions.createPaymentFailure), takeUntil(this.destroy$))
			.subscribe(() => {
				this.modalService.close(this.modal.paymentCVV)
				this.modalService.open(this.modal.error)
			})
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}

	goToWallet() {
		this.router.navigate(['/carteira'])
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize() {
		this.isMobile = window.innerWidth < MOBILE_BREAKPOINT
	}
}
