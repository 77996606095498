import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { combineLatest, map } from 'rxjs'
import { AppState } from 'src/state/app.state'
import { BenefitsActions } from 'src/state/benefits/benefits.actions'
import { getClient } from 'src/state/client/client.reducers'
import { getConfirmReservation } from 'src/state/reservation/reservation.reducers'
import { getSelectedVenue } from 'src/state/venue/venue.reducers'

@Component({
	selector: 'app-confirm-reservation',
	templateUrl: './confirm-reservation.component.html',
	styleUrls: ['./confirm-reservation.component.scss'],
})
export class ConfirmReservationComponent implements OnInit {
	selectedVenue$ = this.store.select(getSelectedVenue)
	vm$ = combineLatest([
		this.selectedVenue$,
		this.store.select(getConfirmReservation),
		this.store.select(getClient),
	]).pipe(
		map(([selectedVenue, confirmReservation, client]) => ({ selectedVenue, confirmReservation, client }))
	)
	constructor(private store: Store<AppState>) {}

	ngOnInit(): void {
		this.store.dispatch(BenefitsActions.getBiggestMultiplier())
	}
}
