import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['alert.component.scss'],
})
export class AlertComponent implements OnInit {
	hasTitle = false
	titleText = ''
	bodyText = ''
	icon = ''
	@Input() template: 'remberPoints' | 'rememberPayment' | 'unavailableCheckin' = 'remberPoints'
	constructor() {}

	ngOnInit(): void {
		if (this.template === 'remberPoints') {
			this.hasTitle = true
			this.titleText = 'Pague a conta pelo Latam Gastronomia'
			this.bodyText =
				'Lembre-se de fazer uma reserva e pagar a conta do restaurante pelo portal do LATAM Pass Gastronomia, essa é a forma para juntar mais pontos.'
			this.icon = 'fa-attention'
		} else if (this.template === 'rememberPayment') {
			this.hasTitle = false
			this.bodyText =
				'Para validação do seu cartão será feita uma cobrança de até R$5,00, em instantes será cancelada e o valor estornado. '

			this.icon = 'fa-validation'
		} else if (this.template === 'unavailableCheckin') {
			this.hasTitle = false
			this.bodyText =
				'O Check-in não está habilitado no momento. Você pode consultar abaixo os horários disponíveis ou fazer uma reserva para este restaurante. Aproveite!'
			this.icon = 'fa-attention'
		}
	}
}
