<app-header></app-header>
<div class="breadcrumb">
	<app-breadcrumb [breadcrumbs]="breadcrumbs"></app-breadcrumb>
</div>
<section class="wrapper">
	<h2 class="title">Reserva de mesa</h2>
	<h1 class="name" *ngIf="selectedVenue$ | async as selectedVenue">{{selectedVenue.name}}</h1>
	<app-reservation-form></app-reservation-form>
</section>

<app-footer></app-footer>

<app-modal [id]="modal.error" top="18%">
	<app-modal-basic title="Algum erro aconteceu" [id]="modal.error" *ngIf="error$ | async as error"
		>{{error}}</app-modal-basic
	>
</app-modal>
