import { capitalizeEachWord } from 'src/shared/helpers/capitalize-each-word'
import { Category, CategoryProps } from './category.vo'

export class Client {
	private constructor(
		readonly id: string,
		readonly fullName: string,
		// readonly cpf: string,
		readonly token: string,
		public selectedCategory: Category | null,
		readonly cards: Category[]
	) {}

	static create(props: ClientProps) {
		const id = props._id
		const fullName = capitalizeEachWord(`${props.name} ${props.lastName}`)
		// const cpf = props.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
		const token = props?.access_token?.token
		const mainCard = props.categories.find((card: any) => card.mainCard === true)
		const cards = props.categories.filter(category => category?.gatewayId)
		const selectCard = mainCard ?? cards[0] ?? props.categories[0]
		//const selectCard = props.categories[0]
		const selectedCategory = selectCard ? new Category(selectCard) : null
		return new Client(
			id,
			fullName,
			token,
			selectedCategory,
			cards.map(card => new Category(card))
		)
	}

	// return new client because redux is immutable
	setCard(gatewayId: string, lastFourDigits: string): Client {
		const selectedCard = this.cards.find(
			card => card.gatewayId === gatewayId && card.lastFourDigits === lastFourDigits
		)
		if (!selectedCard) {
			return this
		}
		return new Client(this.id, this.fullName, this.token, selectedCard, this.cards)
	}
}

export interface ClientProps {
	_id: string
	email: string
	name: string
	lastName: string
	role: string
	access_token: AccessToken
	categories: CategoryProps[]
	cpf: string
}

interface AccessToken {
	token: string
	createdAt: string
}
