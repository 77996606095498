import { Component, OnDestroy, OnInit } from '@angular/core'
import { ModalService } from '../modal/modal.service'
import { Store } from '@ngrx/store'
import { AppState } from 'src/state/app.state'
import { VenueActions } from 'src/state/venue/venue.actions'
import { AddressActions } from 'src/state/address/address.action'
import { DEFAULT_ADDRESS_TEXT } from 'src/state/address/address.reducers'
import { getSelectedAddress } from 'src/state/address/address.reducers'
import { Actions, ofType } from '@ngrx/effects'
import { Subscriber, Subscription, map, withLatestFrom } from 'rxjs'
import { DEFAULT_CUISINE_TEXT, getSelectedCuisine } from 'src/state/cuisines/cuisines.reducers'
import { CuisinesActions } from 'src/state/cuisines/cuisines.action'

@Component({
	selector: 'app-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['filter.component.scss'],
})
export class FilterComponent implements OnInit, OnDestroy {
	modalId = 'modal-filter-id'
	searchName: string = ''
	nameTag: string | undefined
	selectedAddress!: string
	selectedCuisine!: string
	DEFAULT_ADDRESS = DEFAULT_ADDRESS_TEXT
	DEFAULT_CUISINE = DEFAULT_CUISINE_TEXT
	subs = new Subscription()

	constructor(
		private modalService: ModalService,
		private store: Store<AppState>,
		private actions$: Actions
	) {}

	ngOnInit(): void {
		this.subs.add(
			this.actions$
				.pipe(
					ofType(VenueActions.applyFilter),
					withLatestFrom(
						this.store.select(getSelectedAddress),
						this.store.select(getSelectedCuisine)
					),
					map(([_, address, cuisine]) => ({ address, cuisine }))
				)
				.subscribe({
					next: ({ address, cuisine }) => {
						this.selectedAddress = address
						this.selectedCuisine = cuisine.name
					},
				})
		)
	}

	ngOnDestroy(): void {
		this.subs.unsubscribe()
	}

	clickFilter() {
		this.modalService.open(this.modalId)
	}

	closeFilter() {
		this.modalService.close(this.modalId)
	}

	handleDeleteName() {
		this.nameTag = undefined
		this.searchName = ''
		this.store.dispatch(VenueActions.loadVenues({ filterVenueDto: { name: '' } }))
	}

	handleDeleteAddress() {
		this.store.dispatch(AddressActions.setCurrentAddress({ address: DEFAULT_ADDRESS_TEXT }))
		this.store.dispatch(VenueActions.applyFilter())
	}

	handleDeleteCuisine() {
		this.store.dispatch(
			CuisinesActions.setCurrentCuisine({ cuisine: { _id: '', name: DEFAULT_CUISINE_TEXT } })
		)
		this.store.dispatch(VenueActions.applyFilter())
	}

	handleSubmit() {
		this.nameTag = this.searchName
		this.selectedAddress = DEFAULT_ADDRESS_TEXT
		this.selectedCuisine = DEFAULT_CUISINE_TEXT
		this.store.dispatch(VenueActions.loadVenues({ filterVenueDto: { name: this.searchName } }))
	}
}
