import { HttpClient } from '@angular/common/http'
import VenuesGateway, { Cuisine, FilterVenuesDto, FindOneVenueDto } from './venues.gateway'
import { VenueDetail, VenueFilter } from 'src/entity/venues.entity'
import { Observable, map } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Injectable } from '@angular/core'
import { VenuesFilterProperties } from './venues-filter.interface'
import { VenueDetailProps } from './venue-detail.interface'

export const DEFAULT_LIMIT = 8

@Injectable()
export class HttpVenuesGateway implements VenuesGateway {
	baseURL = `${environment.channelsAPI.baseUrl}/api/v1/venues`
	constructor(private http: HttpClient) {}
	getVenueId(venueId: string, findOneVenueDto?: FindOneVenueDto | undefined): Observable<VenueDetail> {
		const body: FindOneVenueDto = {
			partner: environment.partnerId,
			...findOneVenueDto,
		}
		return this.http
			.post<VenueDetailProps>(`${this.baseURL}/${venueId}`, body)
			.pipe(map(venueData => new VenueDetail(venueData)))
	}

	getCuisines(): Observable<Cuisine[]> {
		return this.http.get<Cuisine[]>(`${this.baseURL}/cousines`)
	}

	filter(filterVenuesDto?: FilterVenuesDto): Observable<VenueFilter[]> {
		const body: FilterVenuesDto = {
			partner: environment.partnerId,
			venuesAlreadyInView: [],
			limit: DEFAULT_LIMIT,
			...filterVenuesDto,
		}
		return this.http
			.post<VenuesFilterProperties[]>(`${this.baseURL}/filter`, body)
			.pipe(map(venuesData => venuesData.map(venue => new VenueFilter(venue))))
	}
}
