import { OrderHistory } from 'src/entity/order-history'
import { Status } from '../status.type'
import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store'
import { OrderHistoryActions } from './order-history.actions'

export interface OrderHistoryState {
	orders: OrderHistory[] | null
	selectedOrder: OrderHistory | null
	statusOrderHistory: Status
	errorOrderHistory: string | null
	hasPaymentReady: boolean
	ordersToPay: OrderHistory[] | null
	// cancel
	statusCancelOrder: Status
	errorCancelOrder: string | null
}

const initialState: OrderHistoryState = {
	orders: null,
	selectedOrder: null,
	statusOrderHistory: 'idle',
	errorOrderHistory: null,
	statusCancelOrder: 'idle',
	errorCancelOrder: null,
	hasPaymentReady: false,
	ordersToPay: null,
}
export const getOrderFeatureState = createFeatureSelector<OrderHistoryState>('order')

export const getOrders = createSelector(getOrderFeatureState, state => state.orders)
export const getOrderError = createSelector(getOrderFeatureState, state => state.errorOrderHistory)
export const loadingOrderHistory = createSelector(
	getOrderFeatureState,
	state => state.statusOrderHistory === 'pending'
)
export const loadingCancelOrder = createSelector(
	getOrderFeatureState,
	state => state.statusCancelOrder === 'pending'
)
export const getCancelOrderError = createSelector(getOrderFeatureState, state => state.errorCancelOrder)
export const getSelectOrder = createSelector(getOrderFeatureState, state => state.selectedOrder)
export const getHasPaymentReady = createSelector(getOrderFeatureState, state => state.hasPaymentReady)
export const getOrdersToPay = createSelector(getOrderFeatureState, state => state.ordersToPay)

export const orderReducer = createReducer<OrderHistoryState>(
	initialState,
	on(OrderHistoryActions.setSelectedOrder, (state, action): OrderHistoryState => {
		return {
			...state,
			selectedOrder: action.order,
		}
	}),
	on(OrderHistoryActions.loadOrderHistory, (state): OrderHistoryState => {
		return {
			...state,
			selectedOrder: null,
			statusOrderHistory: 'pending',
		}
	}),
	on(OrderHistoryActions.loadOrderHistorySuccess, (state, action): OrderHistoryState => {
		return {
			...state,
			orders: action.orders,
			ordersToPay: action.orders.filter(order => order.canPay),
			statusOrderHistory: 'success',
			errorOrderHistory: null,
		}
	}),
	on(OrderHistoryActions.loadOrderHistoryFailure, (state, action): OrderHistoryState => {
		return {
			...state,
			orders: null,
			statusOrderHistory: 'failure',
			errorOrderHistory: action.error,
		}
	}),
	on(OrderHistoryActions.cancelOrder, (state): OrderHistoryState => {
		return {
			...state,
			statusCancelOrder: 'pending',
		}
	}),
	on(OrderHistoryActions.cancelOrderSuccess, (state): OrderHistoryState => {
		return {
			...state,
			statusCancelOrder: 'success',
		}
	}),
	on(OrderHistoryActions.cancelOrderFailure, (state, action): OrderHistoryState => {
		return {
			...state,
			errorCancelOrder: action.error,
			statusCancelOrder: 'failure',
		}
	}),
	on(OrderHistoryActions.getOrdersPaymentReadySuccess, (state, action): OrderHistoryState => {
		return {
			...state,
			hasPaymentReady: action.hasPaymentReady,
		}
	})
)
