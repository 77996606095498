<div class="swiper-container desktop">
	<div class="swiper-wrapper">
		<!-- Slides go here -->
		<div *ngFor="let imageDesk of imagesDesk" class="swiper-slide">
			<img id="carroDesktop" [src]="imageDesk" />
		</div>
	</div>
	<!-- Add pagination -->
	<div class="swiper-pagination"></div>
</div>

<div class="swiper-container mobile">
	<div class="swiper-wrapper">
		<!-- Slides go here -->
		<div *ngFor="let imageMobile of imagesMobile" class="swiper-slide">
			<img id="carroMobile" [src]="imageMobile" />
		</div>
	</div>
	<!-- Add pagination -->
	<div class="swiper-pagination"></div>
</div>
