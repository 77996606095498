import { Component, Input, AfterViewInit } from '@angular/core'
import Swiper, { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper'

Swiper.use([Navigation, Pagination, Scrollbar, A11y, Autoplay])

@Component({
	selector: 'app-carrossel',
	templateUrl: './carrossel.component.html',
	styleUrls: ['./carrossel.component.scss'],
})
export class CarrosselComponent implements AfterViewInit {
	@Input() imagesDesk: string[] = []
	@Input() imagesMobile: string[] = []
	@Input() showButton: boolean = true

	private swiper!: Swiper

	ngAfterViewInit() {
		this.swiper = new Swiper('.swiper-container', {
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			autoplay: {
				delay: 5000,
				disableOnInteraction: false,
			},
			touchRatio: 1,
			touchEventsTarget: 'container',
			preventClicks: false,
		})
	}
}
