import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Store } from '@ngrx/store'
import { AppState } from 'src/state/app.state'
import { getClient } from 'src/state/client/client.reducers'
import { CheckInActions } from 'src/state/checkin/checkin.actions'
import { ActivatedRoute, Router } from '@angular/router'
import {
	getErrorAvailabilities,
	getStatusAvailabilities,
	getAvailabilities,
} from 'src/state/checkin/checkin.reducers'
import { Item } from '../select-box/select-box.component'
import { CheckinDate } from 'src/entity/checkin-date.vo'

@Component({
	selector: 'app-checkin',
	templateUrl: './checkin.component.html',
	styleUrls: ['./checkin.component.scss'],
})
export class CheckinComponent implements OnInit {
	statusAvailabities$ = this.store.select(getStatusAvailabilities)
	selectedClient$ = this.store.select(getClient)
	checkInDate = new CheckinDate()
	errorAvailabilities$ = this.store.select(getErrorAvailabilities)
	statusCheckin$ = this.store.select(getAvailabilities)

	form!: FormGroup
	partySizes: Item[] | null = [
		{ name: 'Selecione a qtd de pessoas', value: 'null' },
		{ name: 'Mesa para 1 pessoa', value: '1' },
		{ name: 'Mesa para 2 pessoas', value: '2' },
		{ name: 'Mesa para 3 pessoas', value: '3' },
		{ name: 'Mesa para 4 pessoas', value: '4' },
		{ name: 'Mesa para 5 pessoas', value: '5' },
		{ name: 'Mesa para 6 pessoas', value: '6' },
		{ name: 'Mesa para 7 pessoas', value: '7' },
		{ name: 'Mesa para 8 pessoas', value: '8' },
		{ name: 'Mesa para 9 pessoas', value: '9' },
		{ name: 'Mesa para 10 pessoas', value: '10' },
	]

	constructor(
		private store: Store<AppState>,
		private route: ActivatedRoute,
		private readonly fb: FormBuilder
	) {}

	ngOnInit(): void {
		const venueId = this.route.snapshot.paramMap.get('venueId') as string
		this.store.dispatch(CheckInActions.loadAvailabilities({ venueId }))
		this.form = this.fb.group({
			confirm: [null, [Validators.required]],
			partySize: [null, [Validators.required]],
		})
	}

	setPartySize(partySizeItem: Item) {
		this.form.get('partySize')?.patchValue(+partySizeItem.value)
	}

	get isDisableForm() {
		return !this.form.get('confirm')?.value || this.form.invalid
	}

	confirmCheckin() {
		if (this.form.invalid) return
		const partySize = this.form.get('partySize')?.value
		this.store.dispatch(
			CheckInActions.checkinConfirm({
				checkInDate: this.checkInDate,
				partySize: partySize,
			})
		)
	}
}
