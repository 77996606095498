import { PartySize } from './partysize.vo'
import { ReservationDate } from './reservationDate.vo'

export class Reservation {
	formatPartySize: PartySize
	reservationDate: ReservationDate
	constructor(
		readonly partySize: number | null,
		readonly sectionLabel: string | null,
		readonly reservationDay: string | null,
		readonly reservationTime: string | null,
		readonly sectionId: string | null
	) {
		this.formatPartySize = new PartySize(partySize)
		this.reservationDate = new ReservationDate(reservationDay, reservationTime)
	}
}
