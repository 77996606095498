// angular
import { LOCALE_ID, NgModule } from '@angular/core'
import { registerLocaleData } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module'
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask'
import { CurrencyMaskConfig, CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency'
export const customCurrencyMaskConfig: CurrencyMaskConfig = {
	align: 'left',
	allowNegative: true,
	allowZero: true,
	decimal: ',',
	precision: 2,
	prefix: 'R$ ',
	suffix: '',
	thousands: '.',
	nullable: true,
	min: undefined,
	max: undefined,
	inputMode: CurrencyMaskInputMode.FINANCIAL,
}
import ptBr from '@angular/common/locales/pt'
registerLocaleData(ptBr)

// components
import { AppComponent } from './app.component'
import { DivisorComponent } from './components/divisor/divisor.component'
import { HeaderComponent } from './components/header/header.component'
import { FooterComponent } from './components/footer/footer.component'
import { CarrosselComponent } from './components/carossel/carrossel.component'
import { BreadCrumbComponent } from './components/breadcrumb/breadcrumb.component'
import { VenueCardComponent } from './components/venue-card/venue-card.component'
import { BenefitsComponent } from './components/benefits/benefits.component'
import { ClientComponent } from './components/client/client.component'
import { FilterComponent } from './components/filter/filter.component'
import { VenueInfoComponent } from './components/venue-info/venue-info.component'
import { SpinnerComponent } from './components/spinner/spinner.component'
import { ReservationFormComponent } from './components/reservation-form/reservation-form.component'
import { AlertComponent } from './components/alert/alert.component'
import { TicketComponent } from './components/ticket/ticket.component'
import { OrderCardComponent } from './components/order-card/order-card.component'
import { SuccessTemplateComponent } from './components/success-template/success-template.componeent'
import { ConfirmReservationComponent } from './components/confirm-reservation/confirm-reservation.component'
import { TemplateTabComponent } from './components/template-tab/template-tab.component'
import { OrderDetailsComponent } from './components/order-details/order-details.component'
import { PaymentFormComponent } from './components/payment-form/payment-form.component'
import { PaymentCardComponent } from './components/payment-card/payment-card.component'
import { ConfirmPaymentComponent } from './components/confirm-payment/confirm-payment.component'
import { HandleLoginTokenComponent } from './components/handle-login-token/handle-login-token'
import { RegisterCardComponent } from './components/register-card/register-card.component'
import { MenuEffectComponent } from './components/menu-effect/menu-effect.component'
import { MenuDeskComponent } from './components/menu-desk/menu-desk.component'
import { SelectCardComponent } from './components/select-card/select-card.component'
import { CheckinComponent } from './components/checkin/checkin.component'
import { SelectBoxComponent } from './components/select-box/select-box.component'
import { CheckinSuccessComponent } from './components/checkin-success/checkin-success.component'

// pages
import { VenuesListPage } from './page/venues-list/venues-list.page'
import { VenueDetailsPage } from './page/venue-details/venue-details.page'
import { HowItWorksPage } from './page/how-it-works/how-it-works.page'
import { SandboxPageTest } from './page/sandbox/sandbox.page'
import { OrderHistoryPage } from './page/order-history/order-history.page'
import { ConfirmReservationSuccessPage } from './page/confirm-reservation/confirm-reservation-success.page'
import { WalletPage } from './page/wallet/wallet.page'
import { PaymentPage } from './page/payment/payment.page'
import { PaymentCardPage } from './page/payment-card/payment-card.page'
import { ConfirmPaymentSuccessPage } from './page/confirm-payment/confirm-payment-success.page'
import { RegisterCardPage } from './page/register-card-page/register-card.page'
import { CheckinConfirmPage } from './page/checkin/checkin-confirm.page'
import { CreateReservationPage } from './page/create-reservation/create-reservation.page'
import { OrderDetailsPage } from './page/order-details/order-details.page'
import { CheckinSuccessPage } from './page/checkin/checkin-success.page'

// modals
import { ModalComponent } from './components/modal/modal.component'
import { ModalFilterComponent } from './components/modal/modal-filter/modal-filter.component'
import { ModalBasicComponent } from './components/modal/modal-basic/modal-basic.component'
import { ModalLoginComponent } from './components/modal/modal-login/modal-login.component'
import { ModalReservationComponent } from './components/modal/modal-reservation/modal-reservation.component'
import { ModalHeaderComponent } from './components/modal/modal-header/modal-header.component'
import { ModalSchedulesComponent } from './components/modal/modal-schedules/modal-schedules.component'
import { ModalCvvComponent } from './components/modal/modal-cvv/modal-cvv.component'
import { ModalTipComponent } from './components/modal/modal-tip/modal-tip.component'

// ngrx
import { StoreModule } from '@ngrx/store'
import { venueReducer } from 'src/state/venue/venue.reducers'
import { EffectsModule } from '@ngrx/effects'
import { VenueEffects } from '../state/venue/venue.effects'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'

// reducers
import { clientReducer } from 'src/state/client/client.reducers'
import { addressReducer } from 'src/state/address/address.reducers'
import { cuisinesReducer } from 'src/state/cuisines/cuisines.reducers'
import { orderReducer } from 'src/state/order-history/order-history.reducers'
import { reservationReducer } from 'src/state/reservation/reservation.reducers'
import { benefitsReducer } from 'src/state/benefits/benefits.reducers'
import { paymentReducer } from 'src/state/payment/payment.reducers'
import { checkinReducer } from 'src/state/checkin/checkin.reducers'

// effects
import { AddressEffects } from 'src/state/address/address.effects'
import { CuisinesEffects } from 'src/state/cuisines/cuisines.effects'
import { OrderHistoryEffects } from 'src/state/order-history/order-history.effects'
import { ClientEffects } from 'src/state/client/client.effects'
import { ReservationEffects } from 'src/state/reservation/reservation.effects'
import { BenefitsEffects } from 'src/state/benefits/benefits.effects'
import { PaymentEffects } from 'src/state/payment/payment.effects'
import { CheckinEffects } from 'src/state/checkin/checkin.effects'

// env
import { environment } from 'src/environments/environment'

// gateways
import { HttpVenuesGateway } from 'src/gateway/venue/http-venues.gateway'
import { HttpAddressGateway } from 'src/gateway/address/http-address.gateway'
import { HttpClientGateway } from 'src/gateway/client/http-client.gateway'
import { HttpOrderGateway } from 'src/gateway/order/http-order.gateway'
import { HttpBenefitGateway } from 'src/gateway/benefit/http-benefit.gateway'
import { HttpPaymentsGateway } from 'src/gateway/payment/http-payment.gateway'

// interceptors
import { TokenInterceptor } from 'src/shared/services/auth.interceptor'
import { CustomHeadersInterceptor } from 'src/shared/services/custom-headers.interceptor'

// pipes
import { FormatPartysize } from 'src/shared/pipes/formt-partysize.pipe'

@NgModule({
	declarations: [
		AppComponent,
		DivisorComponent,
		HeaderComponent,
		FooterComponent,
		CarrosselComponent,
		BreadCrumbComponent,
		VenueCardComponent,
		BenefitsComponent,
		ClientComponent,
		FilterComponent,
		SelectBoxComponent,
		SpinnerComponent,
		VenueInfoComponent,
		ReservationFormComponent,
		AlertComponent,
		TicketComponent,
		SuccessTemplateComponent,
		ConfirmReservationComponent,
		OrderCardComponent,
		RegisterCardComponent,
		TemplateTabComponent,
		OrderDetailsComponent,
		PaymentFormComponent,
		HandleLoginTokenComponent,
		PaymentCardPage,
		PaymentCardComponent,
		ConfirmPaymentComponent,
		MenuEffectComponent,
		MenuDeskComponent,
		SelectCardComponent,
		CheckinComponent,
		CheckinSuccessComponent,

		// Modal
		ModalComponent,
		ModalFilterComponent,
		ModalLoginComponent,
		ModalReservationComponent,
		ModalHeaderComponent,
		ModalSchedulesComponent,
		ModalBasicComponent,
		ModalCvvComponent,
		ModalTipComponent,

		// Pipes
		FormatPartysize,

		// Pages
		SandboxPageTest,
		VenuesListPage,
		VenueDetailsPage,
		HowItWorksPage,
		CreateReservationPage,
		ConfirmReservationSuccessPage,
		RegisterCardPage,
		OrderHistoryPage,
		WalletPage,
		PaymentPage,
		ConfirmPaymentSuccessPage,
		CheckinConfirmPage,
		OrderDetailsPage,
		CheckinSuccessPage,
	],
	imports: [
		FormsModule,
		ReactiveFormsModule,
		BrowserModule,
		HttpClientModule,
		StoreModule.forRoot({
			venues: venueReducer,
			address: addressReducer,
			cuisines: cuisinesReducer,
			client: clientReducer,
			reservation: reservationReducer,
			order: orderReducer,
			benefits: benefitsReducer,
			payment: paymentReducer,
			checkin: checkinReducer,
		}),
		NgxMaskDirective,
		NgxMaskPipe,
		NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
		StoreDevtoolsModule.instrument({
			name: 'Latam App DevTools',
			maxAge: 25,
			logOnly: environment.production,
		}),
		EffectsModule.forRoot([
			VenueEffects,
			AddressEffects,
			CuisinesEffects,
			ClientEffects,
			ReservationEffects,
			OrderHistoryEffects,
			BenefitsEffects,
			PaymentEffects,
			CheckinEffects,
		]),
		AppRoutingModule,
	],

	providers: [
		HttpVenuesGateway,
		HttpAddressGateway,
		HttpClientGateway,
		HttpOrderGateway,
		HttpBenefitGateway,
		HttpPaymentsGateway,
		provideEnvironmentNgxMask(),
		{ provide: LOCALE_ID, useValue: 'pt-BR' },
		{ provide: HTTP_INTERCEPTORS, useClass: CustomHeadersInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
