<!-- <div *ngIf="env !== 'production'" style="width: 400px; margin: 0 auto">
	<ng-container *ngIf="{ client: client$ | async } as client">
		<form *ngIf="!client.client">
			<input
				[(ngModel)]="clientId"
				name="clientId"
				type="text"
				style="border: 1px solid black; border-radius: 8px; margin: 5px; width: 100%"
			/>
			<br />
			<button
				(click)="login()"
				style="background: #000; color: white; padding: 8px; border-radius: 8px"
			>
				Login
			</button>
		</form>
		<span *ngIf="client?.client?.fullName">Login como {{ client?.client?.fullName }}</span>
	</ng-container>
</div> -->
<router-outlet></router-outlet>
