import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Subject, debounceTime, skip, take, takeUntil } from 'rxjs'
import { ChooseBrandService } from 'src/shared/services/choose-brand.service'
import { AppState } from 'src/state/app.state'
import { ClientActions } from 'src/state/client/client.actions'
import { getClient } from 'src/state/client/client.reducers'
import { ModalService } from '../modal/modal.service'

@Component({
	selector: 'app-select-card',
	templateUrl: './select-card.component.html',
	styleUrls: ['select-card.component.scss'],
})
export class SelectCardComponent implements OnInit, OnDestroy {
	@Input() id!: string
	@Output() addCard = new EventEmitter<void>()
	destroy$ = new Subject<void>()
	client$ = this.store.select(getClient)
	form!: FormGroup

	constructor(
		private store: Store<AppState>,
		public chooseBrand: ChooseBrandService,
		private fb: FormBuilder,
		public modalService: ModalService
	) {}

	ngOnInit(): void {
		this.form = this.fb.group({ card: [] })
		this.form
			.get('card')
			?.valueChanges.pipe(takeUntil(this.destroy$), skip(1))
			.subscribe(card => {
				const [gatewayId, lastFourDigits] = card.split('|')
				this.store.dispatch(
					ClientActions.selectCategory({
						gatewayId: gatewayId,
						lastFourDigits: lastFourDigits,
					})
				)
				this.modalService.close(this.id)
			})

		this.client$.pipe(take(1)).subscribe(client => {
			this.form
				.get('card')
				?.setValue(
					`${client?.selectedCategory?.gatewayId}|${client?.selectedCategory?.lastFourDigits}`
				)
		})
	}

	ngOnDestroy(): void {
		this.destroy$.next()
		this.destroy$.complete()
	}
}
