<div class="wrapper">
	<div class="header">
		<h1 class="title">Reserva de mesa</h1>
		<button (click)="close.emit()">
			<i class="fa fa-close"></i>
		</button>
	</div>
	<h2 class="name" *ngIf="selectedVenue$ | async as venue">{{ venue.name }}</h2>
	<app-reservation-form></app-reservation-form>
</div>
