import { HttpBackend, HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({
	providedIn: 'root',
})
export class CEPService {
	private readonly viaCep = 'https://viacep.com.br/ws'
	private readonly httpWithoutInterceptor: HttpClient
	constructor(private readonly httpBackend: HttpBackend) {
		this.httpWithoutInterceptor = new HttpClient(httpBackend)
	}

	getCEP(cep: string) {
		return this.httpWithoutInterceptor.get<AddressViaCepResponse>(`${this.viaCep}/${cep}/json`)
	}
}
export interface AddressViaCepResponse {
	bairro: string
	cep: string
	complemento: string
	ddd: string
	gia: string
	ibge: string
	localidade: string
	logradouro: string
	siafi: string
	uf: string
	erro?: string
}
